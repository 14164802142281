import currency from "currency.js";
import { useQuery } from "react-query";
import { Link, Outlet } from "react-router-dom";
import {
  FileListIcon,
  ArrowDownGrowthIcon,
  ArrowUpGrowthIcon,
  LineChartIcon,
  BucketIcon,
  PageHeaderListIcon,
  CurrencyFillIcon,
  UserIcon,
  BookIcon,
} from "../../components/Icons";
import { useAuth } from "../../hooks/useAuth";
import { useIsAdmin, useIsCashier, useIsSales } from "../../utils/hooks";
import "./../../assets/scss/invoicewrapper.scss";

export function BusinessDevelopmentCRMWrapper({ Nav }) {
  const { backendUrl } = useAuth();
  const isAdmin = useIsAdmin();
  const isCashier = useIsCashier();
  const isSales = useIsSales();

  const getStats = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/items/stats`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    let { data } = await response.json();

    return data;
  };

  const { error, data, isSuccess, refetch, isFetching } = useQuery(
    ["ITEMS_STAT"],
    () => getStats(),
    {
      // enabled: false,
      // cacheTime: 0,
      onSuccess: (data) => {
        // queryClient.removeQueries("LOGIN");
      },
    }
  );

  return (
    <div className="invoice-wrapper ">
      <section className={`stats mb-3`}>
        <div className="stat">
          <div className="icon bg-light-blue">
            <UserIcon />
          </div>
          <div className="details">
            <p>{data?.totalCustomerCount}</p>
            <span>Total Customers</span>
          </div>
        </div>

        <div className="stat">
          <div className="icon bg-light-blue">
            <PageHeaderListIcon />
          </div>
          <div className="details">
            <p>{data?.jobCount}</p>
            <span>Total Jobs</span>
          </div>
        </div>

        <div className="stat">
          <div className="icon bg-light-blue">
            <CurrencyFillIcon />
          </div>
          <div className="details">
            <p>{data?.prospectiveCustomerCount}</p>
            <span>Propective Customers</span>
          </div>
        </div>

        <div className="stat">
          <div className="icon bg-light-blue">
            <BookIcon />
          </div>
          <div className="details">
            <p>{data?.documentCount}</p>
            <span>Document & Certification</span>
          </div>
        </div>
      </section>

      {Nav && <div className="nav-bar">{Nav}</div>}
      <Outlet />
    </div>
  );
}
