import { useFormik } from "formik";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ExpensesSelector from "../utils/ExpensesSelector";
import { Popover } from "react-tiny-popover";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { useAuth } from "../../hooks/useAuth";
import DateTime from "react-datetime";
import {
  convertToBaseCurrency,
  customerFullName,
  formatDate,
} from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import { useCurrencies } from "../../utils/hooks";
import Select from "react-select";
import { useStoreState } from "easy-peasy";
import currency from "currency.js";

export function CustomerOpeningBalance({
  show = true,
  setShow,
  customer,
  refetch,
}) {
  const { backendUrl } = useAuth();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const {
    deploymentCurrencies: currenciesOptions,
    getCurrencySymbol,
  } = useCurrencies();
  const generalSettings = useStoreState((state) => state.generalSettings);

  const saveOpeningAmountApi = async (payload) => {
    let dataToConvert = {
      openingAccountAmount: payload.customer.openingAccountAmount,
      currencyCode: payload.customer.currencyCode,
      conversionAmount: payload.customer.conversionAmount,
    };

    if (
      dataToConvert.currencyCode &&
      dataToConvert.currencyCode !== generalSettings?.prevailingCurrency
    ) {
      dataToConvert = convertToBaseCurrency({
        data: dataToConvert,
        conversionAmount: dataToConvert.conversionAmount,
      });
    }

    payload.customer = { ...payload.customer, ...dataToConvert };
    // return;

    let response = await fetch(
      `${backendUrl}/api/customers/save-opening-amount`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const saveOpeningAmountApiMutation = useMutation(
    (payload) => saveOpeningAmountApi(payload),
    {
      onSuccess: ({ message, data }) => {
        if (refetch) refetch();
        toast.success(message);
        setShow(false);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      openingAccountID: "300",
      openingAccountDescription: "Opening Balance Equity",
      openingAccountType: "OWNER'S EQUITY",
      openingAccountAmount: 0,
      Date_Log: new Date(),
      currencyCode: generalSettings.prevailingCurrency,
      conversionAmount: generalSettings.dollarInBaseCurrency,
    },
    validationSchema: yup.object().shape({
      openingAccountID: yup.string().required("required"),
      openingAccountDescription: yup.string().required("required"),
    }),
    onSubmit: async (values) => {
      console.log(values.Date_Log);
      if (
        await ConfirmDialog({
          title: `Post Opening Balance for ${customerFullName(customer)}`,
          description: `Are you sure?`,
          summary: {
            "Account to Debit": "Accounts Receivable",
            "Account to Credit": `${values.openingAccountID} / ${
              values.openingAccountDescription
            } / ${values.openingAccountType}`,
            Amount: currency(values.openingAccountAmount, {
              symbol: getCurrencySymbol(values.currencyCode),
            }).format(),
            "Post Date": `${formatDate(values?.Date_Log, "dd MMM, yyyy")}`,
          },
        })
      )
        saveOpeningAmountApiMutation.mutate({
          customer: {
            ...customer,
            ...values,
          },
        });
    },
  });

  // GA ===============================================================
  const [showJournalPopover, setShowJournalPopover] = useState(false);
  const handleSelectedAccount = (account) => {
    console.log(account);
    formik.setFieldValue("openingAccountID", account?.AccountID);
    formik.setFieldValue("openingAccountDescription", account?.Description);
    formik.setFieldValue("openingAccountType", account?.Type);
    setShowJournalPopover(false);
  };
  // ===================================================================

  return (
    <>
      <Modal show={show} onHide={handleClose} centered enforceFocus={false}>
        <Modal.Header closeButton>
          <Modal.Title className="h5">Customer Opening Balance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate onSubmit={formik.handleSubmit}>
            {/*  <p>{customer?.Customer_ID}</p> */}
            <p>
              <b> {customerFullName(customer)}</b>
            </p>
            <p>
              {customer?.Email} {customer?.PhoneNo1}
            </p>
            <hr />
            <Form.Group className="mb-3">
              <Form.Label>Account to Credit</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  placeholder="Select Account"
                  value={`${formik.values?.openingAccountID || ""} / ${formik
                    .values?.openingAccountDescription || ""} / ${formik.values
                    ?.openingAccountType || ""}`}
                  onChange={() => {}}
                  readOnly
                />

                <Popover
                  isOpen={showJournalPopover}
                  onClickOutside={() => setShowJournalPopover(false)}
                  content={() => (
                    <ExpensesSelector
                      handleSelectedExpense={handleSelectedAccount}
                      usage={"chart of accounts"}
                      onHide={() => setShowJournalPopover(false)}
                      Type="OWNER'S EQUITY"
                    />
                  )}
                  position="bottom"
                >
                  <InputGroup.Text onClick={() => setShowJournalPopover(true)}>
                    <MagnifyIcon />
                  </InputGroup.Text>
                </Popover>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3 pb-1">
              <Form.Label>Exchange Rate</Form.Label>
              <div className="d-flex gap-3">
                <Form.Group>
                  <Select
                    classNamePrefix="form-select"
                    placeholder="Select Currency"
                    isSearchable={false}
                    options={currenciesOptions}
                    value={currenciesOptions.find(
                      (el) => el.value === formik.values.currencyCode
                    )}
                    onChange={(selected) =>
                      formik.setFieldValue("currencyCode", selected.value)
                    }
                  />
                </Form.Group>

                <Form.Group>
                  <CurrencyCustomInput
                    currencySymbol={"Ex. Rate"}
                    name="conversionAmount"
                    value={formik.values.conversionAmount}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value);
                    }}
                    placeholder="0.00"
                  />
                </Form.Group>
              </div>{" "}
            </Form.Group>{" "}
            <Form.Group className="mb-3">
              <Form.Label>Opening Account Amount</Form.Label>
              <CurrencyCustomInput
                currencySymbol={getCurrencySymbol(formik.values.currencyCode)}
                name="openingAccountAmount"
                value={formik.values.openingAccountAmount}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                placeholder="0.00"
              />
            </Form.Group>
            <Form.Group className="col-6 mb-1 pb-2">
              <Form.Label className="mb-1">Post Date</Form.Label>
              <DateTime
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside={true}
                dateFormat="MMM DD, YYYY"
                inputProps={{
                  className: `date-input form-control`,
                  placeholder: "Select date",
                  readOnly: true,
                }}
                value={formik.values.Date_Log}
                onChange={(date) => {
                  formik.setFieldValue("Date_Log", date, true);
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            className="px-4"
            onClick={() => formik.submitForm()}
            disabled={saveOpeningAmountApiMutation.isLoading}
          >
            {saveOpeningAmountApiMutation.isLoading ? "Please wait..." : "Post"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
