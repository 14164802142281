import { Table, Pagination, Form } from "react-bootstrap";
import { CalendarIcon, PrintIcon } from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { Fragment, useEffect, useRef, useState } from "react";
import DateRangePicker from "../utils/DateRangePicker";
import CachedIcon from "mdi-react/CachedIcon";
import {
  FilterTwoIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
} from "../Icons";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import ChevronUpIcon from "mdi-react/ChevronUpIcon";
import { reportActions } from "../../utils/reactQueryActions";
import { useQueryParams, useScrollTop } from "../../utils/hooks";
import queryString from "query-string";
import { services } from "../../config";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse, startOfMonth } from "date-fns";
import ReactPaginate from "react-paginate";
import { paginationOptions } from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import TruckExpensesModal from "../SalesAndInvoicing/TruckExpensesModal";
import { useAuth } from "../../hooks/useAuth";

export default function TransportReport() {
  useScrollTop();
  const { backendUrl } = useAuth();
  const [btnSummaryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const initialFilterParams = {
    startDate: format(startOfMonth(new Date()), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
  };

  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const fetchTransports = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/transport/by-truck?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { data = { count: 0, transports: [], revenue: 0 }, refetch } = useQuery(
    ["TRANSPORTS_BY_TRUCK", queryParams],
    () => fetchTransports(queryParams),
    {
      keepPreviousData: true,
    }
  );

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setQueryParams({
      ...queryParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setQueryParams({
      ...queryParams,
      startDate: "",
      endDate: "",
    });
  };

  return (
    <main className="cash-book">
      <div className="content">
        <header>
          <h1>
            Transport Accounting / Revenue
            <button onClick={() => refetch()} className="btn text-primary">
              <CachedIcon />
            </button>
          </h1>
          <div className="actions mr-5 gap-3">
            <RsDateRangePicker
              placement="bottomEnd"
              defaultValue={
                queryParams.startDate && queryParams.endDate
                  ? [
                      parse(queryParams.startDate, "yyyy-MM-dd", new Date()),
                      parse(queryParams.endDate, "yyyy-MM-dd", new Date()),
                    ]
                  : []
              }
              onClean={() => clearDateRange()}
              onOk={(date) => filterByDateRange(date)}
            />
          </div>
        </header>

        <section className="px-4">
          <Table
            responsive
            borderless
            striped
            className="product-table text-nowrap"
          >
            <thead>
              <tr>
                <th>Vehicle</th>
                {/* <th>Revenue</th>
              <th>Administrative Charges</th>
              <th>Maintenance Expenses </th>
              <th>Final Revenue </th> */}

                <th>Total Maintenance Expenses </th>
                <th>Total Admin Fee</th>
                <th>Total Fueling</th>
                <th>Total Driver Wage</th>
                <th>Total Repair Cost</th>
                <th>Total Road Expense</th>
                <th>Total Monthly Expense</th>
                <th>Total Cost</th>
                <th>Total Revenue</th>
                <th>{"  "}</th>
              </tr>
            </thead>
            <tbody>
              {data.transports.map((el, index) => (
                <tr key={index}>
                  <td>{el.Vehicle}</td>
                  {/*   <td>
                  {currency(el?.revenue, {
                    symbol: "",
                  }).format()}
                </td>
                <td>
                  {currency(el?.administrationCharges, {
                    symbol: "",
                  }).format()}
                </td>
               
              */}
                  <td>
                    {currency(el?.maintenanceExpenses, {
                      symbol: "",
                    }).format()}
                  </td>
                  <td>
                    {currency(el?.totalAdminFee, {
                      symbol: "",
                    }).format()}
                  </td>
                  <td>
                    {currency(el?.totalFueling, {
                      symbol: "",
                    }).format()}
                  </td>
                  <td>
                    {currency(el?.totalDriverwage, {
                      symbol: "",
                    }).format()}
                  </td>
                  <td>
                    {currency(el?.totalRepairCost, {
                      symbol: "",
                    }).format()}
                  </td>
                  <td>
                    {currency(el?.totalRoadExpense, {
                      symbol: "",
                    }).format()}
                  </td>
                  <td>
                    {currency(el?.totalMonthlyExpense, {
                      symbol: "",
                    }).format()}
                  </td>
                  <td>
                    {currency(el?.totalCost, {
                      symbol: "",
                    }).format()}
                  </td>
                  <td>
                    {currency(el?.finalRevenue, {
                      symbol: "",
                    }).format()}
                  </td>

                  <td>
                    <button
                      onClick={() => setSelectedVehicle(el.vehicleDetails)}
                      className="btn btn-outline-primary"
                    >
                      View Expenses
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </section>

        <div className="d-flex justify-content-end mt-3">
          <h4 className="h5 text-right m-0 px-4">
            Revenue:{" "}
            {currency(data?.totalCost, {
              symbol: "",
            }).format()}
          </h4>
        </div>

        <div className="d-flex justify-content-end">
          <hr className="mx-4 mb-0 w-25 border-gray" />
        </div>

        <div className="d-flex justify-content-end total-info mt-0 px-4">
          <div>
            <table className="table table-borderless balance">
              <tbody>
                <tr>
                  <td>Total Maintenance</td>
                  <td>
                    {currency(data.maintenanceExpenses, {
                      symbol: "",
                    }).format()}
                  </td>
                </tr>
                <tr>
                  <td>Total Admin Fee</td>
                  <td>
                    {currency(data.totalAdminFee, {
                      symbol: "",
                    }).format()}
                  </td>
                </tr>

                <tr>
                  <td>Total Fueling</td>
                  <td>
                    {currency(data?.totalFueling, {
                      symbol: "",
                    }).format()}
                  </td>
                </tr>

                <tr>
                  <td>Total Driver wage</td>
                  <td>
                    {currency(data?.totalDriverWage, {
                      symbol: "",
                    }).format()}
                  </td>
                </tr>

                <tr>
                  <td>Transit Repairs</td>
                  <td>
                    {currency(data?.totalRepairCost, {
                      symbol: "",
                    }).format()}
                  </td>
                </tr>

                <tr>
                  <td>Total Road Expenses</td>
                  <td>
                    {currency(data?.totalRoadExpense, {
                      symbol: "",
                    }).format()}
                  </td>
                </tr>

                <tr>
                  <td>Total Monthly Expenses</td>
                  <td>
                    {currency(data?.totalMonthlyExpense, {
                      symbol: "",
                    }).format()}
                  </td>
                </tr>

                {/* <tr>
                  <td>Total Cost</td>
                  <td>
                    {currency(data?.totalCost, {
                      symbol: "",
                    }).format()}
                  </td>
                </tr>
 */}
                {/* <tr>
                  <td>Total Revenue (after expenses)</td>
                  <td>
                    {currency(data?.finalRevenue, {
                      symbol: "",
                    }).format()}
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <hr className="mx-4 mb-0 border-gray" style={{ width: "32%" }} />
        </div>

        <div className="d-flex justify-content-end mt-3">
          <h4 className="h5 text-right m-0 px-4">
            Total Revenue (after expenses): {"  "}
            {currency(data?.finalRevenue, {
              symbol: "",
            }).format()}
          </h4>
        </div>

        <div className="d-flex justify-content-between px-3 align-items-center pagination">
          {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
          <div className="pagination_left">
            <p className="m-0 p-0">Show</p>
            <select
              value={queryParams.limit}
              name="limit"
              className="form-select "
              onChange={(e) => handleSearchQueryChange(e)}
            >
              <option value="10">10 rows</option>
              <option value="20">20 rows</option>
              <option value="30">30 rows</option>
              <option value="40">40 rows</option>
            </select>
          </div>

          <ReactPaginate
            {...paginationOptions}
            pageCount={Math.ceil(data.count / queryParams.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={0}
            onPageChange={({ selected }) => {
              document.body.scrollTop = document.documentElement.scrollTop = 0;
              setQueryParams({
                ...queryParams,
                page: selected + 1,
              });
            }}
            forcePage={queryParams.page - 1}
          />
        </div>

        {/* <div className="p-4 border-top">
          <h2>Summary</h2>
          <div className="summary">
            <table className="table table-borderless balance">
              <tbody>
                <tr>
                  <td>Total Cash Sales</td>
                  <td>5,800.00</td>
                </tr>

                <tr>
                  <td>Total Cash PMT</td>
                  <td>350,250,895.00</td>
                </tr>

                <tr>
                  <td>Total Credit/Debit Card/Cheque</td>
                  <td>1,204,449,629.06</td>
                </tr>

                <tr>
                  <td>Total Cash</td>
                  <td>350,256,695.00</td>
                </tr>
              </tbody>
            </table>
            <table className="table table-borderless balance">
              <tbody>
                <tr>
                  <td>Total Credit Sales</td>
                  <td>1,688,912,055.73</td>
                </tr>

                <tr>
                  <td>Total Posted</td>
                  <td>3,243,668,379.79</td>
                </tr>
              </tbody>
            </table>
            <table className="table table-borderless balance">
              <tbody>
                <tr>
                  <td>Total Expenses</td>
                  <td>0.00</td>
                </tr>

                <tr>
                  <td>POS to Bank</td>
                  <td>103,366,800.00</td>
                </tr>

                <tr>
                  <td>Direct Bank Transfer</td>
                  <td>1,091,328,596.19</td>
                </tr>

                <tr>
                  <td>Cash Sent to Bank</td>
                  <td>0.00</td>
                </tr>

                <tr>
                  <td>Cash at Hand</td>
                  <td>337,732,030.10</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}

        {/*  <div
          className={
            btnSummaryActive
              ? `summaryNew border-top open`
              : `summaryNew border-top`
          }
        >
          <div className="summaryHeader">
            <h2>Summary</h2>
            {btnSummaryActive && <SummaryCloseIcon onClick={close} />}
          </div>

          <div className="gridCont">
            <div className="gridChild gridChildBorderLeftBlue">
              <div className="gridChildLeft">
                <p className="gridChld1">
                  {currency(data?.Cash_Sales, {
                    symbol: "",
                  }).format()}
                </p>
                <p className="gridChld2">Total Cash Sales</p>
              </div>

              <div className="gridChildRight">
                <SummaryChartIconUp />
                <p>5%</p>
              </div>
            </div>

            <div className="gridChild gridChildBorderLeftOrange">
              <div className="gridChildLeft">
                <p className="gridChld1">
                  {currency(data?.Credit_Sales, {
                    symbol: "",
                  }).format()}
                </p>
                <p className="gridChld2">Total Credit Sales</p>
              </div>

              <div className="gridChildRight">
                <SummaryChartIconUp />
                <p>5%</p>
              </div>
            </div>

            <div className="gridChild gridChildBorderLeftBrown">
              <div className="gridChildLeft">
                <p className="gridChld1">
                  {currency(data.cashAtHand, {
                    symbol: "",
                  }).format()}
                </p>
                <p className="gridChld2">Cash at Hand</p>
              </div>

              <div className="gridChildRight">
                <SummaryChartIconDown />
                <p>5%</p>
              </div>
            </div>

            <div className="gridChild gridChildBorderLeftDarkGreen">
              <div className="gridChildLeft">
                <p className="gridChld1">
                  {currency(data?.totalExpenses, {
                    symbol: "",
                  }).format()}
                </p>
                <p className="gridChld2">Total Expenses</p>
              </div>

              <div className="gridChildRight">
                <SummaryChartIconUp />
                <p>5%</p>
              </div>
            </div>

            {showMoreSummary ? (
              <>
                <div className="gridChild gridChildBorderLeftDarkerGreen">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.Cash_PMT, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Cash PMT</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>

                <div className="gridChild gridChildBorderLeftLightYellow">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.Cheque_PMT, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Credit/Debit Card Cheque</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconDown />
                    <p>5%</p>
                  </div>
                </div>

                <div className="gridChild gridChildBorderLeftLightPurple">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.cashSentToBank, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Cash Sent to bank</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>

                <div className="gridChild gridChildBorderLeftDarkPurple">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.posToBank, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">POS to Bank</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>

                <div className="gridChild gridChildBorderLeftBlue">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.totalPosted, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Posted</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>

                <div className="gridChild gridChildBorderLeftDarkPurple">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.totalCash, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Cash</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>

                <div className="gridChild gridChildBorderLeftBrownGreen">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data?.directBankTransfer, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Direct Bank Transfer</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>
              </>
            ) : (
              <> </>
            )}
          </div>

          <div className="d-flex">
            {btnSummaryActive && (
              <button
                className="summaryToggler mx-auto btn border-0"
                onClick={() => setShowMoreSummary(!showMoreSummary)}
              >
                {" "}
                {showMoreSummary ? (
                  <span>
                    Hide Summary
                    <ChevronUpIcon />
                  </span>
                ) : (
                  <span>
                    Show More Summary
                    <ChevronDownIcon />
                  </span>
                )}
              </button>
            )}
          </div>
        </div> */}
      </div>

      {selectedVehicle && (
        <TruckExpensesModal
          selectedVehicle={selectedVehicle}
          setSelectedVehicle={setSelectedVehicle}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
        />
      )}
    </main>
  );
}
