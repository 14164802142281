import {
  Dropdown,
  Table,
  Form,
  Button,
  Badge,
  Modal,
  InputGroup,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  ExchangeFunds,
  ExcelIcon,
  PDFIcon,
  DeleteIcon,
  EditIcon,
  RecieptIcon,
  DropdownCloseIcon,
  DropdownEyeIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState, useMemo } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useCurrencies,
  useEffectOnce,
  useIsAdmin,
  useLocalStorage,
  useQueryParams,
  useReconcilation,
  useScrollTop,
} from "../../utils/hooks";
import { services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useMutation, useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  customerFullName,
  defaultSelectValue,
  formatDate,
  formatNumberSystem,
  paginationOptions,
  reactSelectTheme,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import { useAuth } from "../../hooks/useAuth";
import { isEmpty, uniqBy } from "lodash";
import NoTableItem from "../utils/NoTableItem";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { toast } from "react-toastify";
import ConfirmDialog from "../ConfirmDialogue";
import ReconcilationCheckbox from "../utils/ReconcilationCheckbox";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik, FieldArray, FormikProvider } from "formik";
import CreatableSelect from "react-select/creatable";
import JobItemsModal from "./JobItems";
import MileStoneModal from "./MileStoneModal";

import ExpensesSelector from "../utils/ExpensesSelector";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import VendorRFQFormsModal from "../VendorRFQForms";
import FormPreviewModal from "../Requisition/FormPreviewModal";
import { nanoid } from "nanoid";
import { cloneDeep } from "lodash";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import FillFormModal from "../Requisition/FillFormModal";

function Attachments({ files = [], setFiles, milestone }) {
  //  const [files, setFiles] = useState([]);
  const { backendUrl } = useAuth();
  return (
    <div>
      <div>
        {files?.map((el, index) => (
          <div key={index} className="d-flex align-items-center">
            {el?.fileId ? (
              <a
                href={`${backendUrl}/api/stage/view-certificate/${
                  el.systemFilename
                }`}
                target="_blank"
                rel="noreferrer"
                className="text-primary"
              >
                {el?.name}
              </a>
            ) : (
              <span>{el?.name}</span>
            )}

            <Button
              variant=""
              size="sm"
              onClick={(e) => setFiles(files.filter((el, i) => i !== index))}
            >
              <DropdownCloseIcon width={16} />
            </Button>
            <hr className="m-0" />
          </div>
        ))}
      </div>

      <Form.Label className="btn btn-light-blue">
        <span>Add Files </span>
        <Form.Control
          type="file"
          className="d-none"
          multiple
          onChange={(e) => setFiles([...files, ...e.target.files])}
        />
      </Form.Label>
    </div>
  );
}

function FormPicker({ selectedForms = [], setSelectedForms }) {
  const [showForms, setShowForms] = useState(false);
  const { backendUrl } = useAuth();
  try {
    return (
      <div>
        <div>
          {selectedForms &&
            selectedForms?.map((el, index) => (
              <div key={index} className="d-flex align-items-center">
                <span className="text-primary">
                  {el?.formNumberPrefix} {"  "} {el?.formName} - {el?.UserName}
                </span>

                <Button
                  variant=""
                  size="sm"
                  onClick={(e) =>
                    setSelectedForms(
                      selectedForms.filter((el, i) => i !== index)
                    )
                  }
                >
                  <DropdownCloseIcon width={16} />
                </Button>
                <hr className="m-0" />
              </div>
            ))}
        </div>

        <Button
          className="btn btn-light-blue"
          type="BUTTON"
          onClick={() => setShowForms(true)}
        >
          <span>Add Forms </span>
        </Button>

        {showForms && (
          <VendorRFQFormsModal
            showForms={showForms}
            setShowForms={setShowForms}
            okay={(form) => {
              if (form?.Template) {
                form.Template = JSON.parse(form.Template);
              }
              if (form?.filledFormData) {
                form.filledFormData = JSON.parse(form.filledFormData);
              }
              //    console.log(form);

              form.stageFormId = nanoid();
              setSelectedForms([...selectedForms, form]);
              setShowForms(false);
            }}
            requiresVendor={false}
          />
        )}
      </div>
    );
  } catch (err) {
    return JSON.stringify(selectedForms);
  }
}

export default function StagesByJob({ hasReconcilation = false }) {
  useScrollTop();
  const { deploymentCurrencies: currenciesOptions } = useCurrencies();
  const initialFilterParams = {
    jobNumber: "",
    title: "",
    // branch: "",
    /*  startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"), */
  };

  const { backendUrl } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [selectedJob, setSelectedJob] = useState();
  const [selectedStage, setSelectedStage] = useState();
  const [showAddStageModal, setShowAddStageModal] = useState(false);
  const [selectedJobForMileStone, setSelectedJobForMileStone] = useState();
  const [jobStatus, setJobStatus] = useState();
  const [selectedFormToFill, setSelectedFormToFill] = useState();
  const [selectedFilledForm, setSelectedFilledForm] = useState();
  const [isOperation, setIsOperation] = useState(false);
  const location = useLocation();

  const [showFormPreviewModal, setShowFormPreviewModal] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const isAdmin = useIsAdmin();
  const navigate = useNavigate();
  const [showExpensePopover, setShowExpensePopover] = useState(false);

  const CSVLinkRef = useRef(null);

  const [jobStatusOptions, setJobStatusOptions] = useState([
    {
      label: "Start Work",
      value: "Start Work",
    },
    {
      label: "Stop Work",
      value: "Stop Work",
    },
    {
      label: "Pending",
      value: "Pending",
    },
  ]);

  const [systemStages, setSystemStages] = useState([
    {
      label: "PERMIT TO WORK",
      value: "PERMIT TO WORK",
    },
    {
      label: "JOB HAZARD ANALYSIS",
      value: "JOB HAZARD ANALYSIS",
    },
    {
      label: "TOOL BOX FORMS",
      value: "TOOL BOX FORMS",
    },
    {
      label: "WORK METHOD STATEMENT",
      value: "WORK METHOD STATEMENT",
    },
    {
      label: "JOB PROCEDURE",
      value: "JOB PROCEDURE",
    },
    {
      label: "MEDICAL CERTIFICATIONS",
      value: "MEDICAL CERTIFICATIONS",
    },
    {
      label: "EQUIPMENT CERIFICATION",
      value: "EQUIPMENT CERIFICATION",
    },
    {
      label: "TRAINING CERTIFICATION",
      value: "TRAINING CERTIFICATION",
    },
    {
      label: "NON COMFORMITY FORM",
      value: "NON COMFORMITY FORM",
    },
    {
      label: "CORRECTIVE FORM",
      value: "CORRECTIVE FORM",
    },
  ]);

  useEffectOnce(() => {
    //console.log(location);
    if (location.state) {
      setIsOperation(location.state?.isOperation);
    }
  });

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    /*  page: 1,
    limit: 40, */
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const fetchStages = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/job/stages?&${queryString.stringify(queryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    setSelectedJob({
      jobNumber: queryParams.jobNumber,
      title: queryParams.title,
    });
    setJobStatus(data.jobStatus);
    setJobStatusOptions(
      uniqBy(
        [
          ...data.jobStatusOptions.map((el) => ({
            label: el.status,
            value: el.status,
          })),
          ...jobStatusOptions,
        ],
        "value"
      )
    );
    setSystemStages(
      uniqBy(
        [
          ...data.jobStages.map((el) => ({
            label: el.title,
            value: el.title,
          })),
          ...systemStages,
        ],
        "value"
      )
    );
    return data;
  };

  const {
    data = { count: 0, stages: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(["JOBS_STAGE", queryParams], () => fetchStages(queryParams), {
    keepPreviousData: true,
    enabled: !!queryParams?.jobNumber,
  });

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/reports/stages?${queryString.stringify(rest)}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;
    exData = exData.data.stages.map((row) => ({
      ...row,
      ReqDate: format(new Date(row.ReqDate), "dd-MMM-yyyy hh:mm:ss a"),
    }));

    exData = exData.map((d) => [
      d.Header,
      d.ReqName,
      d.Department,
      currency(d.Amount, {
        symbol: "",
      }).format(),
      d.Purpose,
      d.ReqDate,
      d.ExpID,
      d.username,
    ]);
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [company],
      ["Jobs Report"],
      [date],
      [""],
      [
        "Exp Header",
        "Requester Name",
        "Department",
        "Amount",
        "Purpose",
        "Exp Date",
        "Exp ID",
        "Username",
      ],
      ...exData,
      [""],
      ["Summary"],
      [
        "Total Jobs",
        currency(data?.totalJobs, {
          symbol: "",
        }).format(),
      ],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };
  const handleSelectedExpense = ({ AccountID, Description }) => {
    setFilterParams({
      ...filterParams,
      jobNumber: AccountID,
      title: Description,
    });
    setSelectedJob({
      jobNumber: AccountID,
      title: Description,
    });
    setShowExpensePopover(false);
  };

  const deleteStage = async (payload) => {
    let response = await fetch(`${backendUrl}/api/job/delete-stage`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteMutation = useMutation((payload) => deleteStage(payload), {
    onSuccess: ({ message }) => {
      refetch();
      toast.success("Deleted");
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const handleDeleteStage = async (stage) => {
    if (
      await ConfirmDialog({
        title: "Delete",
        description: "Are you sure you want to delete",
      })
    ) {
      deleteMutation.mutate(stage);
    }
  };

  const preview = (form) => {
    form.filledFormData = form?.filledFormData
      ? JSON.stringify(form.filledFormData)
      : JSON.stringify(form.Template);
    setSelectedFilledForm(form);
    setShowFormPreviewModal(true);
  };

  const updateJobStatus = async ({ jobNumber, status }) => {
    try {
      setIsLoading(true);
      let response = await fetch(`${backendUrl}/api/job/update-status`, {
        method: "POST",
        body: JSON.stringify({ jobNumber, status }),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      });

      // console.log(response);
      if (!response.ok) {
        response = await response.json();
        throw new Error();
      } else {
        setJobStatus(status);
        toast.success("Success");
        //  refetch();
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to set status, Try again");
    } finally {
      setIsLoading(false);
    }
  };

  const showJobItems = (stage) => {};

  return (
    <main className="cash-book">
      <div className="content">
        <div className="content-main">
          <div className={`filter-area ${showFilter ? "open" : "close"}`}>
            <div className="filter-header">
              <h2>
                <FilterTwoIcon /> Filters
              </h2>

              <Button
                variant="white"
                onClick={() => setShowFilter(!showFilter)}
              >
                <FilterCollapseIcon />
              </Button>
            </div>
            <div className="filter-body">
              <Form>
                <div className="body">
                  {!isOperation && (
                    <>
                      <Form.Group className="mb-2-5">
                        <Form.Label>Job/Contract Number</Form.Label>
                        <InputGroup>
                          <Form.Control
                            name="jobNumber"
                            placeholder="Enter Job/Contract Number"
                            value={filterParams?.jobNumber}
                            onChange={(e) => handleFilterParamsChange(e)}
                          />
                          <Popover
                            isOpen={showExpensePopover}
                            onClickOutside={(e) => {
                              if (e.target.dataset.id === "initiator") return;
                              setShowExpensePopover(false);
                            }}
                            content={() => (
                              <ExpensesSelector
                                handleSelectedExpense={handleSelectedExpense}
                                usage="chart of accounts"
                                DetailType="JOB"
                              />
                            )}
                            position="bottom"
                          >
                            <InputGroup.Text
                              onClick={() =>
                                setShowExpensePopover(!showExpensePopover)
                              }
                            >
                              <MagnifyIcon />
                            </InputGroup.Text>
                          </Popover>
                        </InputGroup>
                      </Form.Group>

                      <Form.Group className="mb-2-5">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          name="title"
                          value={filterParams?.title}
                          placeholder="Enter Title"
                          onChange={(e) => handleFilterParamsChange(e)}
                        />
                      </Form.Group>

                      <hr className="mt-3 mb-4" />
                    </>
                  )}

                  <Form.Group className="">
                    <Form.Label>Date Range</Form.Label>
                    <div className="position-relative">
                      <RsDateRangePicker
                        placement="auto"
                        value={
                          filterParams.startDate && filterParams.endDate
                            ? [
                                parse(
                                  filterParams.startDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                                parse(
                                  filterParams.endDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                              ]
                            : []
                        }
                        onClean={() => clearDateRange()}
                        onOk={(date) => filterByDateRange(date)}
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className="buttons rounded">
                  <Button onClick={() => reset()} variant="white">
                    Reset
                  </Button>
                  <Button onClick={() => search()} variant="primary">
                    Search
                  </Button>
                </div>
              </Form>
            </div>
          </div>

          <div className="content-body">
            <header>
              <h1>
                {!showFilter && (
                  <button
                    onClick={() => setShowFilter(!showFilter)}
                    className="btn filter"
                  >
                    <FilterTwoIcon />
                  </button>
                )}

                {!isEmpty(selectedJob)
                  ? `${selectedJob?.jobNumber} - ${selectedJob?.title}`
                  : ""}
                <button
                  title="Refresh"
                  onClick={() => refetch()}
                  className="btn text-primary"
                >
                  <CachedIcon />
                </button>

                {isOperation && (
                  <h1
                    className={jobStatus === "Stop Work" ? "text-danger" : ""}
                  >
                    <b> {jobStatus}</b>
                  </h1>
                )}
              </h1>

              <div className="actions mr-5">
                {/*  <button className="btn print">
                  Print <PrintIcon />
                </button>

                <button className="btn print">
                  Export <ExportIcon />
                </button>

                <button className="btn sendSms">
                  Update Jobs <ExchangeFunds />
                </button> */}

                {/*   <CSVLink
                  className="btn print d-none"
                  filename={`Jobs(${format(
                    new Date(),
                    "dd-MMM-yyyy hh:mm:ss a"
                  )}).csv`}
                  data={excelData}
                  ref={CSVLinkRef}
                /> */}
                {/* 
                <Dropdown>
                  <Dropdown.Toggle
                    variant=""
                    className="btn print"
                    disabled={isfetchingExcel}
                    bsPrefix=""
                  >
                    Export
                    <ExportIcon color="#008000" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    popperConfig={{
                      strategy: "fixed",
                    }}
                    renderOnMount
                    className="text-center"
                  >
                    <Dropdown.Item
                      as="div"
                      onClick={onDownloadExcelData}
                      className="p-cursor"
                    >
                      Excel <ExcelIcon color="#008000" />
                    </Dropdown.Item>
                    <Dropdown.Item as="div">
                      <a
                        href={`${backendUrl}/api/reports/pdf/stages?${queryString.stringify(
                          rest
                        )}`}
                        target="blank"
                      >
                        PDF <PDFIcon color="#ff0000" />
                      </a>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                {/* <Link
                  to={`/requisition/request?page=1&limit=40&q=&status=&type=Pending Service Invoice`}
                  className="btn  btn-light-blue"
                >
                  Approvals
                </Link> */}

                {!isOperation ? (
                  <>
                    <div
                      style={{ zIndex: 10 }}
                      className="d-flex gap-2 align-items-center"
                    >
                      <span className="fw-5">Job/Contract Status:</span>
                      <CreatableSelect
                        classNamePrefix={"form-select"}
                        value={jobStatusOptions.find(
                          (el) => el.value === jobStatus
                        )}
                        options={jobStatusOptions}
                        onChange={({ value }) => {
                          updateJobStatus({
                            jobNumber: selectedJob.jobNumber,
                            status: value,
                          });

                          // Save to Backend
                        }}
                      />
                    </div>{" "}
                    <Button
                      variant="primary"
                      onClick={() =>
                        isEmpty(selectedJob)
                          ? toast.error("Please select Job/Contract")
                          : setShowAddStageModal(true)
                      }
                    >
                      Add New
                    </Button>
                  </>
                ) : (
                  <div>{"  "}</div>
                )}
              </div>
            </header>

            <div className="px-md-4">
              <Table responsive borderless striped className="product-table">
                <thead>
                  <tr>
                    <th />
                    {/*  <th>Job/Contract Number</th> */}
                    <th>Title</th>
                    <th>Description</th>
                    {/*     <th>Customer/Client</th> */}
                    {/*   <th>Status</th> */}
                    {/*   <th>Completion Certificate</th> */}
                    {/*   <th>Cost</th> */}
                    <th>Date </th>
                    <th>Username</th>
                    <th>Attachments</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.stages.map((el, index) => (
                    <tr
                      key={index}
                      onClick={(e) =>
                        e.detail === 2 &&
                        navigate(
                          `details/${encodeURIComponent(el.stageNumber)}`
                        )
                      }
                    >
                      <td>
                        <Dropdown style={{ margin: 0 }}>
                          <Dropdown.Toggle
                            variant=""
                            className="bg-white border-0"
                            bsPrefix="print more"
                          >
                            <DotsVerticalIcon />
                          </Dropdown.Toggle>
                          <Dropdown.Menu
                            className="dropdown-with-icons"
                            popperConfig={{
                              strategy: "fixed",
                            }}
                            renderOnMount
                          >
                            {/*  <Dropdown.Item as="div">
                                <Link
                                  to={`details/${encodeURIComponent(
                                    el.stageNumber
                                  )}`}
                                >
                                  <RecieptIcon />
                                  Details
                                </Link>
                              </Dropdown.Item> */}
                            <Dropdown.Item
                              as="button"
                              onClick={() => setSelectedStage(el)}
                            >
                              <EditIcon />
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              as="button"
                              onClick={() => handleDeleteStage(el)}
                            >
                              <DeleteIcon />
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                      {/*   <td>{el.stageNumber}</td> */}
                      <td>{el.title}</td>
                      <td>{el.description}</td>
                      {/*   <td>{customerFullName(el.customer)}</td> */}
                      {/*   <td>{el.status}</td> */}
                      {/*  <td>
                        {currency(el.stageCost, {
                          symbol: currenciesOptions.find(
                            (curr) =>
                              curr.cc === (el?.currency ? el?.currency : generalSettings?.prevailingCurrency)
                          )?.symbol,
                        }).format()}
                      </td> */}
                      <td className="text-nowrap">
                        {el.dateLog
                          ? format(new Date(el.dateLog), "dd MMM yyyy")
                          : "..."}
                      </td>
                      <td>{el.username}</td>
                      <td className="d-flex gap-3">
                        <div className="">
                          <h2 className="h6">Files</h2>
                          {el?.files &&
                            JSON.parse(el?.files)?.map((el, index) => (
                              <div
                                key={index}
                                className="d-flex align-items-center"
                              >
                                <a
                                  href={`${backendUrl}/api/job/view-stage-file/${
                                    el.systemFileName
                                  }`}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-primary"
                                >
                                  {el?.name}
                                </a>
                              </div>
                            ))}
                        </div>
                        <hr />
                        <div className="">
                          <h2 className="h6">Forms</h2>
                          {el?.selectedForms &&
                            JSON.parse(el?.selectedForms)?.map(
                              (form, index) => (
                                <div
                                  key={index}
                                  className="d-flex align-items-center  gap-3"
                                >
                                  <Button
                                    onClick={() => preview(form)}
                                    variant=""
                                    className="text-primary "
                                  >
                                    {formatNumberSystem(
                                      form?.formNumberPrefix,
                                      form?.currentNumber
                                    )}
                                    {"-  "}
                                    {form?.formName} - {form?.UserName}
                                  </Button>

                                  <Button
                                    onClick={() => preview(form)}
                                    variant="light-blue"
                                    size="sm"
                                  >
                                    View
                                  </Button>

                                  {form.Template && (
                                    <Button
                                      onClick={() =>
                                        setSelectedFormToFill({
                                          ...form,
                                          stageId: el.id,
                                        })
                                      }
                                      variant="light-blue"
                                      size="sm"
                                    >
                                      Fill
                                    </Button>
                                  )}
                                </div>
                              )
                            )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {!isFetching && isSuccess && isEmpty(data?.stages) ? (
                <div>
                  <NoTableItem queryParams={queryParams} />
                  <p className="text-center">{queryParams?.title}</p>
                </div>
              ) : isEmpty(queryParams.jobNumber) ? (
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <NoTableItem queryParams={{}} title="" />
                  <Button
                    type="button"
                    onClick={() => setShowExpensePopover(true)}
                    className="text-center"
                    variant="outline-primary"
                    data-id="initiator"
                  >
                    Select Job/Contract
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <ModalLoader
        show={isfetchingExcel || deleteMutation.isLoading || isLoading}
      />
      {showAddStageModal && selectedJob ? (
        <AddJobStage
          selectedJob={selectedJob}
          /*  setSelectedStage={setSelectedStage} */
          refetch={refetch}
          systemStages={systemStages}
          setShowAddStageModal={setShowAddStageModal}
        />
      ) : null}
      {showFormPreviewModal && selectedFilledForm ? (
        <FormPreviewModal
          showFormPreviewModal={showFormPreviewModal}
          setShowFormPreviewModal={setShowFormPreviewModal}
          template={selectedFilledForm.filledFormData}
          selectedFilledForm={selectedFilledForm}
          setSelectedFilledForm={setSelectedFilledForm}
        />
      ) : null}
      {selectedStage ? (
        <EditJobStage
          selectedJob={selectedJob}
          selectedStage={selectedStage}
          setSelectedStage={setSelectedStage}
          refetch={refetch}
          systemStages={systemStages}
          setShowAddStageModal={setShowAddStageModal}
        />
      ) : null}
      {selectedFormToFill && (
        <FillFormModal
          openFilledForm={selectedFormToFill}
          setOpenFilledForm={setSelectedFormToFill}
          name={""}
          setthefilledForm={() => refetch()}
          mainFormID={selectedFormToFill?.id}
          stageId={selectedFormToFill?.stageId}
          stageFormId={selectedFormToFill?.stageFormId}
        />
      )}
    </main>
  );
}

function AddJobStage({
  /*  selectedStage,
  setSelectedStage, */
  selectedJob,
  setSelectedStage,
  setShowAddStageModal,
  refetch,
  systemStages = [],
  selectedStage,
}) {
  const { backendUrl } = useAuth();
  const [showForms, setShowForms] = useState(false);
  const formik = useFormik({
    initialValues: {
      files: [],
      jobNumber: selectedJob?.jobNumber,
      title: "",
      description: "",
      selectedForms: [],
      status: "",
    },
    validationSchema: yup.object().shape({}),
    onSubmit: (values) => {
      // console.log(values, "jdj");
      addStageMutation.mutate(values);
    },
  });

  const addStageApi = async (payload) => {
    payload = cloneDeep(payload);
    const formData = new FormData();
    payload.selectedForms = JSON.stringify(payload.selectedForms);

    //   console.log(payload);
    for (const [key, value] of Object.entries(payload)) {
      if (key === "files") {
        for (const file of value) {
          if (!file?.fileId) {
            formData.append("files", file);
          }
        }
        continue;
      }
      formData.append(key, value);
    }
    /* formData.append(
      "alreadyUploadedFiles",
      JSON.stringify(payload.files.filter((el) => el?.fileId))
    ); */

    let response = await fetch(`${backendUrl}/api/job/add-stage`, {
      method: "POST",
      credentials: "include",
      body: formData,
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const addStageMutation = useMutation((payload) => addStageApi(payload), {
    onSuccess: (data) => {
      toast.success("Success");
      refetch();
      setShowAddStageModal();
    },
    onError: (err) => {
      toast.error("Unable to Upload");
    },
  });

  return (
    <>
      <Modal
        show={true}
        animation={false}
        centered
        enforceFocus={false}
        onHide={() => setShowAddStageModal()}
      >
        <Modal.Header closeButton>
          <h2 className="h5">Add New</h2>{" "}
        </Modal.Header>
        <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
          <Modal.Body>
            <div className="mb-4">
              <h3 className="h6">{selectedJob?.title}</h3>
            </div>
            <Form.Group className={"mb-3"}>
              <Form.Label>Title</Form.Label>
              <CreatableSelect
                classNamePrefix="form-select"
                options={systemStages}
                value={systemStages.find(
                  (el) => el.value === formik.values.title
                )}
                onChange={({ value }) => formik.setFieldValue("title", value)}
                isSearchable
              />
            </Form.Group>
            <Form.Group className={"mb-3"}>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as={"textarea"}
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
              />
            </Form.Group>

            {/*  <Form.Group className={"mb-3"}>
              <Form.Label>Status</Form.Label>
              <CreatableSelect
                options={[]}
                onChange={({ value }) => formik.setFieldValue("status", value)}
                isSearchable
              />
            </Form.Group> */}

            <Form.Group className={"mb-3"}>
              <Form.Label>Form</Form.Label>
              <FormPicker
                selectedForms={formik.values.selectedForms}
                setSelectedForms={(forms) =>
                  formik.setFieldValue("selectedForms", forms)
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Upload</Form.Label>
              <Attachments
                files={formik.values.files}
                setFiles={(files) => formik.setFieldValue("files", files)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              type="button"
              onClick={() => setShowAddStageModal()}
            >
              Cancel
            </Button>
            <Button type="submit">Post</Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <ModalLoader show={addStageMutation.isLoading} />{" "}
    </>
  );
}

function EditJobStage({
  selectedStage,
  setSelectedStage,
  selectedJob,
  setShowAddStageModal,
  refetch,
  systemStages = [],
}) {
  const { backendUrl } = useAuth();
  // const [showForms, setShowForms] = useState(false);
  const formik = useFormik({
    initialValues: {
      stageId: selectedStage.id,
      files: selectedStage?.files
        ? JSON.parse(selectedStage?.files)
        : selectedStage?.files,
      jobNumber: selectedJob?.jobNumber,
      title: selectedStage?.title,
      description: selectedStage?.description,
      selectedForms: selectedStage?.selectedForms
        ? JSON.parse(selectedStage?.selectedForms)
        : selectedStage?.selectedForms,
      // status: "",
    },
    validationSchema: yup.object().shape({}),
    onSubmit: (values) => {
      // console.log(values, "jdj");
      updateStageMutation.mutate(values);
    },
  });

  const updateStageApi = async (payload) => {
    payload = cloneDeep(payload);
    const formData = new FormData();

    payload.selectedForms = JSON.stringify(payload.selectedForms);

    //   console.log(payload);
    for (const [key, value] of Object.entries(payload)) {
      if (key === "files") {
        for (const file of value) {
          if (!file?.fileId) {
            formData.append("files", file);
          }
        }
        continue;
      }
      formData.append(key, value);
    }
    formData.append(
      "alreadyUploadedFiles",
      JSON.stringify(payload.files.filter((el) => el?.fileId))
    );

    let response = await fetch(`${backendUrl}/api/job/update-stage`, {
      method: "POST",
      credentials: "include",
      body: formData,
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const updateStageMutation = useMutation(
    (payload) => updateStageApi(payload),
    {
      onSuccess: (data) => {
        toast.success("Success");
        refetch();
        setSelectedStage();
      },
      onError: (err) => {
        toast.error("Unable to Save");
      },
    }
  );

  return (
    <>
      <Modal
        show={true}
        animation={false}
        centered
        enforceFocus={false}
        onHide={() => setSelectedStage()}
      >
        <Modal.Header closeButton>
          <h2 className="h5">Edit</h2>{" "}
        </Modal.Header>
        <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
          <Modal.Body>
            <div className="mb-4">
              <h3 className="h6">{selectedJob?.title}</h3>
            </div>
            <Form.Group className={"mb-3"}>
              <Form.Label>Title</Form.Label>
              <CreatableSelect
                classNamePrefix="form-select"
                options={systemStages}
                value={systemStages.find(
                  (el) => el.value === formik.values.title
                )}
                onChange={({ value }) => formik.setFieldValue("title", value)}
                isSearchable
              />
            </Form.Group>
            <Form.Group className={"mb-3"}>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as={"textarea"}
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
              />
            </Form.Group>

            {/*   <Form.Group className={"mb-3"}>
              <Form.Label>Status</Form.Label>
              <CreatableSelect
                options={[]}
                onChange={({ value }) => formik.setFieldValue("status", value)}
                isSearchable
              />
            </Form.Group> */}

            <Form.Group className={"mb-3"}>
              <Form.Label>Form</Form.Label>
              <FormPicker
                selectedForms={formik.values.selectedForms}
                setSelectedForms={(forms) =>
                  formik.setFieldValue("selectedForms", forms)
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Upload</Form.Label>
              <Attachments
                files={formik.values.files}
                setFiles={(files) => formik.setFieldValue("files", files)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              type="button"
              onClick={() => setSelectedStage()}
            >
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <ModalLoader show={updateStageMutation.isLoading} />{" "}
    </>
  );
}
