import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DotSpinner, PaymentAndBillingIcon } from "../Icons";
import PageHeader from "../PageHeader";
import { useAuth } from "../../hooks/useAuth";
import { useQuery } from "react-query";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

export default function ProcessingBillingAndPayment() {
  const { backendUrl, token } = useAuth();
  const [stopSpinner, setStopSpinner] = useState(true);
  const [shouldFetch, setShouldFetch] = useState(true);
  const navigate = useNavigate();

  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const reference = params.get("reference");
  const users = params.get("q");

  const fetchcompanyDetails = async (url) => {
    const res = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    });

    const data = await res.json();

    // setComapanyStaff(data?.staff);

    const companyData = data?.company;
    return { ...companyData, currentTime: new Date().getTime() };
  };
  const { data, enabled, refetch } = useQuery(
    ["GET_COMPANY_DETAILS"],
    // make sure to return this url fetchcompanyDetails(`${backendUrl}/api/company/details`)
    () => fetchcompanyDetails(`${backendUrl}/api/company/details`),
    {
      enabled: false,
      refetchInterval: shouldFetch ? 5000 : false, // Start or stop refetching based on shouldFetch state
    }
  );

  useEffect(() => {
    let intervalId; // Declare intervalId variable

    if (shouldFetch) {
      intervalId = setInterval(() => {
        const transactionReference = data?.transactionReference;
        const numberOfUsers = data?.Num_Users;
        const expiringDate = new Date(data?.ExpiryDate).getTime();
        const currentDate = new Date().getTime();
        const userActionType = data?.actionType;

        if (
          transactionReference === reference &&
          numberOfUsers === users &&
          expiringDate >= currentDate
        ) {
          toast.success("Payment confirmed");
          setShouldFetch(false);
          setStopSpinner(false);
          if (userActionType === "expiredRemovedUsers") {
            navigate("/confirm-billing-payment-transaction", {
              state: { reference: reference },
            });
          } else if (userActionType === "expiredRenew") {
            navigate("/confirm-billing-payment-transaction", {
              state: { reference: reference },
            });
          } else if (userActionType === "activeAddedUsers") {
            navigate("/confirm-billing-payment-transaction?create-user=true", {
              state: { reference: reference },
            });
          } else if (userActionType === "expiredAddedUsers") {
            navigate("/confirm-billing-payment-transaction?create-user=true", {
              state: { reference: reference },
            });
          }
        }
        refetch(); // Trigger refetch at the specified interval
      }, 5000);
    }

    return () => {
      clearInterval(intervalId); // Clear interval when component unmounts or shouldFetch becomes false
    };
  }, [shouldFetch, refetch, data]);
  return (
    <main className="inner-page-outlet ">
      <PageHeader
        name="Payment & Billing"
        description="Process Payment"
        icon={<PaymentAndBillingIcon />}
      />

      <main className="confirm-payment">
        {stopSpinner && (
          <div className=" d-flex flex-column justify-content-center align-items-center">
            <DotSpinner />
            <h4>Processing Payment</h4>
          </div>
        )}
      </main>
      {/* <Spinner animation="border" variant="primary" size="sm" /> */}
    </main>
  );
}
