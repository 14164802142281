import { useState, useRef, useEffect, useMemo } from "react";
import { NavLink } from "react-router-dom";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import {
  //   useReportsNavItems,
  //   salesUserReportNavItems,
  //   storeUserReportNavItems,
  salesSupplySubNav,
} from "../../config";
import { useAuth } from "../../hooks/useAuth";
import { lowerCase } from "lodash";
import { useResolveRootRoles } from "../../utils/hooks";

export default function SalesSupplyAnalysisNav(props) {
  const { user: authUser, isCement } = useAuth();
  const { getRootRoles } = useResolveRootRoles();
  //   const reportsNavItems = useReportsNavItems();

  const resolveNav = (department) => {
    department = lowerCase(department);
    // return department === "store"
    //   ? storeUserReportNavItems
    //   : ["sales", "sales manager"].includes(department)
    //   ? salesUserReportNavItems.filter(
    //       (el) =>
    //         (isCement ? el.isCementNav : false) ||
    //         typeof el.isCementNav === "undefined"
    //     )
    //   : reportsNavItems;
    return salesSupplySubNav;
  };

  const items = useMemo(() => {
    const navItems = resolveNav(authUser.Department);
    // return getRootRoles({
    //   navItems,
    // });

    return navItems;
  }, [authUser.Department, authUser.company]);

  const fileHolder = useRef(null);
  const [isShowLeftSroll, setShowLeftScroll] = useState(false);
  const [isShowRightSroll, setShowRightScroll] = useState(false);

  const showLeftSroll = () => {
    setShowLeftScroll(fileHolder?.current && fileHolder.current.scrollLeft > 0);
  };

  const showRightScroll = () => {
    setShowRightScroll(
      fileHolder.current &&
        fileHolder.current.offsetWidth + fileHolder.current.scrollLeft <
          fileHolder.current.scrollWidth
    );
  };

  const srcollFileHolder = () => {
    showLeftSroll();
    showRightScroll();
  };

  useEffect(() => {
    setTimeout(() => {
      if (fileHolder.current) srcollFileHolder();
    }, 50);
  }, [fileHolder]);

  return (
    <div className=" tabs-holder">
      {isShowLeftSroll && (
        <button
          className="btn slider-btn left"
          onClick={() => (fileHolder.current.scrollLeft -= 500)}
        >
          <ChevronLeftIcon />
        </button>
      )}
      <div
        ref={fileHolder}
        onScroll={(e) => srcollFileHolder(e)}
        className="dashboard-tabs innerpage-tabs px-4"
      >
        <ul className="nav nav-tabs">
          {items
            .filter((item) => item?.to)
            .map((item, index) => (
              <li key={index} className="nav-item">
                <NavLink className={`nav-link ga-nav-tab`} to={item?.to}>
                  {item?.name}
                </NavLink>
              </li>
            ))}
        </ul>
      </div>
      {isShowRightSroll && (
        <button
          className="btn slider-btn right"
          onClick={() => (fileHolder.current.scrollLeft += 500)}
        >
          <ChevronRightIcon />
        </button>
      )}
    </div>
  );
}
