import {
  Modal,
  Form,
  Button,
  InputGroup,
  Row,
  Col,
  Table,
  Dropdown,
} from "react-bootstrap";
import * as yup from "yup";
import { FieldArray, useFormik, FormikProvider } from "formik";
import { useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import { useBackendUrl, useEffectOnce, useUnits } from "../utils/hooks";
import NumberCustomInput from "./utils/NumberCustomInput";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import {
  consumptionTypes,
  customerFullName,
  formatForQty,
  // vendorFullName,
  qtyFormat,
  tonsToPcs,
  Units,
  unitsResolver,
} from "../utils/helpers";
import { appSettings } from "../config";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { useStoreActions, useStoreState } from "easy-peasy";
import Select from "react-select";
import ItemSizeDialog from "./ItemSizeDialog";
import { useEffect } from "react";
import currency from "currency.js";
import { Popover } from "react-tiny-popover";
import ExpensesSelector from "./utils/ExpensesSelector";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import eventBus from "../utils/EventBus";
import { useMemo } from "react";
import Datetime from "react-datetime";
import moment from "moment";
import { lowerCase, isEmpty, first } from "lodash";
import ConvertQuantity from "./utils/ConvertQuantity";
import ConfirmDialog from "./ConfirmDialogue";
import VendorSelector from "./Vendor/VendorSelector";
import { DeleteIcon, EditIcon } from "./Icons";
import { cloneDeep } from "lodash";
import AddRodItemModal from "./SalesAndInvoicing/AddRodItemModal";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import EditIronRodItemModal from "./SalesAndInvoicing/EditIronRodItemModal";
import NoTableItem from "./utils/NoTableItem";
// import VendorSearchableSelect from "./utils/VendorSearchableSelect";

export default function CreateBatchConsumptionModal({
  showCreateBatchConsumptionModal = true,
  selectedBatchItem,
  setShowCreateBatchConsumptionModal,
  refetch,
}) {
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const backendUrl = useBackendUrl();
  const [showItemSelectorModal, setShowItemSelectorModal] = useState(false);
  const { Units: saleTypes } = useUnits();
  const [editedItemIndex, setEditedItemIndex] = useState(null);

  const initialValues = {
    parentBar_Code: selectedBatchItem.Bar_Code,
    salesDate: moment(),
    consumptionItems: [],
  };
  const schema = yup.object().shape({
    parentBar_Code: yup.string().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      if (
        await ConfirmDialog({
          title: "Post Consumption",
          description: "Are you sure, you want to post",
        })
      ) {
        outsourceMutation.mutate({
          ...values,
        });
      }
    },
  });

  const outsource = async (payload) => {
    let response = await fetch(`${backendUrl}/api/consumption/add`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const outsourceMutation = useMutation((payload) => outsource(payload), {
    onSuccess: ({ message, data }) => {
      if (refetch) refetch();
      toast.success(message);
      setShowCreateBatchConsumptionModal(false);
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  /*   useEffect(() => {
    formik.setFieldValue(
      "subTotal",
      currency(formik.values.amount).multiply(formik.values.quantity).value
    );
  }, [formik.values.amount, formik.values.quantity]); */
  const tableData = useMemo(() => {
    return cloneDeep(formik.values.consumptionItems);
  }, [formik.values?.consumptionItems]);

  const setTableData = (items) => {
    items = items.map((el) => ({
      ...el,
      //  SubTotal: currency(el.Quantity).multiply(el.UnitCost).value,
    }));
    formik.setFieldValue("consumptionItems", [...items]);
  };

  const handleAddItem = (item) => {
    // console.log(item);
    if (Array.isArray(item)) {
      setTableData(item);
      setShowItemSelectorModal(false);
      return;
    }

    // if sale rep version, ensure all other items have same product_name
    if (!isEmpty(tableData) && appSettings.requireSalesRep) {
      const firstItem = first(tableData);
      //  console.log(firstItem);
      if (firstItem.Product_Name !== item.Product_Name) {
        return toast.error(
          `Select Items with the same manufacturer (${
            firstItem.Product_Name
          }), `
        );
      }
    }

    const foundIndex = tableData.findIndex(
      (el) => el.Bar_Code === item.Bar_Code
    );
    if (foundIndex === -1) {
      setTableData([...tableData, item]);
    } else {
      const oldItem = tableData[foundIndex];
      if (
        (oldItem.saleType !== item.saleType && !appSettings.isBatchStandard) ||
        oldItem.Serial_Number !== item.Serial_Number
      ) {
        setTableData([...tableData, item]);
      } else {
        tableData[foundIndex] = item;
        setTableData([...tableData]);
      }
    }

    formik.setFieldValue("invoiceCat", item.Product_Name);
    setShowItemSelectorModal(false);
  };

  const handleEditItem = (item) => {
    const foundIndex = tableData.findIndex(
      (el) =>
        el.Bar_Code === item.Bar_Code &&
        el.saleType === item.saleType &&
        el.Serial_Number === item.Serial_Number
    );
    tableData[foundIndex] = item;
    setTableData([...tableData]);
    setEditedItemIndex(null);
  };

  const handleRemoveItem = (index) => {
    setTableData([...tableData.filter((el, i) => i !== index)]);
  };

  return (
    <>
      <Modal
        size="lg"
        show={showCreateBatchConsumptionModal}
        onHide={() => {
          setShowCreateBatchConsumptionModal(false);
        }}
        centered={true}
        animation={false}
        enforceFocus={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="h6">
              Add Consumption - {selectedBatchItem?.ItemName}/
              {selectedBatchItem?.Bar_Code}
            </h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormikProvider value={formik}>
            <Form noValidate onSubmit={formik.handleSubmit}>
              {/* <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>

            <Form.Control
              as={"textarea"}
              name="title"
              placeholder="Enter Title"
              value={formik.values.title}
              onChange={formik.handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Type</Form.Label>
            <Select
              options={consumptionTypes}
              value={consumptionTypes.find(
                (el) => el.value === formik.values.type
              )}
              onChange={(selected) =>
                formik.setFieldValue("type", selected.value)
              }
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Quantity</Form.Label>

            <NumberCustomInput
              name="quantity"
              value={formik.values.quantity}
              onValueChange={(value, name) => {
                formik.setFieldValue(name, value);
              }}
              isInvalid={formik.touched.quantity && !!formik.errors.quantity}
            />
            {formik.touched.quantity && !!formik.errors.quantity ? (
              <span className="custom-invalid-feedback">
                {formik.errors.quantity}
              </span>
            ) : null}
          </Form.Group>

          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Amount</Form.Label>
                <CurrencyCustomInput
                  name="amount"
                  value={formik.values.amount}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={formik.touched.amount && !!formik.errors.amount}
                />
                {formik.touched.amount && !!formik.errors.amount ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.amount}
                  </span>
                ) : null}
              </Form.Group>
            </Col>

            <Col>
              <Form.Group className="mb-3">
                <Form.Label>SubTotal</Form.Label>
                <CurrencyCustomInput
                  name="subTotal"
                  value={formik.values.subTotal}
                  onValueChange={(value, name) => {}}
                  readOnly
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3">
            <Form.Label>Remark</Form.Label>
            <Form.Control
              as={"textarea"}
              name="remark"
              placeholder="Enter Remark"
              value={formik.values.remark}
              onChange={formik.handleChange}
              rows={2}
            />
          </Form.Group> */}

              <Table
                borderless
                responsive
                hover
                striped
                className="product-table"
              >
                <thead>
                  <tr>
                    <th />
                    <th>Item Code</th>
                    <th>Item Name</th>
                    <th>Type</th>
                    <th>Cost</th>
                    <th>Quantity</th>
                    <th>Subtotal</th>
                    <th>Remark</th>
                  </tr>
                </thead>

                <tbody>
                  {formik.values?.consumptionItems &&
                    formik.values.consumptionItems.map((el, index) => (
                      <tr
                        key={index}
                        // onClick={(e) => handleRowClick(e, index)}
                        className="p-cursor"
                      >
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant=""
                              className="bg-white border-0"
                              bsPrefix="print more"
                            >
                              <DotsVerticalIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              popperConfig={{
                                strategy: "fixed",
                              }}
                              renderOnMount
                              className="dropdown-with-icons"
                            >
                              <Dropdown.Item
                                as="button"
                                type="button"
                                onClick={() => setEditedItemIndex(index)}
                              >
                                <EditIcon />
                                Edit Item
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                type="button"
                                onClick={() => handleRemoveItem(index)}
                              >
                                <DeleteIcon />
                                Remove Item
                              </Dropdown.Item>
                            </Dropdown.Menu>{" "}
                          </Dropdown>
                        </td>
                        <td>{el.Bar_Code}</td>
                        <td>{el.Item_Name}</td>
                        <td>{el.Cat_Name}</td>
                        <td>
                          {currency(el.UnitCost, {
                            symbol: "",
                          }).format()}{" "}
                        </td>
                        <td>
                          {currency(el.Quantity, {
                            symbol: "",
                            precision: 2,
                            format: formatForQty,
                          }).format()}{" "}
                        </td>
                        <td>
                          {currency(el.totalUnitCost, {
                            symbol: "",
                          }).format()}
                        </td>{" "}
                        <td>{el.remark}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <div>
                {isEmpty(formik.values?.consumptionItems) ? (
                  <NoTableItem queryParams={{}} title="" />
                ) : null}
              </div>

              <div className="d-flex gap-3 my-4 w-100">
                <Button
                  type="button"
                  onClick={() => setShowItemSelectorModal(true)}
                  variant="outline-primary"
                  className="text-nowrap"
                >
                  + Add Item
                </Button>
              </div>

              <Form.Group className="mb-3 col-md-4">
                <Form.Label>Date</Form.Label>
                <Datetime
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  dateFormat="MMM DD, YYYY"
                  name="salesDate"
                  inputProps={{
                    className: `date-input form-control ${
                      formik.touched.salesDate && !!formik.errors.salesDate
                        ? "is-invalid"
                        : ""
                    }`,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.salesDate}
                  onChange={(date) => {
                    formik.setFieldValue("salesDate", date, true);
                  }}
                  onBlur={() => formik.setFieldTouched("salesDate", true)}
                />
              </Form.Group>

              <div className="d-flex justify-content-between border-top pt-3">
                <p>
                  <b>Total: </b>{" "}
                  {!isEmpty(formik.values.consumptionItems) &&
                    currency(
                      formik.values.consumptionItems
                        .map((el) => el.totalUnitCost)
                        .reduce((a, b) => a + b, 0),
                      {
                        symbol: "",
                      }
                    ).format()}
                </p>
                <Button
                  disabled={outsourceMutation.isLoading}
                  variant="primary"
                  className="p-2 px-5"
                  type="submit"
                >
                  {outsourceMutation.isLoading ? "Please wait…" : "Post"}
                </Button>
              </div>
            </Form>
          </FormikProvider>
        </Modal.Body>
      </Modal>
      {showItemSelectorModal && (
        <AddRodItemModal
          showItemSelectorModal={showItemSelectorModal}
          setShowItemSelectorModal={setShowItemSelectorModal}
          handleAddItem={handleAddItem}
          saleTypes={saleTypes}
          lockedTableData={[]}
          selectedCustomer={{}}
          withQuantity={true}
          previouslySelectedItems={tableData}
          usage="selector"
          itemsApiUrl="/api/items1"
        />
      )}
      {editedItemIndex !== null && (
        <EditIronRodItemModal
          setEditedItemIndex={setEditedItemIndex}
          handleEditItem={handleEditItem}
          selectedItemToEdit={{
            ...tableData[editedItemIndex],
          }}
          saleTypes={saleTypes}
          usage="selector"
          itemsApiUrl="/api/items1"
        />
      )}
    </>
  );
}
