import { useCallback, useState } from "react";

import MagnifyIcon from "mdi-react/MagnifyIcon";
import {
  Modal,
  Table,
  Form,
  Button,
  InputGroup,
  FormCheck,
} from "react-bootstrap";
import { appSettings, durationOptions, services } from "../../config";
import useDebounce, {
  useIsAdmin,
  useLocalStorage,
  useUnits,
} from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/item-select-modal.scss";
import { useQuery } from "react-query";
import currency from "currency.js";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import NumberCustomInput from "../utils/NumberCustomInput";
import queryString from "query-string";

import { useFormik, getIn, FieldArray } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { NoSelectedItemIcon } from "../Icons";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useRef } from "react";
import {
  calculateMarkUp_Incentive_Margin,
  paginationOptions,
  pcsToTons,
  qtyFormat,
  qtyFormatToString,
  scrollToElement,
  sortOptions,
  tonsToPcs,
  toTonsOrPcs,
  unitsResolver,
} from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import { useMemo } from "react";
import { useAuth } from "../../hooks/useAuth";
import { isEmpty, lowerCase } from "lodash";
import { useStoreActions, useStoreState } from "easy-peasy";
import ReactPaginate from "react-paginate";
import ModalLoader from "../utils/ModalLoader";
import { truncate } from "lodash";
import SelectBatchDialog from "./SelectBatchDialog";
import { cloneDeep } from "lodash";
import ConvertQuantity from "../utils/ConvertQuantity";
import GlobalItemSearchModal from "../modals/GlobalItemSearchModal";

export default function AddRodItemMultipleModal({
  showItemSelectorModal,
  setShowItemSelectorModal,
  handleAddItem,
  saleTypes = [],
  selectedCustomer,
  withQuantity = false,
  previouslySelectedItems = [],
  mode,
  oldInvoiceData,
  isQuotation,
  itemsApiUrl = "/api/items",
  Warehouse,
  readOnly,
  showInStore = false,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 100,
    q: "",
    batch: true,
    batchData: true,
    withQuantity,
    withItemPriceByCustomerType: true,
    sort: "name-asc",
    showInStore,
  });
  const debouncedqueryParams = useDebounce(queryParams, 500);
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl } = useAuth();
  const { Units, getUnitLabelByValue } = useUnits();
  const generalSettings = useStoreState((state) => state.generalSettings);
  const isAdmin = useIsAdmin();
  const [showSelected, setShowSelected] = useState(false);
  const [uiSettings, setUiSettings] = useLocalStorage("uiSettings", {
    addManufacturerToMultipleItemSelect: true,
    addCategoryToMultipleItemSelect: true,
  });

  const [showGloabItemSearch, setGloablItemShow] = useState(false);

  const initialItem = {
    Quantity: 1,
    UnitPrice: "",
    PriceSold: "",
    durationNumber: 0,
    durationValue: "Month",
    Warranty: false,
    Discount: 0,
    ironRodQuantity: 1,
    saleType: "Tons",
    requirePermission: false,
    Size: "",
  };

  const formik = useFormik({
    initialValues: {
      itemsForSale: previouslySelectedItems
        ? previouslySelectedItems.map((el) => {
            return el
              ? {
                  ...el,
                  Quantity:
                    lowerCase(el.saleType) === "tons"
                      ? pcsToTons(el.Quantity, el.Item_Desc, itemMeasurements)
                      : el.Quantity,
                }
              : { ...el };
          })
        : [],
    },
    validationSchema: yup.object().shape({}),
    onSubmit: async (values) => {
      try {
        const { items: itemsWithBatches } = await getBatches(
          values.itemsForSale
        );
        const itemsForSale = cloneDeep(values.itemsForSale).map((item) => {
          const selectedItem = {
            ...item.selectedItem,
            batches: itemsWithBatches?.find(
              (el) => el.Bar_Code === item.Bar_Code
            )?.batches,
          };

          // =======================================
          if (mode === "edit" && !isEmpty(oldInvoiceData)) {
            const foundItem = oldInvoiceData.find(
              (el) => el.Bar_Code === item.Bar_Code
            );
            selectedItem.soldBatches = foundItem?.soldBatches
              ? foundItem?.soldBatches
              : [];

            selectedItem.batches = [
              ...selectedItem.batches.map((el) => {
                // check if batch was sold before
                const foundSoldBatches = selectedItem.soldBatches.filter(
                  (soldbatch) => soldbatch.Bar_Code === el.Bar_Code
                );

                if (!isEmpty(foundSoldBatches)) {
                  const totalSoldQty = foundSoldBatches
                    .map((el) => el.Quantity)
                    .reduce((a, b) => currency(a).add(b).value, 0);
                  el.Quantity = currency(totalSoldQty).add(el.Quantity).value;
                }

                return el;
              }),
            ];

            // it was sold out - 0
            for (let soldbatch of selectedItem.soldBatches) {
              const foundInSelectedBatches = selectedItem.batches.find(
                (el) => el.Bar_Code === soldbatch.Bar_Code
              );
              if (foundInSelectedBatches) {
                continue;
              } else {
                selectedItem.batches.push(soldbatch);
              }
            }
          }
          //============================================================

          const values = cloneDeep(item);

          // Convert to lowest form, to Validate if enough in stock
          //   const originalQuantity = values.Quantity;
          values.Quantity =
            lowerCase(values.saleType) === "tons" && selectedItem?.Item_Desc
              ? tonsToPcs(
                  values.Quantity,
                  selectedItem.Item_Desc,
                  itemMeasurements
                )
              : values.Quantity;

          if (lowerCase(selectedItem.Item_Type) !== "service" && !isQuotation) {
            if (Number(values.Quantity) > selectedItem?.quantityInStock) {
              values.error = `${
                selectedItem.Item_Name
              }: Not enough item in stock`;
            }
          }

          /* values.Warrant_Duration = `${values.durationNumber} ${
        values.durationValue
      }`; */

          values.Warrant_Duration = values?.Warrant_Duration || "";

          values.PriceSold = currency(values.PriceSold).value;

          values.SubTotal = currency(values.PriceSold).multiply(
            lowerCase(values.saleType) === "tons"
              ? pcsToTons(
                  values.Quantity,
                  selectedItem.Item_Desc,
                  itemMeasurements
                )
              : values.Quantity
          ).value;

          values.Discount = currency(values.Discount, {
            symbol: "",
            separator: "",
          })
            .multiply(
              lowerCase(values.saleType) === "tons"
                ? pcsToTons(
                    values.Quantity,
                    selectedItem.Item_Desc,
                    itemMeasurements
                  )
                : values.Quantity
            )
            .format();

          // Get Profit Based on batch we are picking from---------------------------------------------------
          const quantityToSell = values.Quantity;
          let remainder = quantityToSell;
          const updatedBatchDataToSave = [];
          let measurement = itemMeasurements.find(
            (measurement) => measurement.Size === selectedItem.Item_Desc
          );

          if (
            lowerCase(values.saleType) !== "tons" &&
            lowerCase(values.saleType) !== "pieces"
          ) {
            measurement = {
              Quantity: 1,
            };
          }

          if (lowerCase(values.saleType) === "tons" && !measurement) {
            return toast.error("This item is not sold in Tons");
          }

          const priceSoldPerUnit =
            lowerCase(values.saleType) === "tons"
              ? currency(values.PriceSold)
                  .divide(measurement?.Quantity || 1)
                  .format()
              : values.PriceSold;

          for (let [index, batch] of selectedItem.batches.entries()) {
            const pcsUnitCost = currency(batch.UnitCost, {
              symbol: "",
              separator: "",
            })
              .divide(measurement?.Quantity || 1)
              .format();

            // console.log(selectedItem);

            //
            const {
              calculatedMarkUp,
              calculatedIncentive,
            } = calculateMarkUp_Incentive_Margin({
              ...selectedItem,
              UnitCost: pcsUnitCost,
              PriceSold: priceSoldPerUnit,
              /*  Margin,
          marginType,
          marginPercentage,
          Incentive,
          incentiveType,
          incentivePercentage,
          markUp,
          markUpType,
          markUpPercentage, */
            });

            if (Number(batch.Quantity) >= Number(remainder)) {
              // means we are at the last
              updatedBatchDataToSave.push({
                unitCost: pcsUnitCost,
                quantity: remainder,
                totalUnitCost: currency(pcsUnitCost, {
                  symbol: "",
                  separator: "",
                })
                  .multiply(remainder)
                  .format(),
                totalUnitPrice: currency(priceSoldPerUnit, {
                  symbol: "",
                  separator: "",
                })
                  .multiply(remainder)
                  .format(),
                totalMargin: currency(selectedItem?.Margin || 0)
                  .divide(measurement?.Quantity || 1)
                  .multiply(remainder).value,
                totalIncentive: currency(calculatedIncentive || 0)
                  .divide(measurement?.Quantity || 1)
                  .multiply(remainder).value,
                totalMarkUp: currency(calculatedMarkUp || 0).multiply(remainder)
                  .value,
              });

              if (lowerCase(values.saleType) === "pieces") {
                values.UnitCost = pcsUnitCost;
              }
              break;
            } else {
              updatedBatchDataToSave.push({
                unitCost: pcsUnitCost,
                quantity: batch.Quantity,
                totalUnitCost: currency(pcsUnitCost, {
                  symbol: "",
                  separator: "",
                })
                  .multiply(batch.Quantity)
                  .format(),
                totalUnitPrice: currency(priceSoldPerUnit, {
                  symbol: "",
                  separator: "",
                })
                  .multiply(batch.Quantity)
                  .format(),
                totalMargin: currency(selectedItem?.Margin || 0)
                  .divide(measurement?.Quantity || 1)
                  .multiply(batch.Quantity).value,
                totalIncentive: currency(calculatedIncentive || 0)
                  .divide(measurement?.Quantity || 1)
                  .multiply(batch.Quantity).value,
                totalMarkUp: currency(calculatedMarkUp || 0).multiply(
                  batch.Quantity
                ).value,
              });

              remainder = Number(
                currency(remainder, {
                  symbol: "",
                  separator: "",
                })
                  .subtract(batch.Quantity)
                  .format()
              );
            }
          }

          //  console.log(updatedBatchDataToSave);

          const totalProfit = updatedBatchDataToSave
            .map((el) =>
              currency(el.totalUnitPrice)
                .subtract(el.totalUnitCost)
                .subtract(el.totalIncentive)
                .format()
            )
            .reduce(
              (a, b) =>
                currency(a, {
                  precision: 2,
                }).add(b),
              0
            );

          values.Profit = totalProfit;
          //-------------------------------------------------------------------------------------------------

          //---------------------------------
          values.Total_Incentive = updatedBatchDataToSave
            .map((el) => el.totalIncentive)
            .reduce(
              (a, b) =>
                currency(a, {
                  precision: 2,
                }).add(b),
              0
            );

          //  For Service Overwrite ----------------------------------------------------------
          if (lowerCase(selectedItem.Item_Type) === "service") {
            values.Profit = currency(priceSoldPerUnit)
              .subtract(selectedItem.UnitCost)
              .multiply(values.Quantity).value;
            values.Total_Incentive = currency(selectedItem?.Incentive).multiply(
              values.Quantity
            ).value;
          }

          return {
            ...selectedItem,
            Serial_Number: values?.Size ? values.Size : selectedItem.Item_Desc,
            ...values,
            UnitCost: !isEmpty(updatedBatchDataToSave)
              ? updatedBatchDataToSave[0]?.unitCost
              : selectedItem.UnitCost,
          };
        });

        const foundError = itemsForSale.find((el) => el.error);
        if (foundError) {
          toast.error(foundError.error);
          return;
        }
        handleAddItem(itemsForSale);
      } catch (err) {
        console.log(err);
        toast.error("Unable to get batches, Check connection and try again");
      }
    },
  });

  const [selectedItem, setselectedItem] = useState(null);
  //  const [searchString, setSearchString] = useState("");
  // const debouncedSearchString = useDebounce(searchString, 800);

  const getItems = async (debouncedqueryParams) => {
    const url = Warehouse ? `${itemsApiUrl}/items/${Warehouse}` : itemsApiUrl;

    let response = await fetch(
      `${backendUrl}${url}?${queryString.stringify(debouncedqueryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { data = { items: [], warehouses: [] }, isFetching } = useQuery(
    [queryActions.GET_ITEMS, debouncedqueryParams],
    () => getItems(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const getBatches = async (items) => {
    const url = Warehouse
      ? `${itemsApiUrl}/batches-for-sales/${Warehouse}/multiple?itemCodes=${items
          .map((el) => el.Bar_Code)
          .join(",")}`
      : `${itemsApiUrl}/batches-for-sales/multiple?itemCodes=${items
          .map((el) => el.Bar_Code)
          .join(",")}`;

    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        let response = await fetch(`${backendUrl}${url}`, {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        });
        if (!response.ok) {
          response = await response.json();
          throw new Error();
        } else {
          const { data } = await response.json();
          resolve(data);
        }
      } catch (err) {
        console.log(err);
        toast.error("Unable to load batches, Try again");
        reject();
      } finally {
        setIsLoading(false);
      }
    });
  };

  const handleSelectedItem = (item) => {
    //  console.log(selectedItem);
    // Check Customer type to get correct unit price
    const transType = lowerCase(selectedCustomer?.TransType);
    item.UnitPrice =
      transType === "distributor"
        ? item.Vat_5
        : ["wholesaler", "wholeseler"].includes(transType)
        ? item.Tax
        : item.UnitPrice;

    // Use Customer Types if available for customer
    const foundPriceForCustomerTransType = item?.Items_ItemPriceByCustomerType?.find(
      (el) => selectedCustomer?.TransType === el.TransType
    );

    if (!isEmpty(foundPriceForCustomerTransType)) {
      item.UnitPrice = foundPriceForCustomerTransType.UnitPrice;
      if (Number(foundPriceForCustomerTransType.UnitPricePcs) > 0) {
        item.Tax = foundPriceForCustomerTransType.UnitPricePcs;
      }
    }

    item.unitPriceInTons = item.UnitPrice;
    item.unitPriceInPcs = item.Tax;

    if (transType === "distributor") {
      item.unitPriceInPcs = item.pcsPriceDistributor;
    }

    item.quantityInStock = item.Quantity;
    item.selectedItem = { ...item };
    const UnitPrice = currency(item.UnitPrice).value;

    item.UnitPrice = UnitPrice;
    item.PriceSold = UnitPrice;
    item.Discount = 0;
    item.saleType = item?.Product_Model ? item?.Product_Model : "Each";
    return item;
  };

  const handleSaleType = ({ item, saleType }) => {
    const editedTable = tableData.map((el) =>
      el.Bar_Code === item.Bar_Code
        ? {
            ...el,
            saleType,
            UnitPrice:
              lowerCase(saleType) === "tons"
                ? el.unitPriceInTons
                : lowerCase(saleType) === "pieces"
                ? el.unitPriceInPcs
                : el.UnitPrice,
            PriceSold:
              lowerCase(saleType) === "tons"
                ? el.unitPriceInTons
                : lowerCase(saleType) === "pieces"
                ? el.unitPriceInPcs
                : el.UnitPrice,
          }
        : { ...el }
    );

    setTableData([...editedTable]);
  };

  useEffect(() => {
    const finalSellingPrice = currency(formik.values.UnitPrice, {
      symbol: "",
      separator: "",
    })
      .subtract(formik.values.Discount)
      .format();
    formik.setFieldValue("PriceSold", finalSellingPrice);
  }, [formik.values.Discount]);

  // set quantity
  useEffect(() => {
    // if (selectedItem?.Item_Desc) {
    const quantity =
      lowerCase(formik.values.saleType) === "tons" && selectedItem?.Item_Desc
        ? tonsToPcs(
            formik.values.ironRodQuantity,
            selectedItem.Item_Desc,
            itemMeasurements
          )
        : formik.values.ironRodQuantity;
    formik.setFieldValue("Quantity", quantity);
    //}
  }, [
    formik.values.ironRodQuantity,
    formik.values.saleType,
    selectedItem?.Item_Desc,
    itemMeasurements,
  ]);

  const tableData = useMemo(() => {
    return cloneDeep(formik.values.itemsForSale);
  }, [formik.values?.itemsForSale]);

  const setTableData = (items) => {
    items = items.map((el) => ({
      ...el,
      SubTotal: currency(el.Quantity).multiply(el.PriceSold).value,
    }));
    formik.setFieldValue("itemsForSale", [...items]);
  };

  const addToCart = (selectedItem) => {
    if (tableData.find((el) => el.Bar_Code === selectedItem.Bar_Code)) {
      return;
    }
    const item = handleSelectedItem(cloneDeep(selectedItem));

    let saleType = item.Product_Model;
    if (item.Product_Model === "Tons") {
      // check if has enough tons
      const foundMeasurement = itemMeasurements.find(
        (measurement) => measurement.Size === selectedItem.Item_Desc
      );
      if (foundMeasurement) {
        const quantityInTon = Number(foundMeasurement.Quantity);

        if (Number(quantityInTon) > Number(item.quantityInStock)) {
          saleType = "Pieces";
        }
      }
    }

    const itemToAdd = {
      ...item,
      Quantity: 1,
      durationNumber: 0,
      durationValue: "Month",
      Warranty: false,
      requirePermission: false,
      Size: selectedItem.Item_Desc,
      Serial_Number: selectedItem.Item_Desc,
      selectedBatches: [],
      saleType,
    };

    //  console.log(itemToAdd);
    setTableData([...tableData, itemToAdd]);
  };

  const removeFromCart = (item) => {
    setTableData([...tableData.filter((el) => el.Bar_Code !== item.Bar_Code)]);
  };

  const cartData = useCallback(
    (item) => {
      // console.log(tableData);
      return tableData.find((el) => el.Bar_Code === item.Bar_Code);
    },
    [tableData]
  );

  const addQuantityToCart = ({ item, Quantity }) => {
    setTableData(
      tableData.map((el) =>
        el.Bar_Code === item.Bar_Code
          ? {
              ...el,
              Quantity,
            }
          : { ...el }
      )
    );
  };

  const addPriceToCart = ({ item, PriceSold }) => {
    setTableData(
      tableData.map((el) =>
        el.Bar_Code === item.Bar_Code
          ? {
              ...el,
              PriceSold,
            }
          : { ...el }
      )
    );
  };

  const allItems = useMemo(() => {
    return Warehouse ? data?.warehouses : data?.items;
  }, [Warehouse, data?.warehouses, data?.items]);

  function handleGloabSearch() {
    setGloablItemShow(true);
  }

  return (
    <>
      <Modal
        show={true}
        onHide={() => setShowItemSelectorModal(false)}
        dialogClassName="multi-item-select-modal"
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1>Add Items</h1>
            <p>Select items and set the pricing details.</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <h2>Select Items - {selectedCustomer?.TransType}</h2>

            <div className="items-table-area rounded">
              <div className="d-flex gap-3 w-100 align-items-center">
                <div className="global-search-area m-3 mx-0 flex-grow-1">
                  <MagnifyIcon />
                  <input
                    className="form-control search-input"
                    name="q"
                    value={queryParams.q}
                    onChange={(e) =>
                      setQueryParams({
                        ...queryParams,
                        q: e.target.value,
                        page: 1,
                      })
                    }
                    placeholder="Search item..."
                    autoFocus
                    autoComplete="off"
                  />
                </div>

                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      width: "12rem",
                    }),
                  }}
                  classNamePrefix={`form-select-store`}
                  isSearchable={false}
                  placeholder="Sort By"
                  options={sortOptions}
                  value={sortOptions.find(
                    (el) => el.value === queryParams.sort
                  )}
                  onChange={({ value }) =>
                    setQueryParams({
                      ...queryParams,
                      page: 1,
                      sort: value,
                    })
                  }
                />

                <Form.Group>
                  <Form.Switch
                    inline
                    id="addManufacturerToMultipleItemSelect"
                    label="Show Manufacturer"
                    name="addManufacturerToMultipleItemSelect"
                    type={"checkbox"}
                    checked={uiSettings?.addManufacturerToMultipleItemSelect}
                    onChange={(e) =>
                      setUiSettings({
                        ...uiSettings,
                        addManufacturerToMultipleItemSelect: !uiSettings?.addManufacturerToMultipleItemSelect,
                      })
                    }
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Switch
                    inline
                    id="addCategoryToMultipleItemSelect"
                    label="Show Category"
                    name="addCategoryToMultipleItemSelect"
                    type={"checkbox"}
                    checked={uiSettings?.addCategoryToMultipleItemSelect}
                    onChange={(e) =>
                      setUiSettings({
                        ...uiSettings,
                        addCategoryToMultipleItemSelect: !uiSettings?.addCategoryToMultipleItemSelect,
                      })
                    }
                  />
                </Form.Group>

                <Form.Label className="d-flex gap-3 fw-6">
                  <Form.Switch
                    checked={showSelected}
                    onChange={() => setShowSelected(!showSelected)}
                  />
                  Show Selected
                </Form.Label>

                <Form noValidate onSubmit={formik.handleSubmit}>
                  <Button
                    type="button"
                    className=" submit-btn"
                    onClick={() => formik.submitForm()}
                  >
                    Add to Cart
                  </Button>
                </Form>
              </div>

              <div className="content table-max mb-3">
                <Table
                  borderless
                  responsive
                  striped
                  hover
                  className="product-table"
                >
                  <thead className="position-sticky top-0">
                    <tr>
                      <th />
                      <th scope="col">Item Code</th>
                      <th scope="col">Item Name</th>

                      <th scope="col">Qty In Cart</th>
                      <th scope="col">UOM</th>
                      <th scope="col">Unit Price</th>
                      <th scope="col">Qty In Stock </th>
                      {uiSettings.addCategoryToMultipleItemSelect && (
                        <th scope="col">Category</th>
                      )}
                      {uiSettings?.addManufacturerToMultipleItemSelect && (
                        <th scope="col">Manufacturer</th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="blue-hover">
                    {allItems &&
                      (!showSelected ? allItems : tableData).map(
                        (item, index) => (
                          <tr
                            key={item?.Bar_Code}
                            className={
                              !Boolean(cartData(item)) ? "p-cursor" : ""
                            }
                            onClick={() => {
                              return (
                                !Boolean(cartData(item)) &&
                                addToCart({ ...item })
                              );
                              /*   : removeFromCart({ ...item }); */
                            }}
                          >
                            <td onClick={(e) => e.stopPropagation()}>
                              <FormCheck
                                checked={Boolean(cartData(item))}
                                onChange={(e) =>
                                  e.target.checked
                                    ? addToCart({ ...item })
                                    : removeFromCart({ ...item })
                                }
                              />
                            </td>
                            <td>{item.Bar_Code}</td>
                            <td>
                              <p className="fw-5">{item.Item_Name || "..."}</p>
                              <span>{item?.Item_Desc}</span>
                            </td>

                            <td>
                              {cartData(item) ? (
                                <NumberCustomInput
                                  name={`${item.Bar_Code}Quantity`}
                                  style={{ width: "3rem" }}
                                  value={cartData(item).Quantity}
                                  onValueChange={(value, name) => {
                                    addQuantityToCart({
                                      item,
                                      Quantity: value,
                                    });
                                  }}
                                />
                              ) : (
                                "..."
                              )}
                            </td>
                            <td>
                              {cartData(item) ? (
                                <Select
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      width: "8rem",
                                    }),
                                  }}
                                  classNamePrefix={"form-select"}
                                  placeholder={"Select"}
                                  isSearchable={false}
                                  value={saleTypes.find(
                                    (el) =>
                                      el.value === cartData(item)?.saleType
                                  )}
                                  options={unitsResolver(
                                    saleTypes,
                                    cartData(item)?.saleType
                                  )}
                                  onChange={({ value }) => {
                                    handleSaleType({
                                      item,
                                      saleType: value,
                                    });
                                  }}
                                  isDisabled={item.Product_Model !== "Tons"}
                                />
                              ) : (
                                getUnitLabelByValue(item.Product_Model)
                              )}
                            </td>

                            <td>
                              {cartData(item) ? (
                                <div
                                  style={{
                                    backgroundColor: "#fff",
                                  }}
                                >
                                  <CurrencyCustomInput
                                    style={{ width: "8rem" }}
                                    name={`${item.Bar_Code}PriceSold`}
                                    value={cartData(item)?.PriceSold}
                                    onValueChange={(value, name) =>
                                      addPriceToCart({
                                        item,
                                        PriceSold: value,
                                      })
                                    }
                                    readOnly={readOnly}
                                  />
                                </div>
                              ) : (
                                currency(item.UnitPrice, {
                                  symbol: "",
                                }).format()
                              )}
                            </td>
                            <td>
                              <ConvertQuantity
                                quantity={item.Quantity}
                                desc={item?.Item_Desc}
                                convertNow={true}
                              />
                            </td>
                            {uiSettings.addCategoryToMultipleItemSelect && (
                              <td>{item.Cat_Name}</td>
                            )}
                            {uiSettings.addManufacturerToMultipleItemSelect && (
                              <td>{item.Product_Name}</td>
                            )}
                          </tr>
                        )
                      )}
                  </tbody>
                </Table>
              </div>
              <div className=" d-flex flex-column align-items-center">
                {isEmpty(allItems) && !isFetching && (
                  <div>
                    <p className=" text-center mb-2">Item not found</p>
                    <Button
                      size="lg"
                      variant="outline-primary"
                      onClick={handleGloabSearch}
                    >
                      Try Global Search
                    </Button>
                  </div>
                )}
              </div>

              <div className="d-flex justify-content-end gap-4 align-items-center">
                <Form.Label className="d-flex gap-3 fw-6">
                  <Form.Switch
                    checked={showSelected}
                    onChange={() => setShowSelected(!showSelected)}
                  />
                  Show Selected
                </Form.Label>

                <Button
                  type="button"
                  className=" submit-btn"
                  onClick={() => formik.submitForm()}
                >
                  Add to Cart
                </Button>
              </div>
              <hr />

              {!showSelected && (
                <div className="d-flex justify-content-between px-3 my-3 align-items-center pagination">
                  <p className="m-0">
                    Showing {data?.startIndex + 1} to{" "}
                    {data?.endIndex <= data?.count
                      ? data?.endIndex
                      : data?.count}{" "}
                    of {data.count} entries
                  </p>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ModalLoader
        show={isLoading}
        onExited={() => scrollToElement('input[name="ironRodQuantity"]')}
      />

      {showGloabItemSearch && (
        <GlobalItemSearchModal
          show={showGloabItemSearch}
          setShow={setGloablItemShow}
        />
      )}
    </>
  );
}
