import { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { useIsAdmin, useResolveRootRoles } from "../../utils/hooks";
import { useStoreState } from "easy-peasy";
import { useRef } from "react";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";

export function Nav(props) {
  const { getRootRoles } = useResolveRootRoles();
  const isAdmin = useIsAdmin();
  const generalSettings = useStoreState((state) => state.generalSettings);

  const items = useMemo(() => {
    const navItems = [
      {
        name: "Quotation",
        to: "quotation",
      },
      {
        name: "Proforma",
        to: "proforma",
      },
      {
        name: "Loading Order",
        to: "loading-order",
      },
      ...(generalSettings?.store
        ? [
            {
              name: "Online Store Quotes / Orders",
              to: "online-store-quotes-orders",
            },
          ]
        : []),
      {
        name: "Pre-order",
        to: "pre-order",
      },
      {
        name: "Invoice List",
        to: "invoice-list",
      },
      {
        name: "Customer List",
        to: "customer-list",
      },
      {
        name: "Customer Due Payments",
        to: "upcoming-payments",
      },
      {
        name: "System Companies",
        to: "/eb-companies/eb-company-list",
      },
      ...(generalSettings?.store
        ? [
            {
              name: "Online Store Customer List",
              to: "online-store-customer-list",
            },
          ]
        : []),
      ...(isAdmin
        ? [
            {
              name: "Customer Type",
              to: "customer-type",
            },
            {
              name: "Customer Group",
              to: "customer-group",
            },
          ]
        : []),

      {
        name: "Inventory",
        to: "inventory",
      },
      {
        name: "Manage Transaction",
        to: "manage-transaction",
      },
      {
        name: "Enquires List",
        to: "enquire-list",
      },
      {
        name: "Due Payments",
        to: "due-invoices",
      },
    ];

    return getRootRoles({
      parent: "Sales and Invoicing",
      navItems,
    });
  }, [isAdmin, generalSettings, getRootRoles]);

  const fileHolder = useRef(null);
  const [isShowLeftSroll, setShowLeftScroll] = useState(false);
  const [isShowRightSroll, setShowRightScroll] = useState(false);

  const showLeftSroll = () => {
    setShowLeftScroll(fileHolder?.current && fileHolder.current.scrollLeft > 0);
  };

  const showRightScroll = () => {
    setShowRightScroll(
      fileHolder.current &&
        fileHolder.current.offsetWidth + fileHolder.current.scrollLeft <
          fileHolder.current.scrollWidth
    );
  };

  const srcollFileHolder = () => {
    showLeftSroll();
    showRightScroll();
  };

  useEffect(() => {
    setTimeout(() => {
      if (fileHolder.current) srcollFileHolder();
    }, 50);
  }, [fileHolder]);

  return (
    <div className=" tabs-holder">
      {isShowLeftSroll && (
        <button
          className="btn slider-btn left"
          onClick={() => (fileHolder.current.scrollLeft -= 500)}
        >
          <ChevronLeftIcon />
        </button>
      )}
      <div
        ref={fileHolder}
        onScroll={(e) => srcollFileHolder(e)}
        className="dashboard-tabs innerpage-tabs px-4"
      >
        <ul className="nav nav-tabs">
          {items.map((item, index) => (
            <li key={index} className="nav-item">
              <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
                {item.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
      {isShowRightSroll && (
        <button
          className="btn slider-btn right"
          onClick={() => (fileHolder.current.scrollLeft += 500)}
        >
          <ChevronRightIcon />
        </button>
      )}
    </div>
  );
}
