import currency from "currency.js";
import { useQuery } from "react-query";
import { Outlet } from "react-router-dom";
import {
  //   FileListIcon,
  //   ArrowDownGrowthIcon,
  //   ArrowUpGrowthIcon,
  LineChartIcon,
  //   BucketIcon,
  PageHeaderListIcon,
  CurrencyFillIcon,
  GroupUsers,
} from "../../components/Icons";
import { useAuth } from "../../hooks/useAuth";
import { fetchActionsUtil } from "../../utils/helpers";
import { useIsSales } from "../../utils/hooks";
import "./../../assets/scss/invoicewrapper.scss";

export function VendorWrapper({ Nav }) {
  const { backendUrl } = useAuth();
  //   const isAdmin = useIsAdmin();
  //   const isCashier = useIsCashier();
  const isSales = useIsSales();

  const getStats = async () => {
    // GA - This is slow
    const { data } = await fetchActionsUtil(
      `${backendUrl}/api/vendors/vendor-ledger?page=1&limit=1`,
      "GET"
    );

    const vendors = await fetchActionsUtil(`${backendUrl}/api/vendors`, "GET");

    data.vendors = vendors?.data?.vendors?.length;
    return data;
  };

  const { data } = useQuery(["VENDOR_STAT"], () => getStats(), {
    onSuccess: (data) => {
      // queryClient.removeQueries("LOGIN");
    },
  });

  return (
    <div className="invoice-wrapper ">
      <section className={`stats mb-3 ${isSales ? "two-column" : ""} `}>
        <div className="stat">
          <div className="icon bg-light-blue">
            <GroupUsers color="#4169E1" />
          </div>
          <div className="details">
            <p>{data?.vendors}</p>
            <span>Registered Vendors</span>
          </div>
        </div>

        {!isSales ? (
          <>
            <div className="stat">
              <div className="icon bg-light-blue">
                <PageHeaderListIcon />
              </div>
              <div className="details">
                <p>
                  {currency(data?.totalDebit, {
                    symbol: "",
                  }).format()}
                </p>
                <span>Vendor Suppliers</span>
              </div>
            </div>

            <div className="stat">
              <div className="icon bg-light-blue">
                <CurrencyFillIcon />
              </div>
              <div className="details">
                <p>
                  {currency(data?.totalCredit, {
                    symbol: "",
                  }).format()}
                </p>
                <span>Vendor Payment</span>
              </div>
            </div>
          </>
        ) : null}

        <div className="stat">
          <div className="icon bg-light-blue">
            <LineChartIcon />
          </div>
          <div className="details">
            <p>
              {currency(data?.balance, {
                symbol: "",
              }).format()}
            </p>
            <span>Vendor Payable</span>
          </div>
        </div>
      </section>

      {Nav && <div className="nav-bar">{Nav}</div>}
      <Outlet />
    </div>
  );
}
