import { Modal, Button, Table } from "react-bootstrap";
import { useState, useEffect } from "react";
import "../../assets/scss/scoped/issueItem.scss";
import NewRequisitionModal from "../Requisition/NewRequisition";
import { format } from "date-fns";
import currency from "currency.js";

export default function ViewNonAvaliableItemsModal({
  show,
  mainData,
  onHide,
  productLog,
}) {
  const [tableData, setTableData] = useState({});
  const [showNewRequisitionModal, setShowNewRequisitionModal] = useState(false);

  useEffect(() => {
    if (productLog) {
      const newItems = mainData;
      newItems.prod_profiles = newItems?.items?.map((el) => ({
        ...el,
        item: el?.Bar_Code,
        title: el?.Item_Name,
        quantity: el?.estimateQty,
      }));
      setTableData(newItems);
    } else {
      setTableData(mainData);
    }
  }, [mainData, productLog]);

  return (
    <Modal
      onHide={() => onHide(false)}
      show={show}
      backdropClassName={`global-backdrop`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="my-style-modal"
      animation={false}
      enforceFocus={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {!productLog ? (
            <>
              <div>
                <h1>{`${tableData?.PlanName}`} Raw Materials</h1>
              </div>

              <p>
                {`${tableData?.availability ? "" : "Non"}`} Available raw
                materials.
              </p>
            </>
          ) : (
            <>
              <div>
                <h1>{`${tableData?.plan?.Plan_Name} Plan`}</h1>
              </div>

              <p>Production Details.</p>
            </>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="container">
            <div>
              <div>
                {productLog && (
                  <div className="row mb-3 fw-bold">
                    <div className="col-6">
                      <div className="mb-2">
                        <span>Bar Code:</span>
                        <span className="mx-3">{tableData?.Bar_Code}</span>
                      </div>

                      <div className="mb-2">
                        <span>Category: </span>
                        <span className="mx-3"> {tableData?.Cat_Name}</span>
                      </div>

                      <div className="mb-2">
                        <span>Quantity: </span>
                        <span className="mx-3"> {tableData?.Quantity}</span>
                      </div>

                      <div className="mb-2">
                        <span>Unit: </span>
                        <span className="mx-3">
                          {" "}
                          {tableData?.Product_Model}
                        </span>
                      </div>
                      <div className="mb-2">
                        <span>Date: </span>
                        <span className="mx-3">
                          {tableData?.Date_Log &&
                            format(
                              new Date(tableData?.Date_Log),
                              "dd MMM, yyyy"
                            )}
                        </span>
                      </div>
                    </div>
                    {/* secound */}
                    <div className="col-6">
                      <div className="mb-2">
                        <span>Item Name: </span>
                        <span className="mx-3"> {tableData?.Item_Name}</span>
                      </div>

                      <div className="mb-2">
                        <span>Product Name: </span>
                        <span className="mx-3"> {tableData?.Product_Name}</span>
                      </div>

                      <div className="mb-2">
                        <span>Plan Id: </span>
                        <span className="mx-3"> {tableData?.Plan_Id}</span>
                      </div>
                      <div className="mb-2">
                        <span>Storage: </span>
                        <span className="mx-3">
                          {tableData?.Requester?.toLowerCase() ===
                          "Sales".toLowerCase()
                            ? "Sales"
                            : tableData?.Issued_Name?.W_name}
                        </span>
                      </div>
                      <div className="mb-2">
                        <span>Time: </span>
                        <span className="mx-3"> {tableData?.IssueTime}</span>
                      </div>
                    </div>
                  </div>
                )}

                {/*  */}
                {/* Table */}

                <div className="mb-4">
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Bar Code</th>
                        <th>Name</th>
                        <th>Propose Quantity</th>
                        {productLog && (
                          <>
                            <th>Used Quantity</th>
                            <th>Unit Cost</th>
                            <th>Proposed Total</th>
                            <th>Used Total</th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {tableData?.prod_profiles?.length > 0 &&
                        tableData?.prod_profiles?.map((el, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{el.item}</td>
                            <td>{el.title}</td>
                            <td>{el.quantity}</td>
                            {productLog && (
                              <>
                                <td>{el.Quantity}</td>
                                <td>{el.UnitPrice}</td>
                                <td>{el.proCost}</td>
                                <td>{el.AmountDue}</td>
                              </>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>

                {/*  */}

                {productLog && (
                  <div className="d-flex justify-content-start mt-4">
                    <div className="gridChild gridChildBorderLeftBlue">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(tableData?.Quantity, {
                            symbol: "",
                          })
                            .multiply(tableData?.UnitCost)
                            .format()}
                        </p>
                        <p className="gridChld2">Total</p>
                      </div>
                    </div>
                  </div>
                )}

                {/*  */}

                {!productLog && (
                  <div className="d-flex justify-content-end mt-3">
                    <Button
                      onClick={() => onHide(false)}
                      variant="white"
                      className="border bg-white mx-4"
                    >
                      Cancel
                    </Button>

                    {!tableData?.availability && (
                      <button
                        className="btn btn-primary mx-3"
                        onClick={() => setShowNewRequisitionModal(true)}
                      >
                        Request for items
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>

            {showNewRequisitionModal && (
              <NewRequisitionModal
                modalTitle="Send for Approval"
                defaultFormValues={{
                  title: ``,
                  description: "",
                  type: "Material Request",
                }}
                itemsRequested={tableData?.nonAvailableItems}
                setShowNewRequisitionModal={setShowNewRequisitionModal}
                /* handleCreatedRequisition={handleCreatedRequisition} */
              />
            )}

            {/*  */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
