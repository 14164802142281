import { format } from "date-fns";
import openSocket from "socket.io-client";
import { useNavigate } from "react-router-dom";

import {
  CancelX,
  ChartPieSliceTitleIcon,
  Elipses,
  LightElipses,
  NotificationIcon,
  OpenVolume,
  CloseVolume,
} from "./Icons";
import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import queryString from "query-string";
// import { useQuery } from "react-query";
import { fetchActionsUtil, formatDate } from "../utils/helpers";
import { toast } from "react-toastify";
import { useCacheBuster } from "react-cache-buster";

const notiTypes = ({ Cust_ID }) => {
  let notificationType = {
    INVOICE: "Invoice",
    INVENTORY: "Inventory",
    SALES: "Sales",
    ORDERS: "Orders",
  };

  if (Cust_ID) {
    notificationType = {
      ORDERS: "Orders",
    };
  }

  const Headertypes = Object.values(notificationType);

  return { Headertypes, notificationType };
};

const socketType = {
  SENTTOSTAFF: "sendtostaff",
  SENTTOCUSTOMER: "sendtocustomer",
};

function playSound() {
  const audio = new Audio("/sound.wav");
  audio.play();
}

const NotificationDisplay = ({
  onHide,
  notificationData,
  openNotifications,
  setNotifications,
  Staff_ID,
  backendUrl,
  Cust_ID,
}) => {
  const [active, setactive] = useState("All");
  const { setUser, user: authUser } = useAuth();

  const { Headertypes } = notiTypes({ Cust_ID });

  const showActiveClass = (item) => {
    return active === item ? "active-type" : "";
  };

  const displayLenght = (item) => {
    let num = notificationData.filter(
      (el) => el.Type === item && el.Open === false
    ).length;
    const show = num > 0 ? true : false;
    num = num > 9 ? "9+" : num;
    return { num, show };
  };

  const changeSound = async () => {
    const res = await fetchActionsUtil(
      `${backendUrl}/api/users/Sound/${authUser?.Staff_ID}`,
      "POST"
    );
    if (res.message === "Successful") {
      Boolean(authUser?.sound) && playSound();
      setUser({
        ...authUser,
        sound: !Boolean(authUser?.sound),
      });
    }
  };

  return (
    <div
      className={`notification overflow-hidden ${
        openNotifications ? "visible" : "hidden"
      }`}
    >
      <div>
        <div className="d-flex justify-content-between border-bottom p-4">
          <div className="noti-text">Notification</div>
          <div className="d-flex align-items-baseline">
            {Staff_ID && (
              <div className="mx-3 p-cursor" onClick={changeSound}>
                {!Boolean(authUser?.sound) ? <OpenVolume /> : <CloseVolume />}
              </div>
            )}
            <div className="p-cursor" onClick={onHide}>
              <CancelX />
            </div>
          </div>
        </div>

        {/*  */}
        <div className="d-flex justify-content-between border-bottom">
          {Staff_ID && (
            <div
              className={`${showActiveClass("All")} noti-type`}
              onClick={() => setactive("All")}
            >
              All
            </div>
          )}
          {Headertypes?.map((el, i) => (
            <div
              key={i}
              className={`${showActiveClass(el)} noti-type`}
              onClick={() => setactive(el)}
            >
              <div className="notify-display">
                <div>{el}</div>
                {displayLenght(el).show && (
                  <div className={`notify-display-inside ${el}`}>
                    <p>{displayLenght(el).num}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
          {/*  */}
        </div>
      </div>
      <div className="notification-scroll">
        <Messages
          messageData={
            active === "All"
              ? notificationData
              : notificationData.filter((el) => el.Type === active)
          }
          setNotifications={setNotifications}
          backendUrl={backendUrl}
          Cust_ID={Cust_ID}
          onHide={onHide}
        />
      </div>
    </div>
  );
};

const Messages = ({
  messageData,
  setNotifications,
  backendUrl,
  Cust_ID,
  onHide,
}) => {
  const navigate = useNavigate();

  const { notificationType } = notiTypes({ Cust_ID });

  const navigateIt = async (item) => {
    const res = await fetchActionsUtil(
      `${backendUrl}/api/notifications/read/${item?.id}?Receiver=${
        item?.Receiver
      }`,
      "POST"
    );

    if (res.data?.length > 0) {
      setNotifications(res.data);
      if (item?.Type === notificationType.ORDERS) {
        if (item?.Title === "Customer Order") {
          navigate(`/approval-store?TransactionID=${item?.Details}`, {
            replace: true,
          });
        } else if (item?.Title === "Customer Quote") {
          navigate(`/approval-store-quotation?TransactionID=${item?.Details}`, {
            replace: true,
          });
        } else {
          navigate(`/store/account/orders?q=${item?.Details}`, {
            replace: true,
          });
        }
      } else if (item?.Type === notificationType.INVENTORY) {
        const details = JSON.parse(item?.Details);

        if (item?.Title === "Out of Stock") {
          navigate(
            `/inventory-for-sales/items-out-of-stock?${queryString.stringify({
              barcode: details.Bar_Code,
              itemName: details.Item_Name,
              page: 1,
              limit: 40,
              withProduct: true,
              withCategory: true,
              itemsOutOfStock: true,
            })}`,
            {
              replace: true,
            }
          );
        } else if (item?.Title === "Approve Stock Inbound") {
          navigate(`/${details?.page}?q=${details?.InvoiceNo}`, {
            replace: true,
          });
        } else {
          navigate(
            `/${details?.page}?TransactionID=${details?.TransactionID}&status=${
              details?.Status
            }`,
            {
              replace: true,
            }
          );
        }
      }
      onHide(false);
    }
  };

  return messageData?.map((el, i) => (
    <div className={`d-flex p-4  ${el?.Open ? "" : "read"}`} key={i}>
      <div className="icon mt-2 p-cursor">
        <ChartPieSliceTitleIcon />
      </div>
      <div className="grow-it p-cursor" onClick={() => navigateIt(el)}>
        <div className="d-flex justify-content-between pb-2">
          <div>
            <p className="fw-bold">{el?.Type}</p>
          </div>
          <div>
            <p className="text-muted mx-3">
              {formatDate(el?.Post_Time, "k:mm:ss")}
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-between inside-text">
          <div>
            <p>
              {el?.Title}
              {/* {el?.User} */}
            </p>
            <small className="text-muted">{el.Description}</small>
          </div>

          <p>{!el?.Open ? <Elipses /> : <LightElipses />}</p>
        </div>
      </div>
    </div>
  ));
};

export const Notification = ({ Cust_ID, backendUrl, Staff_ID, sound }) => {
  const { checkCacheStatus } = useCacheBuster();
  const [openNotifications, setOpenNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const displayLenght = () => {
    let num = notifications.filter((el) => el.Open === false).length;
    const show = num > 0 ? true : false;
    num = num > 9 ? "9+" : num;
    return { num, show };
  };
  const socketurl = backendUrl;

  const fetchStaffNotification = async () => {
    const res = await fetchActionsUtil(
      `${backendUrl}/api/notifications/user/${Staff_ID}`,
      "GET"
    );
    setNotifications(res?.data);
  };

  const fetchCustomerNotification = async () => {
    const res = await fetchActionsUtil(
      `${backendUrl}/api/notifications/user/${Cust_ID}`,
      "GET"
    );
    setNotifications(res?.data);
  };

  useEffect(() => {
    if (Cust_ID) {
      fetchCustomerNotification();
    } else if (Staff_ID) {
      fetchStaffNotification();
    }
  }, [backendUrl]);

  useEffect(() => {
    //   get notification from web socket
    const socketClient = openSocket(socketurl);

    socketClient.on("connect", () => {
      //  toast.info("Connected");
      console.log("Check version");
      checkCacheStatus();
    });

    if (Cust_ID) {
      socketClient.on(`${socketType.SENTTOCUSTOMER}-${Cust_ID}`, (data) => {
        setNotifications((prev) => [data.newObj, ...prev]);
      });
    } else if (Staff_ID) {
      socketClient.on(`${socketType.SENTTOSTAFF}-${Staff_ID}`, (data) => {
        Boolean(sound) && playSound();
        setNotifications((prev) => [data.newObj, ...prev]);
      });
    }

    return () => {
      if (Cust_ID) {
        socketClient.removeListener(`${socketType.SENTTOSTAFF}-${Cust_ID}`);
      } else if (Staff_ID) {
        socketClient.removeListener(`${socketType.SENTTOSTAFF}-${Staff_ID}`);
      }

      socketClient.removeListener("connect");
    };
  }, []);

  return (
    <>
      <div
        className="p-cursor"
        onClick={() => {
          setOpenNotifications(true);
        }}
      >
        <div className="notify-display">
          <NotificationIcon />
          {displayLenght().show && (
            <div className="notify-display-inside">
              <p>{displayLenght().num}</p>
            </div>
          )}
        </div>
      </div>

      <NotificationDisplay
        onHide={() => setOpenNotifications(false)}
        notificationData={notifications}
        openNotifications={openNotifications}
        setNotifications={setNotifications}
        Staff_ID={Staff_ID}
        backendUrl={backendUrl}
        Cust_ID={Cust_ID}
      />
    </>
  );
};
