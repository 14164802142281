import { Modal, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { services } from "../config";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import Select from "react-select";
import { useEffect } from "react";
import { useBackendUrl } from "../utils/hooks";
import CachedIcon from "mdi-react/CachedIcon";
import ConfirmDialog from "./ConfirmDialogue";
import {
  CashSelectIcon,
  ChequeSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  DropdownCloseIcon,
} from "./Icons";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";

function PaymentAccount({
  proceed,
  show,
  previouslySelectedAccount,
  data,
  payFromLabel = "Reimburse From",
  payByLabel = "Reimburse By",
  hasRemark = false,
}) {
  const paymentMethod = [
    {
      icon: <CreditSelectIcon />,
      label: "Imprest",
      value: "Imprest",
    },
    {
      icon: <CashSelectIcon />,
      label: "Cash",
      value: "Cash",
    },

    {
      icon: <ChequeSelectIcon />,
      label: "Cheque",
      value: "Cheque",
    },

    {
      icon: <DirectTransferSelectIcon />,
      label: "Direct Bank Transfer",
      value: "Direct Bank Transfer",
    },
    {
      icon: <CreditSelectIcon />,
      label: "Credit/Debit Card (POS)",
      value: "Credit/Debit Card",
    },
  ];

  const cashOptions = [
    {
      label: "Cash On Hand",
      value: "Cash on hand",
    },
  ];

  const initialValues = {
    reimburseBy: previouslySelectedAccount?.By,
    reimburseFrom: previouslySelectedAccount?.From,
    amount: previouslySelectedAccount?.amount,
    remark: "",
  };

  const schema = yup.object().shape({
    reimburseBy: yup.string().required(),
    reimburseFrom: yup.string().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      proceed(values);
    },
  });

  const canClose = async () => {
    proceed(false);
  };

  const handlePaymentMethodReimburse = (value) => {
    formik.setFieldValue("reimburseBy", value);
    formik.setFieldValue("reimburseFrom", "");
  };

  const resolveFromOptions = (PaymentType) => {
    //  console.log(PaymentType, data.banks);
    return PaymentType === "Imprest"
      ? data.imprest
      : ["Cheque", "Direct Bank Transfer", "Credit/Debit Card"].includes(
          PaymentType
        )
      ? data.banks
      : cashOptions;
  };

  return (
    <Modal
      show={show}
      onHide={() => canClose()}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6">Select Account</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>{payByLabel}</Form.Label>
            <Select
              classNamePrefix="form-select"
              menuPlacement="top"
              placeholder="Choose method"
              isSearchable={false}
              value={paymentMethod.find(
                (el) => el.value === formik.values.reimburseBy
              )}
              options={paymentMethod}
              onChange={({ value }) => handlePaymentMethodReimburse(value)}
              getOptionLabel={(el) => (
                <div className="label-with-icon d-flex gap-2 align-items-center">
                  <span>{el.icon}</span>{" "}
                  <span className="fw-5">{el.label}</span>
                </div>
              )}
              //  menuIsOpen={true}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="fw-bold ">{payFromLabel}</Form.Label>
            <Select
              classNamePrefix="form-select"
              value={resolveFromOptions(formik.values.reimburseBy).find(
                (el) => el.value === formik.values.reimburseFrom
              )}
              options={resolveFromOptions(formik.values.reimburseBy)}
              onChange={({ value }) =>
                formik.setFieldValue("reimburseFrom", value)
              }
              key={formik.values?.reimburseBy}
            />
            {formik.touched.reimburseFrom && !!formik.errors.reimburseFrom ? (
              <span className="custom-invalid-feedback">
                {formik.errors.reimburseFrom}
              </span>
            ) : null}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="fw-bold ">Amount</Form.Label>
            <CurrencyCustomInput
              name="amount"
              value={formik.values.amount}
              onValueChange={(value, name) => {
                formik.setFieldValue(name, value);
              }}
            />
            {formik.touched.amount && !!formik.errors.reimburseFrom ? (
              <span className="custom-invalid-feedback">
                {formik.errors.reimburseFrom}
              </span>
            ) : null}
          </Form.Group>

          {hasRemark && (
            <Form.Group className="mb-3">
              <Form.Label className="fw-bold">Remark</Form.Label>
              <Form.Control
                placeholder="Enter Remark"
                name="remark"
                value={formik.values.remark}
                onChange={formik.handleChange}
                as={"textarea"}
                rows={2}
              />
            </Form.Group>
          )}

          <Button variant="primary" className="w-100 p-2" type="submit">
            {"Continue"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default function PaymentAccountDialog(props) {
  return createConfirmation(confirmable(PaymentAccount))({ ...props });
}
