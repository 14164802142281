import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { CSVLink } from "react-csv";
import {
  DeleteIcon,
  DropdownCheckIcon,
  DropdownCloseIcon,
  EditIcon,
  ExportIcon,
  FilterCollapseIcon,
  PDFIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import { FilterTwoIcon, ExcelIcon } from "../Icons";
import useDebounce, {
  useIsAdmin,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import queryString from "query-string";
import { useMutation, useQuery } from "react-query";
import currency from "currency.js";
import { format, parse, setMonth } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  formatDate,
  paginationOptions,
  reactSelectTheme,
  scrollToTop,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import TableComponent from "../TableComponent";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import { isEmpty } from "lodash";
import NoTableItem from "../utils/NoTableItem";

import CreateShippingCostModal from "../CreateShippingCostModal";
import { fetchActionsUtil } from "../../utils/helpers";

import ConfirmDialog from "../ConfirmDialogue";
import { toast } from "react-toastify";
import Select from "react-select";
import LoginDialog from "../LoginDialog";
import { IsPrivileged } from "../DisplayChildElement";
import { Link, useNavigate } from "react-router-dom";
import ConvertQuantity from "../utils/ConvertQuantity";
import CurrencyInput from "react-currency-input-field";
import EyeOffOutlineIcon from "mdi-react/EyeOffOutlineIcon";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";

export default function SavedProcurementPlans() {
  const { backendUrl } = useAuth();
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);

  useScrollTop();
  const [selected, setSelected] = useState({});
  const navigate = useNavigate();

  const initialFilterParams = {
    page: 1,
    limit: 40,
    Name: "",
  };
  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...queryParams,
    ...initialFilterParams,
  });

  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams((q) => ({ ...q, ...debouncedFilterParams }));
  }, [debouncedFilterParams, setQueryParams]);

  const fetchItems = async (queryParams) => {
    let { data } = await fetchActionsUtil(
      `${backendUrl}/api/demandplan?&${queryString.stringify(queryParams)}`
    );
    //console.log(data);

    data.staffs = data?.staffs?.map((el) => ({
      value: el.UserName,
      label: el.UserName,
    }));
    return data;
  };

  const {
    data = { count: 0, plans: [], staffs: [] },
    refetch,
    isFetching,
  } = useQuery(["demandplan", queryParams], () => fetchItems(queryParams), {});

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const deleteApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/stock/delete`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteplanMutation = useMutation((payload) => deleteApi(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      refetch();
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });
  const handleDelete = async (plan) => {
    if (await LoginDialog()) {
      if (
        await ConfirmDialog({
          title: "Delete",
          description: "Are you sure you want to delete ?",
        })
      ) {
        deleteplanMutation.mutate({ ...plan });
      }
    }
  };

  const actionApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/stock/action`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const actionMutation = useMutation((payload) => actionApi(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      refetch();
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });
  const handleAction = async ({ action, id }) => {
    if (
      await ConfirmDialog({
        title: action === "Approved" ? "Approve" : "Reject",
        description: `Are you sure you want to ${
          action === "Approved" ? "Approve" : "Reject"
        }`,
      })
    ) {
      actionMutation.mutate({ action, id });
    }
  };

  // The main table data
  const tableBodyData = (el, index) => {
    return (
      <>
        <td>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="bg-white border-0"
              bsPrefix="print more"
            >
              <DotsHorizontalIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
              className="dropdown-with-icons"
            >
              <Dropdown.Item
                as="div"
                className="p-cursor"
                onClick={() => {
                  //  console.log(el);
                  navigate(
                    `/inventory-for-sales/items-forcast-recorder/edit/${el.id}`,
                    {
                      state: { plan: el },
                    }
                  );
                }}
              >
                <EyeOutlineIcon />
                View / Edit
              </Dropdown.Item>

              <Dropdown.Item
                as="div"
                className="p-cursor"
                onClick={() => handleDelete(el)}
              >
                <DeleteIcon />
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
        <td>{data?.startIndex + index + 1}</td>
        <td>{el?.UserName || "..."}</td>
        <td>
          {el?.month ? format(setMonth(new Date(), el?.month), "MMMM") : "..."}
        </td>
        <td>{el?.year || "..."}</td>
        <td>{el?.status || "..."}</td>
        <td>
          {el?.Date_Log
            ? formatDate(el?.Date_Log, "dd MMM, yyyy HH:mm:ss")
            : ""}
        </td>
      </>
    );
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th />
          <th>S/N</th>
          <th>Prepared By</th>
          <th>Month</th>
          <th>Year</th>
          <th>Status</th>
          <th>Date</th>
        </tr>
      </thead>
    );
  };

  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/customers/get-shopping-cost?${queryString.stringify(
        rest
      )}`,
      "GET"
    );

    const company = exData?.company?.CompName;

    // console.log(exData.data);
    exData = exData?.SavedProcurementPlans.map((row) => ({
      ...row,
      Date: format(new Date(row.Date), "dd-MMM-yyyy"),
    }));

    exData = exData.map((d, i) => [
      data?.startIndex + i + 1,
      d.state,
      d.LGA,
      currency(d?.cost, {
        symbol: "",
      }).format(),
      d.Date,
    ]);
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [company],
      ["Shipping Cost Report"],
      [date],
      [""],
      ["S/N", "State", "City", "Cost", "Date"],
      ...exData,
      [""],
    ];
    // console.log(exData);
    setExcelData(exData);
  };

  return (
    <section className="cashbook-wrapped">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button
                  variant="white"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                    <Form.Group className="mb-2-5">
                      <Form.Label>Staff</Form.Label>
                      <Select
                        classNamePrefix="form-select"
                        menuPosition="fixed"
                        menuPlacement="auto"
                        name="UserName"
                        placeholder="All"
                        theme={reactSelectTheme}
                        isSearchable={true}
                        key={data?.UserName}
                        onChange={(selected) => {
                          setFilterParams({
                            ...filterParams,
                            UserName: selected.value,
                          });
                        }}
                        value={
                          filterParams?.UserName && data?.staffs
                            ? data?.staffs.find(
                                (el) => el.value === filterParams?.UserName
                              )
                            : {
                                value: "",
                                label: "All",
                              }
                        }
                        options={data?.staffs}
                      />
                    </Form.Group>

                    <hr className="mt-3 mb-4" />
                    <Form.Group className="">
                      <Form.Label>Date Range</Form.Label>
                      <div className="position-relative">
                        <RsDateRangePicker
                          placement="topStart"
                          value={
                            filterParams.startDate && filterParams.endDate
                              ? [
                                  parse(
                                    filterParams.startDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                  parse(
                                    filterParams.endDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                ]
                              : []
                          }
                          onClean={() => clearDateRange()}
                          onOk={(date) => filterByDateRange(date)}
                        />
                      </div>
                    </Form.Group>
                  </div>
                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="content-body">
              <header>
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Plans
                  <button
                    onClick={() => refetch()}
                    className="btn text-primary"
                    title="Refresh"
                  >
                    <CachedIcon />
                  </button>
                </h1>
                <div className="actions mr-5">
                  <Link
                    className="btn btn-primary"
                    to={
                      "/inventory-for-sales/items-forcast-recorder/items-forcast-recorder"
                    }
                  >
                    + Create Plan
                  </Link>
                </div>
              </header>

              <div className="px-md-4">
                <TableComponent
                  responsive
                  borderless
                  striped
                  tableHeadsFunction={tableHead}
                  mainDataArray={data?.plans}
                  tableDataRowFunction={tableBodyData}
                  className="product-table text-nowrap"
                />
                {isEmpty(data.plans) && !isFetching ? (
                  <div className="d-flex justify-content-center text-center w-100 my-4">
                    <NoTableItem queryParams={queryParams} />
                  </div>
                ) : null}
              </div>

              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>
                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                    <option value="50">50 rows</option>
                    <option value="100">100 rows</option>
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={Math.ceil(data.count / queryParams.limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    scrollToTop();
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={queryParams.page - 1}
                />
              </div>
            </div>
          </div>
        </div>

        <ModalLoader show={isfetchingExcel} />
      </main>
    </section>
  );
}
