import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./../assets/scss/requisition/index.scss";

import { confirmable, createConfirmation } from "react-confirm";
import {
  ApproveBlueModalIcon,
  ApproveCheckIcon,
  RejectModalIcon,
} from "./Icons";
import { Table } from "react-bootstrap";
import { map, isEmpty } from "lodash";
import { isNumber } from "lodash";
import currency from "currency.js";
import { Fragment } from "react";

function Dialog({
  proceed,
  type = "",
  show,
  title = "",
  description = "",
  items = [],
}) {
  return (
    <Modal
      show={show}
      onHide={() => proceed(false)}
      dialogClassName="requisition-details-modal"
      centered={true}
      animation={false}
      enforceFocus={false}
      size="lg"
    >
      <Modal.Header />
      <Modal.Body className="approve-action">
        <div className="text-center">
          <div className="mb-4">
            {type === "info" ? (
              <ApproveBlueModalIcon />
            ) : type === "danger" ? (
              <RejectModalIcon />
            ) : (
              <ApproveBlueModalIcon />
            )}{" "}
          </div>

          <h2>{title}</h2>
          <p
            className="fs-6"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        <Table striped responsive className="mt-3">
          <thead className="bg-lighter">
            <tr className="text-nowrap">
              <th>Item</th>
              <th>Qty Sold</th>
              <th>Batch</th>
              <th>
                Qty Sold <br /> From Batch
              </th>
              <th>
                Qty Now <br /> In Batch
              </th>
              <th>Re-calculate </th>
            </tr>
          </thead>
          <tbody>
            {items.map((el, index) => (
              <Fragment key={index}>
                <tr>
                  <td>
                    {el.Bar_Code} - {el.Item_Name}
                  </td>
                  <td>
                    {currency(el.quantitySold, {
                      symbol: "",
                      precision: 0.5,
                    }).format()}
                  </td>
                  {el.batchesUsed
                    .filter((el, i) => i === 0)
                    .map((el) => (
                      <Fragment key={el.Bar_Code}>
                        <td>
                          {el.Bar_Code ? el.Bar_Code.split("-").pop() : "..."}
                        </td>

                        <td>
                          {currency(el.quantityUsed, {
                            symbol: "",
                            precision: 0.5,
                          }).format()}
                        </td>

                        <td>
                          {currency(el.quantityNow, {
                            symbol: "",
                            precision: 0.5,
                          }).format()}
                        </td>

                        <td>{JSON.stringify(el.willRecalculateSaleData)}</td>
                      </Fragment>
                    ))}
                </tr>
                {el.batchesUsed
                  .filter((el, i) => i !== 0)
                  .map((el) => (
                    <tr key={el.Bar_Code}>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>
                        {el.Bar_Code ? el.Bar_Code.split("-").pop() : "..."}
                      </td>

                      <td>
                        {currency(el.quantityUsed, {
                          symbol: "",
                          precision: 0.5,
                        }).format()}
                      </td>

                      <td>
                        {currency(el.quantityNow, {
                          symbol: "",
                          precision: 0.5,
                        }).format()}
                      </td>

                      <td>{JSON.stringify(el.willRecalculateSaleData)}</td>
                    </tr>
                  ))}

                <tr>&nbsp;</tr>
              </Fragment>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <span />
        <div className="proceed-actions justify-content-between">
          <Button
            onClick={() => proceed(false)}
            variant="white"
            className="border bg-white"
          >
            Close
          </Button>
          <Button
            onClick={() => proceed(true)}
            variant="primary"
            autoFocus={true}
          >
            Continue
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default function RecalculateProfitDialog(props) {
  return createConfirmation(confirmable(Dialog))(props);
}
