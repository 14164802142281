import { cloneDeep, isEmpty, isNumber } from "lodash";
import { Fragment, useState } from "react";
import { Button, Dropdown, Form, Table } from "react-bootstrap";
import Select from "react-select";
import {
  convertMomentDate,
  resolveApprovalBadgeBg,
  scrollToElement,
} from "../../utils/helpers";
import {
  AddCircleIcon,
  CreateInvoiceIcon,
  CreditMemoSelectIcon,
  CreditSelectIcon,
  DeleteIcon,
  DropdownCloseIcon,
  // MoneyDollarIcon,
  NoSelectedItemIcon,
} from "../Icons";
import Datetime from "react-datetime";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import { Popover } from "react-tiny-popover";
import { useFormik } from "formik";
import moment from "moment/moment";
import DatePickerCustomInput from "../utils/DatePickerCustomInput";
import useDebounce, { useEffectOnce } from "../../utils/hooks";
import currency from "currency.js";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import ModalLoader from "../utils/ModalLoader";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import SelectBankDialog from "../SelectBankDialog";
// import { queryActions } from "../../utils/reactQueryActions";
import { last } from "lodash";
import ConfirmDialog from "../ConfirmDialogue";
import VendorSelectModal from "./VendorPostSelector";
import { IsPrivileged } from "../DisplayChildElement";
import { useStoreState } from "easy-peasy";
import NewRequisitionModal from "../Requisition/NewRequisition";
import PageHeader from "../PageHeader";
import { useParams } from "react-router-dom";
import { ApproveAndCloseDialog, RejectDialog } from "../Requisition/Dialog";
import ApproveAndSendModal from "../Requisition/ApproveAndSendModal";
import { requisitionActions } from "../../utils/reactQueryActions";
import { FilesAttachments } from "../../utils/Attachments";
import { isEqual } from "lodash";
import ApprovalBadge from "../utils/ApprovalBadge";
import NumberCustomInput from "../utils/NumberCustomInput";
import { addDays } from "date-fns";

function VendorRow({
  index,
  vendor,
  editTable,
  remove,
  vendors = [],
  addNewRow,
  backendUrl,
  getVendorBalanceAndCredit,
}) {
  const [datePopoverOpened, setDatePopoverOpened] = useState(false);
  const generalSettings = useStoreState((state) => state.generalSettings);
  const [dueDatePopoverOpened, setDueDatePopoverOpened] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const initialValues = {
    Post_Date: vendor.Post_Date || new Date(),
    DueDate: vendor.DueDate || new Date(),
    newInstallment: vendor?.newInstallment || 0,
    Remark: vendor?.Remark || "",
    PaymentType: vendor?.PaymentType || "Cash",
    // duePaymentTransactionID: "",
    chequeNumber: vendor?.chequeNumber || "",
    BankName: vendor?.BankName || "",
    Vendor_ID: vendor?.Vendor_ID || "",
    InvoiceCat: vendor?.InvoiceCat || "None",
    attachments: vendor?.attachments,
    InvoiceNo: vendor?.InvoiceNo || "",
    vendorDueIn: isNumber(vendor?.vendorDueIn)
      ? vendor?.vendorDueIn
      : generalSettings.vendorDueIn,
  };

  const formik = useFormik({
    initialValues,
  });

  const formValues = useDebounce(formik.values, 500);

  const setUp = async (Vendor_ID) => {
    const vendor = vendors.find((el) => el.Vendor_ID === Vendor_ID);
    if (isEmpty(vendor?.balance)) {
      getVendorBalanceAndCredit(Vendor_ID);
    }
    formik.setFieldValue("Vendor_ID", Vendor_ID);
  };

  useEffectOnce(() => {
    if (vendor?.Vendor_ID) setUp(vendor.Vendor_ID);
  });

  useEffect(() => {
    // formik values is set empty to undefined
    for (let k in initialValues) {
      if (!formValues.hasOwnProperty(k)) {
        const key = String(k);
        formValues[key] = "";
      }
    }
    editTable({
      index,
      formValues: {
        ...(formValues?.Vendor_ID
          ? vendors.find((el) => el.Vendor_ID === formValues.Vendor_ID)
          : {}),
        ...formValues,
      },
    });
  }, [formValues]);

  const showSelectBankDialog = async (props = {}) => {
    const bank = await SelectBankDialog({
      ...props,
      selectedBank: formik.values.BankName,
    });
    if (bank) {
      formik.setFieldValue("BankName", bank.bank);
      if (props.hasChequeNumber) {
        formik.setFieldValue("chequeNumber", bank.chequeNumber);
      }
    }
  };

  useEffect(() => {
    if (
      ["Credit/Debit Card", "Direct Bank Transfer"].includes(
        formik.values.PaymentType
      )
    ) {
      showSelectBankDialog();
    } else if (formik.values.PaymentType === "Cheque") {
      showSelectBankDialog({
        hasChequeNumber: true,
      });
    }
  }, [formik.values.PaymentType]);

  const handleVendorChange = (value) => {
    formik.setFieldValue("Vendor_ID", value);
    if (value) {
      console.log("value =>", value);
      setUp(value);
      addNewRow(index);
    }
  };

  const sendDeleteImage = async (payload, index) => {
    try {
      setIsLoading(true);
      let response = await fetch(
        `${backendUrl}/api/vendors/delete-pending-invoice-attachment`,
        {
          method: "POST",
          credentials: "include",
          body: JSON.stringify(payload),
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
        }
      );
      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }
      const res = await response.json();
      formik.setFieldValue(
        "attachments",
        formik.values.attachments.filter((el, i) => i !== index)
      );

      return res;
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteImage = async (index) => {
    const file = formik.values.attachments.find((el, i) => i === index);
    if (file?.fileId) {
      if (
        await ConfirmDialog({
          title: "Delete",
          description: `Do you want to delete this file - ${file.name}`,
        })
      ) {
        const res = await sendDeleteImage(
          { file, TransactionID: vendor?.TransactionID },
          index
        );

        toast.success(res?.message);
      }
    } else {
      formik.setFieldValue(
        "attachments",
        formik.values.attachments.filter((el, i) => i !== index)
      );
    }
  };

  useEffect(() => {
    formik.setFieldValue(
      "DueDate",
      addDays(
        new Date(formik.values.Post_Date) || new Date(),
        formik.values.vendorDueIn
      )
    );
  }, [formik.values.vendorDueIn, formik.values.Post_Date]);

  return (
    <tr>
      <td>
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            className="bg-white border-0"
            bsPrefix="print more"
            type="button"
          >
            <DotsVerticalIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu
            popperConfig={{
              strategy: "fixed",
            }}
            renderOnMount
            className="dropdown-with-icons"
          >
            <Dropdown.Item
              as="button"
              type="button"
              onClick={() => remove(index)}
            >
              <DeleteIcon />
              Remove Vendor
            </Dropdown.Item>
          </Dropdown.Menu>{" "}
        </Dropdown>
      </td>

      <td>{index + 1}</td>

      <td>
        <Select
          classNamePrefix={"form-select"}
          className="vendor-select-dropdown"
          data={vendors}
          menuPlacement="bottom"
          menuPosition="fixed"
          isSearchable={true}
          isClearable={true}
          value={vendors.find((el) => el.value === formik.values.Vendor_ID)}
          options={vendors}
          onChange={(selection) =>
            handleVendorChange(selection?.value ? selection?.value : "")
          }
          id={`newInstallment-vendor-${index}`}
        />{" "}
      </td>

      <td>
        <Form.Control
          placeholder="Enter Invoice No"
          name="InvoiceNo"
          value={formik.values.InvoiceNo}
          onChange={formik.handleChange}
          style={{ width: "15rem" }}
        />
      </td>
      <td>
        <Form.Control
          placeholder="Enter Remark"
          name="Remark"
          value={formik.values.Remark}
          onChange={formik.handleChange}
          style={{ width: "15rem" }}
        />
      </td>
      <td>
        <CurrencyCustomInput
          id={`newInstallment-${vendor.Vendor_ID}`}
          name="newInstallment"
          placeholder="0.00"
          value={formik.values.newInstallment}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value);
          }}
        />
      </td>

      <td>
        <FilesAttachments
          files={formik.values.attachments}
          setFiles={(files) => formik.setFieldValue("attachments", files)}
          url={`${backendUrl}/api/vendors/view-invoice`}
          deleteImage={deleteImage}
          colWidth="12"
          isLoading={isLoading}
        />
      </td>

      <td>
        <NumberCustomInput
          placeholder="0"
          name="vendorDueIn"
          value={formik.values.vendorDueIn}
          onValueChange={(value, name) => {
            formik.setFieldValue(name, value, true);
          }}
        />
      </td>

      <td>
        <Popover
          reposition={false}
          isOpen={dueDatePopoverOpened}
          onClickOutside={() => setDueDatePopoverOpened(false)}
          align="end"
          padding={10}
          content={() => (
            <Datetime
              dateFormat="MM DD, YYYY"
              className="date-picker-2"
              timeFormat={false}
              closeOnSelect={true}
              closeOnClickOutside={true}
              name="DueDate"
              inputProps={{
                className: `date-input form-control ${
                  formik.touched.DueDate && !!formik.errors.DueDate_Date
                    ? "is-invalid"
                    : ""
                }`,
                placeholder: "Select date",
                readOnly: true,
              }}
              input={false}
              value={formik.values.DueDate}
              onChange={(date) => {
                formik.setFieldValue("DueDate", date, true);
                setDueDatePopoverOpened(false);
              }}
              onBlur={() => formik.setFieldTouched("DueDate", true)}
            />
          )}
        >
          <DatePickerCustomInput
            onClick={() => setDueDatePopoverOpened(!dueDatePopoverOpened)}
            value={convertMomentDate(formik.values.DueDate)}
          />
        </Popover>
      </td>

      <td>
        {currency(vendor.balance, {
          symbol: "",
        }).format()}
      </td>

      <td>
        <Popover
          reposition={false}
          isOpen={datePopoverOpened}
          onClickOutside={() => setDatePopoverOpened(false)}
          align="end"
          padding={10}
          content={() => (
            <Datetime
              dateFormat="MM DD, YYYY"
              className="date-picker-2"
              timeFormat={false}
              closeOnSelect={true}
              closeOnClickOutside={true}
              name="Post_Date"
              inputProps={{
                className: `date-input form-control ${
                  formik.touched.Post_Date && !!formik.errors.Post_Date
                    ? "is-invalid"
                    : ""
                }`,
                placeholder: "Select date",
                readOnly: true,
              }}
              input={false}
              value={formik.values.Post_Date}
              onChange={(date) => {
                formik.setFieldValue("Post_Date", date, true);
                setDatePopoverOpened(false);
              }}
              onBlur={() => formik.setFieldTouched("Post_Date", true)}
            />
          )}
        >
          <DatePickerCustomInput
            onClick={() => setDatePopoverOpened(!datePopoverOpened)}
            value={convertMomentDate(formik.values.Post_Date)}
          />
        </Popover>
      </td>
      <td>{vendor.TransactionID}</td>
    </tr>
  );
}

export default function ApproveVendorPostedInvoice() {
  let { requestid } = useParams();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const { backendUrl, token, user: authUser } = useAuth();
  const [showVendorSelectorModal, setShowVendorSelectorModal] = useState(false);
  const generalSettings = useStoreState((state) => state?.generalSettings);
  const [tableData, setTableData] = useState([
    {
      Vendor_ID: "",
      TransactionID: `STN${Date.now()}1`,
      attachments: [],
    },
  ]);
  const [savedTableData, setSavedTableData] = useState();

  const [showApproveAndSendModal, setShowApproveAndSendModal] = useState(false);

  const setSelectedVendor = (vendor) => {
    const lastitem = last(tableData);
    vendor.TransactionID = `STN${Date.now()}`;
    console.log("vendor => ", vendor);
    if (lastitem?.Vendor_ID === "") {
      setTableData([
        ...tableData.filter((el, index) => index !== tableData.length - 1),
        vendor,
        { Vendor_ID: "", TransactionID: `STN${Date.now()}2`, attachments: [] },
      ]);
    } else {
      setTableData([
        ...tableData,
        vendor,
        { Vendor_ID: "", TransactionID: `STN${Date.now()}1`, attachments: [] },
      ]);
    }
  };

  const editTable = ({ index, formValues }) => {
    tableData[index] = {
      ...tableData[index],
      ...formValues,
    };
    setTableData([...tableData]);
  };

  const addNewRow = (index) => {
    if (index === tableData.length - 1) {
      setTableData([
        ...tableData,
        { Vendor_ID: "", TransactionID: `STN${Date.now()}1` },
      ]);
    }
  };

  const remove = (index) => {
    const newTableData = tableData.filter((el, i) => index !== i);
    setTableData([...newTableData]);
  };

  const updateVendorsLedger = async (payload) => {
    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    // pick up files
    for (let vendor of payload.vendors) {
      if (!isEmpty(vendor.attachments)) {
        const alreadyUploadedFiles = [];
        for (let file of vendor.attachments) {
          if (!file?.fileId) {
            formData.append(`vendorInvoiceFile_${vendor.TransactionID}`, file);
          } else {
            alreadyUploadedFiles.push(file);
          }
        }

        formData.append(
          `alreadyUploadedFiles_${vendor.TransactionID}`,
          JSON.stringify(alreadyUploadedFiles)
        );
      }
    }

    let response = await fetch(
      `${backendUrl}/api/vendors/update-pending-invoice`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
        body: formData,
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const saveVendorInvoiceMutation = useMutation(
    (payload) => updateVendorsLedger(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        setSavedTableData(tableData);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const post = async () => {
    const table =
      tableData.length > 1
        ? tableData.filter((el, index) => index !== tableData.length - 1)
        : tableData;

    const foundEmptyVendorIndex = table.findIndex((el) => el.Vendor_ID === "");
    if (foundEmptyVendorIndex !== -1) {
      // console.log(foundEmptyVendorIndex);
      scrollToElement(`#newInstallment-vendor-${foundEmptyVendorIndex}`);
      return toast.error(
        `Select a Vendor in row ${foundEmptyVendorIndex + 1} or delete the row`
      );
    }

    const foundEmptyInstallment = table.find(
      (el) => Number(el.newInstallment) <= 0
    );

    const foundIndexInstallment = table.findIndex(
      (el) => Number(el.newInstallment) <= 0
    );

    if (foundEmptyInstallment) {
      scrollToElement(`#newInstallment-${foundEmptyInstallment.Vendor_ID}`);
      return toast.error(
        `Empty or Invalid amount in row ${foundIndexInstallment + 1}`
      );
    }

    saveVendorInvoiceMutation.mutate({
      vendors: tableData.filter((el) => el.Vendor_ID && el.newInstallment),
    });
  };

  const getVendor = async () => {
    let response = await fetch(
      `${backendUrl}/api/vendors?withCredit=${true}&withPaymentDue=${true}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    data.vendors = data.vendors.map((el) => ({
      ...el,
      label: el.CompanyName,
      value: el.Vendor_ID,
    }));
    return data;
  };

  const { data = { vendors: [] } } = useQuery(
    ["GET ALL VENDORS"],
    () => getVendor(),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const getVendorBalanceAndCredit = async (Vendor_ID) => {
    // return new Promise(async (resolve, reject) => {
    try {
      setIsLoading(true);
      let response = await fetch(
        `${backendUrl}/api/vendors/balance-and-credit/${Vendor_ID}`,
        {
          method: "GET",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          credentials: "include",
        }
      );

      if (!response.ok) {
        response = await response.json();
        throw new Error();
      } else {
        const {
          data: { balance, duePayments, invoiceCats },
        } = await response.json();
        // resolve({ balance, duePayments });
        queryClient.setQueryData(["GET ALL VENDORS"], (data) => {
          data.vendors = data.vendors.map((el) =>
            el.Vendor_ID === Vendor_ID
              ? { ...el, balance, duePayments, invoiceCats }
              : { ...el }
          );
          return data;
        });

        setTableData((tableData) =>
          tableData.map((el) =>
            el.Vendor_ID === Vendor_ID
              ? { ...el, balance, duePayments, invoiceCats }
              : { ...el }
          )
        );
      }
    } catch (err) {
      console.log(err);

      // reject();
    } finally {
      setIsLoading(false);
    }
    //  });
  };

  const getRequisitionDetails = async (requestid) => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/requisition/${requestid}`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    const cleanedUpDataForTable = data.invoices.map((el) => ({
      ...el,
      pendingInvoiceID: el.id,
      attachments: JSON.parse(el.attachments),
    }));

    setTableData(cleanedUpDataForTable);
    setSavedTableData(cleanedUpDataForTable);

    return data;
  };

  // fetch pending invoices
  const {
    error,
    data: { requisition, company } = { requisition: null, company: null },
    isFetching,
    refetch: refetchRequisition,
  } = useQuery(
    [requisitionActions.GET_REQUISITION_DETAILS, requestid],
    () => getRequisitionDetails(requestid),
    {
      onError: (err) => {
        console.log(err);
      },
      onSuccess: (data) => {},
    }
  );

  const requiredDataForCompare = (tableData) =>
    cloneDeep(tableData).map((vendor) => ({
      Post_Date: vendor.Post_Date || new Date(),
      DueDate: vendor.DueDate || new Date(),
      newInstallment: vendor?.newInstallment || 0,
      Remark: vendor?.Remark || "",
      PaymentType: vendor?.PaymentType || "Cash",
      chequeNumber: vendor?.chequeNumber || "",
      BankName: vendor?.BankName || "",
      Vendor_ID: vendor?.Vendor_ID || "",
      InvoiceCat: vendor?.InvoiceCat || "None",
      attachments: vendor?.attachments,
      InvoiceNo: vendor?.InvoiceNo || "",
    }));

  const reject = async () => {
    if (
      !isEqual(
        requiredDataForCompare(tableData),
        requiredDataForCompare(savedTableData)
      )
    ) {
      return toast.info("Please save changes");
    }

    if (
      await RejectDialog({
        authUser,
        requisitionTitle: requisition.title,
        requisition: last(requisition?.requisitiontracks),
        previousRequisitionTrackId: last(requisition?.requisitiontracks).ID,
      })
    ) {
      refetchRequisition();
    }
  };

  const approveAndClose = async () => {
    if (
      !isEqual(
        requiredDataForCompare(tableData),
        requiredDataForCompare(savedTableData)
      )
    ) {
      return toast.info("Please save changes");
    }

    if (
      await ApproveAndCloseDialog({
        authUser,
        requisitionTitle: requisition.title,
        requisition: last(requisition?.requisitiontracks),
        previousRequisitionTrackId: last(requisition?.requisitiontracks).ID,
      })
    ) {
      refetchRequisition();
    }
  };

  const canPerformAction = (requisitionData) => {
    if (!requisition) return false;
    const pendingRequisition = last(requisitionData?.requisitiontracks);

    // By Department
    if (
      pendingRequisition?.sentToDepartment === authUser.Department &&
      pendingRequisition?.sentby !== authUser.Staff_ID &&
      pendingRequisition?.status === "Pending"
    ) {
      return true;
    }

    return (
      pendingRequisition &&
      pendingRequisition?.status === "Pending" &&
      pendingRequisition?.receivedby === authUser.Staff_ID
    );
  };

  const approveAndSend = () => {
    if (
      !isEqual(
        requiredDataForCompare(tableData),
        requiredDataForCompare(savedTableData)
      )
    ) {
      return toast.info("Please save changes");
    }

    setShowApproveAndSendModal(true);
  };

  return (
    <IsPrivileged roleName="Create Invoice">
      <main className="create-invoice">
        <PageHeader
          name={`Approve Invoice (Vendor)`}
          description={`Edit and Approve Invoice (Vendor)`}
          icon={<CreateInvoiceIcon />}
        />

        <div className="p-3 content">
          <div className="d-flex content-holder rounded">
            <section className="item-details customers">
              <div>
                <header className="d-flex gap-3 justify-content-between">
                  <h1>Approve Invoice</h1>
                  <ApprovalBadge
                    text={requisition?.status}
                    className="approval"
                    bg={resolveApprovalBadgeBg(requisition?.status)}
                  />
                </header>

                <div className="selected-data-area mt-3">
                  {/*  */}
                  <div className="table-holder">
                    {!isEmpty(tableData) ? (
                      <Table
                        responsive
                        borderless
                        hover
                        striped
                        className="product-table text-nowrap post-payment"
                      >
                        <thead>
                          <tr>
                            <th />
                            <th />
                            <th>Vendor</th>
                            <th>Invoice No</th>
                            <th>Remark</th>
                            <th>Amount</th>
                            <th>Attach Invoice</th>
                            <th>Due In (Days)</th>
                            <th>Due Date</th>
                            <th>Balance on Ledger</th>
                            <th>Transaction Date</th>
                            <th>Transaction ID</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.map((el, index) => (
                            <Fragment key={el.TransactionID}>
                              <VendorRow
                                vendors={data?.vendors}
                                index={index}
                                vendor={el}
                                editTable={editTable}
                                remove={remove}
                                addNewRow={addNewRow}
                                backendUrl={backendUrl}
                                getVendorBalanceAndCredit={
                                  getVendorBalanceAndCredit
                                }
                              />
                            </Fragment>
                          ))}
                        </tbody>
                      </Table>
                    ) : null}
                  </div>

                  <button
                    onClick={() => setShowVendorSelectorModal(true)}
                    type="button"
                    className="btn text-primary my-3 d-flex align-items-center gap-2"
                  >
                    <AddCircleIcon />
                    Add Vendor
                  </button>

                  {/*  No item  */}
                  {isEmpty(tableData) ? (
                    <div className="no-item my-5">
                      <div className="info">
                        <NoSelectedItemIcon />
                        <h2 className="mb-2">Haven't selected a Vendor yet</h2>
                        <p>
                          You can click +Add Vendor Button to add a Vendor to
                          the table.
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              {!isEmpty(tableData) && canPerformAction(requisition) ? (
                <div className="d-flex justify-content-between total-info">
                  <div />
                  <div>
                    <Button
                      onClick={() => post()}
                      disabled={saveVendorInvoiceMutation.isLoading}
                      type="button"
                      className="py-3 px-5"
                    >
                      Save Changes
                    </Button>
                  </div>
                </div>
              ) : null}

              {canPerformAction(requisition) ? (
                <div
                  className="border-top w-50 py-4 d-flex mt-4 d-flex justify-content-between d-flex gap-3 	bg-white"
                  style={{ zIndex: "10" }}
                >
                  <Button
                    onClick={() => reject()}
                    variant="danger"
                    className="text-white px-3 reject"
                  >
                    Reject
                  </Button>

                  <div className="proceed-actions d-flex gap-3 ">
                    <Button
                      className="btn btn-white border bg-white"
                      onClick={() => approveAndSend()}
                    >
                      Approve & Sent
                    </Button>
                    <Button
                      className="approve-and-close"
                      onClick={() => approveAndClose()}
                      variant="primary"
                    >
                      Approve & Close
                    </Button>
                  </div>
                </div>
              ) : (
                <div />
              )}
            </section>
          </div>
        </div>

        {/*   Modals */}
        {showVendorSelectorModal && (
          <VendorSelectModal
            setShowVendorSelectorModal={setShowVendorSelectorModal}
            setSelectedVendor={setSelectedVendor}
            withCredit={true}
            withPaymentDue={true}
            isMulti={false}
            alreadySelectedVendors={tableData}
            withInvoiceCat={true}
          />
        )}

        {showApproveAndSendModal && (
          <ApproveAndSendModal
            requestid={requestid}
            setShowApproveAndSendModal={setShowApproveAndSendModal}
            refetchRequisition={refetchRequisition}
          />
        )}

        <ModalLoader show={saveVendorInvoiceMutation.isLoading} />

        <ModalLoader
          show={isLoading}
          title="Please wait, getting vendor balance and due payments..."
        />
      </main>
    </IsPrivileged>
  );
}
