import { useStoreState } from "easy-peasy";
import AddRodItemModal from "./AddRodItemModal";
import AddRodItemMultipleModal from "./AddRodItemMultipleModal";
import AddRodItemMultipleGridModal from "./AddRodItemMultipleGridModal";

export default function AddItemModal(props) {
  const generalSettings = useStoreState((state) => state.generalSettings);
  return generalSettings.inventorySelector === "Single" ? (
    <AddRodItemModal {...props} />
  ) : generalSettings.inventorySelector === "Multiple Grid" ? (
    <AddRodItemMultipleGridModal {...props} />
  ) : (
    <AddRodItemMultipleModal {...props} />
  );
}
