import { Navigate, Route } from "react-router";
import { lazy } from "react";

import Items from "./Items";
import StoreLayout from "./StoreLayout";
import { CustomerAccount } from "../CustomerDetails/CustomerAccount";
import StoreOrder from "../SalesAndInvoicing/StoreOrder";
import ContactUs from "./ContactUs";
// import IsServiceAvailable from "../IsServiceAvailable";
import { appSettings } from "../../config";
import ContentPage from "./ContentPage";
import ChangePassword from "../ChangePassword";

// Lazy imports ------------------------------------------------------
const Profile = lazy(() => import("../CustomerDetails/Profile"));
const CustomerLedger = lazy(() => import("../CustomerDetails/Ledger"));
const CustomerPayments = lazy(() => import("../CustomerDetails/Payments"));
const CustomerInvoices = lazy(() => import("../CustomerDetails/Invoices"));
const CustomerOrders = lazy(() => import("../CustomerDetails/Orders"));
const Cart = lazy(() => import("../SalesAndInvoicing/Cart"));
const CustomerStoreQuotes = lazy(() =>
  import("../CustomerDetails/CustomerStoreQuotes")
);

const StoreRoutes = (
  /*  <Route element={<IsServiceAvailable serviceType="store" />}> */
  <Route element={<StoreLayout />}>
    <Route path={appSettings.storeBaseUrl}>
      <Route index element={<Navigate to="home" />} />
      <Route path="" element={<Items />} />
      <Route path="home" element={<Items />} />
      <Route path="cart" element={<Cart />} />
      <Route path="contact-us" element={<ContactUs />} />
      <Route path="account" element={<CustomerAccount />}>
        <Route index element={<Navigate to="profile" />} />
        <Route path="profile" element={<Profile />} />
        <Route path="ledger" element={<CustomerLedger />} />
        <Route path="payments" element={<CustomerPayments />} />
        <Route path="invoices" element={<CustomerInvoices />} />
        <Route path="orders" element={<CustomerOrders />} />
        <Route path="quotes" element={<CustomerStoreQuotes />} />
      </Route>
      <Route path="categories" element={<Items />}>
        <Route path=":categoryName" element={<Items />} />
      </Route>
      <Route path="products" element={<Items />}>
        <Route path=":productName" element={<Items />} />
      </Route>
      <Route path="track-order" element={<StoreOrder />} />
      <Route path="terms-and-conditions" element={<ContentPage key={1} />} />
      <Route path="return-policy" element={<ContentPage key={2} />} />
      <Route
        path="customer-products"
        element={<Items usage="customer-products" />}
      />
      <Route path="change-password" element={<ChangePassword />} />
    </Route>
  </Route>
  /*   </Route> */
);

export default StoreRoutes;
