import React, { useEffect, useState } from "react";
import { format, parse } from "date-fns";
import PageHeader from "../PageHeader";
import CreatableSelect from "react-select/creatable";

import {
  ApproveCheckIcon,
  AttachmentIcon,
  BusinessBillIcon,
  DotSpinner,
  DownloadIcon,
  EmailIcon,
  ExcelIcon,
  FilterCollapseIcon,
  FilterTwoIcon,
  LoaderIcon,
  ViewIcon,
} from "../Icons";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Button,
  InputGroup,
  Modal,
  Popover,
  Form,
  Dropdown,
  Collapse,
  Spinner,
} from "react-bootstrap";
import {
  Formik,
  Field,
  ErrorMessage,
  Form as FormikForm,
  useFormik,
} from "formik";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import {
  customerTypeOptions,
  formatDate,
  paginationOptions,
  searchParamsToObject,
} from "../../utils/helpers";
import queryString from "query-string";
import { isEmpty, uniqBy } from "lodash";
import { useQuery } from "react-query";
import { queryActions } from "../../utils/reactQueryActions";
import * as yup from "yup";

import { toast } from "react-toastify";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import ReactPaginate from "react-paginate";
import CachedIcon from "mdi-react/CachedIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import CustomerSelector from "../utils/CustomerSelector";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import TableComponent from "../TableComponent";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import NoTableItem from "../utils/NoTableItem";
import FormSelectorModal from "./FormSelectorModal";
import VirtualKeyboard from "../keyboard/Keyboard";
import { read, utils } from "xlsx";

const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }], // superscript/subscript
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  [{ direction: "rtl" }], // text direction

  [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],
  ["link"],

  ["clean"], // remove formatting button
  ["undo", "redo"],

  [{ formula: true }], // math formula
  [{ syntax: true }], // syntax highlighting
  [{ mention: true }], // user/entity mentions
  [{ table: true }],
  [{ history: true }],
];

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

function CreateCustomersEmail({ show, onclick, ...props }) {
  const { backendUrl, token, user } = useAuth();
  const [showFormModal, setShowFormModal] = useState(false);

  const [selectedForm, setSelectedForm] = useState("");
  const [importing, setImporting] = useState(false);

  const [formLink, setFormLink] = useState("");
  const [formId, setFormID] = useState("");

  const initialValues = {
    files: [],
    content: "",
    subject: "",
    recipient: "",
  };
  const schema = yup.object().shape({
    content: yup.string().required("required"),
    subject: yup.string().required("required"),
    // recipient: yup.string().required("required"),
  });

  const [options, setOptions] = useState([]);

  const handleChange = (newValue) => {
    setOptions(newValue);
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const paste = event.clipboardData.getData("text");
    const items = paste.split(",").map((item) => item.trim());
    const newOptions = items.map((item) => ({
      label: item,
      value: item,
      name: "",
    }));
    setOptions((prevOptions) => [...prevOptions, ...newOptions]);
  };
  // action form url query on render once

  const getCustomer = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/customers?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },

        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();

    data.customerTypes = uniqBy(
      [
        ...customerTypeOptions,
        ...data.customerTypes
          .filter((el) => el.TransType)
          .map((el) => ({
            value: el.TransType,
            label: el.TransType,
          })),
      ],
      "value"
    );

    data.groups = uniqBy(
      [
        ...data.groups
          .filter((el) => el.Group)
          .map((el) => ({
            value: el.Group,
            label: el.Group,
          })),
      ],
      "value"
    );

    return data;
  };

  const {
    data = { customers: [], customerTypes: [] },
    isFetching,
    isSuccess,
  } = useQuery([queryActions.CUSTOMERS], () => getCustomer(), {
    enabled: true,
    keepPreviousData: true,
  });

  const handleSubmit = async (values, onSubmitProps) => {
    const formData = new FormData();

    values.files.forEach((file, index) => {
      formData.append(`files${index}`, file);
    });

    formData.append("content", values.content);
    formData.append("recipient", values.recipient);
    formData.append("subject", values.subject);
    formData.append("formLink", formLink);
    formData.append("formId", formId);
    formData.append("customers", JSON.stringify(options));

    try {
      onSubmitProps.setSubmitting(true);
      let response = await fetch(
        `${backendUrl}/api/customers/send-customer-emails`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        toast.success(data.message);
        props.refetch();
        onSubmitProps.resetForm();

        onclick(false);
      }
    } catch (error) {
      toast.error("Message Not sent");
    }
    onSubmitProps.setSubmitting(false);
  };
  const handleSelectedForm = (currentForm) => {
    if (Boolean(currentForm)) {
      setFormLink(
        `${process.env.REACT_APP_FRONTEND_URL}/company-form/${
          currentForm?.id
        }/${user?.company}/fill-and-approved`
      );

      setFormID(currentForm?.id);
      setSelectedForm(currentForm);
    } else {
      setFormLink("");
      setFormID("");
      setSelectedForm("");
    }
  };
  function handleFile({ eventData: e, type }) {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = async function(e) {
        setImporting(true);
        const workbook = read(e.target.result, {
          cellDates: true,
          dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
        });

        let rows = utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          {
            defval: "",
            dateNF: "yyyy-MM-dd HH:mm:ss.SSS",
          }
        );

        const newOptions = rows.map((item) => ({
          ...item,
          label: item.Email,
          value: item.Email,
        }));
        setOptions((prevOptions) => [...prevOptions, ...newOptions]);
        setImporting(false);
      };

      reader.readAsArrayBuffer(file);
    }
    e.target.value = "";
  }

  return (
    <>
      {showFormModal && (
        <FormSelectorModal
          showchildForm={showFormModal}
          setShowChildForm={setShowFormModal}
          handleSelectedForm={handleSelectedForm}
        />
      )}

      <Modal show={show} size="xl" onHide={() => onclick(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="h6">Create Email</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <main className="">
            <div className="content">
              <div className="content-main">
                <div className="content-body">
                  <div className="p-4">
                    <Formik
                      initialValues={initialValues}
                      onSubmit={handleSubmit}
                      validationSchema={schema}
                    >
                      {({ setFieldValue, values, isSubmitting }) => (
                        <FormikForm noValidate>
                          <div className="row mb-3">
                            <div className=" col-md-8">
                              <div className="mb-3 row">
                                <label className="col-form-label fw-bold">
                                  Subject:
                                </label>
                                <div>
                                  <textarea
                                    className="form-control"
                                    value={values.subject}
                                    onChange={(event) =>
                                      setFieldValue(
                                        "subject",
                                        event.target.value
                                      )
                                    }
                                    name="subject"
                                    rows="3"
                                    style={{
                                      resize: "none",
                                    }}
                                  />
                                  <ErrorMessage
                                    name="subject"
                                    component="div"
                                    className="text-danger mb-2"
                                  />
                                </div>
                              </div>

                              <div className=" row mb-3">
                                <ReactQuill
                                  theme="snow"
                                  className="notranslate"
                                  placeholder="insert text here"
                                  modules={{
                                    toolbar: toolbarOptions,
                                  }}
                                  value={values.content}
                                  onChange={(content) =>
                                    setFieldValue("content", content)
                                  }
                                />
                                <ErrorMessage
                                  name="content"
                                  component="div"
                                  className="text-danger mb-2"
                                />
                              </div>

                              <div
                                className="row"
                                style={{ marginTop: "1rem" }}
                              >
                                {Boolean(selectedForm) && (
                                  <div className="col-md-3 mb-3">
                                    <div className="card p-cursor">
                                      <div className="bg-white border-0 p-2 pb-0 d-flex justify-content-between align-items-center card-header">
                                        <small className="text-light">
                                          Created:{" "}
                                          {selectedForm?.Date_Log
                                            ? format(
                                                new Date(
                                                  selectedForm?.Date_Log
                                                ),
                                                "dd/MM/yyyy"
                                              )
                                            : "..."}
                                        </small>
                                        <div className="d-flex align-items-center">
                                          {/* <ApproveCheckIcon /> */}
                                          <span
                                            className="p-2"
                                            style={{
                                              position: "absolute",
                                              top: "0",
                                              right: "0",
                                              cursor: "pointer",
                                              borderRadius: "50%",
                                            }}
                                            onClick={() => setSelectedForm("")}
                                          >
                                            ✖
                                          </span>
                                        </div>
                                      </div>

                                      <div className="card-body p-3 pt-2">
                                        <div className="card-subtitle mb-2 text-muted fw-normal">
                                          {selectedForm.UserName || "..."}
                                        </div>
                                        <div className="fw-bolder card-title h6">
                                          {selectedForm.Title || "..."}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <div className="">
                                  <label
                                    className=" p-2 border rounded-1"
                                    htmlFor="attachment"
                                    style={{
                                      backgroundColor: "#F4F7FA",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <AttachmentIcon />{" "}
                                    <span> Add Attachment</span>
                                    <input
                                      id="attachment"
                                      name="files"
                                      type="file"
                                      onChange={(event) => {
                                        const selectedFiles =
                                          event.currentTarget.files;

                                        setFieldValue("files", [
                                          ...values.files,
                                          ...selectedFiles,
                                        ]);
                                      }}
                                      multiple
                                      className=" d-none"
                                    />
                                  </label>
                                  <Button
                                    variant=""
                                    className=" mx-4  py-2 border rounded-1"
                                    style={{
                                      backgroundColor: "#F4F7FA",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => setShowFormModal(true)}
                                  >
                                    Attach Form
                                  </Button>
                                  <Button
                                    type="submit"
                                    variant="primary"
                                    className=" mx-4"
                                  >
                                    {isSubmitting ? " Sending" : " Send"}
                                  </Button>
                                </div>

                                <div className=" col-md-6">
                                  {/* {Boolean(selectedForm) && (
                                  <div
                                    style={{
                                      backgroundColor: "#F4F7FA",
                                      cursor: "pointer",
                                      position: "relative",
                                    }}
                                  >
                                    <p
                                      className="   p-2
                                border
                                rounded-1    my-2"
                                    >
                                      {selectedForm?.Title}
                                    </p>

                                    <span
                                      className="p-2"
                                      style={{
                                        position: "absolute",
                                        top: "0",
                                        right: "0",
                                        cursor: "pointer",
                                        borderRadius: "50%",
                                      }}
                                      onClick={() => setSelectedForm("")}
                                    >
                                      ✖
                                    </span>
                                  </div>
                                )} */}

                                  {values.files?.map((file) => (
                                    <div
                                      style={{
                                        backgroundColor: "#F4F7FA",
                                        cursor: "pointer",
                                        position: "relative",
                                      }}
                                    >
                                      <p
                                        className="   p-2
                                border
                                rounded-1    my-2"
                                      >
                                        {file.name}
                                      </p>

                                      <span
                                        className="p-2"
                                        style={{
                                          position: "absolute",
                                          top: "0",
                                          right: "0",
                                          cursor: "pointer",
                                          borderRadius: "50%",
                                        }}
                                        onClick={() => {
                                          const newFiles = values.files.filter(
                                            (el) => el.name !== file.name
                                          );
                                          setFieldValue("files", newFiles);
                                        }}
                                      >
                                        ✖
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className=" col-md-4">
                              <div className="mb-3 row">
                                <label className="col-form-label fw-bold">
                                  Recepient:
                                </label>
                                <div className="">
                                  <Select
                                    classNamePrefix="form-select"
                                    menuPosition="fixed"
                                    menuPlacement="auto"
                                    placeholder="Select receipient"
                                    name="recepient"
                                    isSearchable={true}
                                    onChange={(selected) => {
                                      if (selected) {
                                        setFieldValue(
                                          "recipient",
                                          selected?.value
                                        );
                                      } else {
                                        setFieldValue("recipient", "");
                                      }
                                    }}
                                    value={values?.value}
                                    options={data.groups || []}
                                    isClearable
                                  />
                                  {/* <ErrorMessage
                                    name="recipient"
                                    component="div"
                                    className="text-danger mb-2"
                                  /> */}
                                </div>
                              </div>

                              <div className="d-flex gap-4 justify-content-end mb-2">
                                <Dropdown>
                                  <Dropdown.Item
                                    variant=""
                                    className="btn print px-2 pt-1  "
                                    style={{ cursor: "pointer" }}
                                    bsPrefix=""
                                    as="span"
                                  >
                                    {importing ? (
                                      <Spinner
                                        animation="border"
                                        variant="primary"
                                        size="sm"
                                      />
                                    ) : (
                                      <div>
                                        <label
                                          htmlFor="template"
                                          style={{ cursor: "pointer" }}
                                        >
                                          Import <ExcelIcon color="#008000" />
                                        </label>
                                        <input
                                          type="file"
                                          id="template"
                                          className="d-none"
                                          accept=".xlsx"
                                          onChange={(e) => {
                                            handleFile({
                                              eventData: e,
                                              type: "INSERT_ITEMS",
                                            });
                                          }}
                                        />
                                      </div>
                                    )}
                                  </Dropdown.Item>
                                </Dropdown>

                                <Dropdown>
                                  <Dropdown.Item
                                    variant=""
                                    className="btn print px-2"
                                    bsPrefix=""
                                    as="a"
                                    href={`/excel_templates/INVEX_CUSTOMER_EMAIL.xlsx`}
                                    target="blank"
                                    title="Download"
                                    download=""
                                  >
                                    Templates <DownloadIcon />
                                  </Dropdown.Item>
                                </Dropdown>
                              </div>

                              <div onPaste={handlePaste}>
                                <CreatableSelect
                                  isMulti
                                  value={options}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                          </div>
                        </FormikForm>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </Modal.Body>
      </Modal>
    </>
  );
}

function EmailContent({ title, content, show, hide }) {
  return (
    <Modal show={show} onHide={() => hide(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="h6">Mail</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{title}</h4>
        <iframe
          srcDoc={content}
          className="w-100"
          style={{ minHeight: "20rem" }}
        />
      </Modal.Body>
    </Modal>
  );
}
function EmailAdressModal({ addressId, show, hide, group }) {
  async function getEmailAddress(url) {
    const res = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    });
    return await res.json();
  }

  const { backendUrl, token } = useAuth();
  const { data } = useQuery(["emailAddress", addressId], async () =>
    getEmailAddress(
      `${backendUrl}/api/customers/get-group-email-address/${addressId}`
    )
  );

  const tableHead = () => {
    return (
      <thead className="position-sticky top-0">
        <tr>
          <th>S/N</th>
          <th>Email</th>
        </tr>
      </thead>
    );
  };

  const tableData = (el, index) => {
    return (
      <>
        <td>{index + 1}</td>
        <td>{el}</td>
      </>
    );
  };

  return (
    <Modal show={show} onHide={() => hide(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title className="h6">Email Address</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="fw-bold mb-3">{group}</p>

        <div>
          <TableComponent
            responsive
            borderless
            striped
            tableHeadsFunction={tableHead}
            mainDataArray={data?.data?.Email.split(" , ") || []}
            className="product-table text-nowrap"
            tableDataRowFunction={tableData}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default function SendCustomersEmail() {
  useScrollTop();
  const navigate = useNavigate();
  const initialFilterParams = {
    title: "",
    startDate: "",
    endDate: "",
    email: "",
  };

  const [showFilter, setShowFilter] = useLocalStorage("showEmailFilter", true);
  const { backendUrl, token } = useAuth();
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [content, setContent] = useState("");
  const [title, setTitle] = useState("");
  const [showContentModal, setContentModal] = useState(false);
  const [showAddressModal, setShowEmailAddressModal] = useState(false);
  const [emailAddressesId, setEmailAddressesId] = useState("");
  const [recipientGroup, setRecipientGroup] = useState("");

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const fetchCompanDetails = async (query) => {
    const res = await fetch(
      `${backendUrl}/api/customers/get-bulk-mails?${queryString.stringify(
        query
      )}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      }
    );

    const data = await res.json();

    return data;
  };

  const { data, refetch, isFetching, isSuccess } = useQuery(
    ["GET_EMAIL_CAMPAIGN", queryParams],
    () => fetchCompanDetails(queryParams),

    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams, setQueryParams]);

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };
  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const tableHead = () => {
    return (
      <thead className="position-sticky top-0">
        <tr>
          <th />
          <th>Title</th>
          <th>Recipient Goup</th>
          <th>Email Adress</th>
          <th>Views</th>
          <th>Date</th>
        </tr>
      </thead>
    );
  };

  function parseViews(views) {
    return JSON.parse(views);
  }
  const tableData = (el, index) => {
    return (
      <>
        <td>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="bg-white border-0"
              bsPrefix="print more"
            >
              <DotsVerticalIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
              className="dropdown-with-icons"
            >
              <Dropdown.Item
                as="button"
                onClick={() => {
                  setContent(el.Content);
                  setTitle(el.Title);
                  setContentModal(true);
                }}
              >
                <ViewIcon /> Open{" "}
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() => {
                  setEmailAddressesId(el?.id);
                  setRecipientGroup(el.Recepient);

                  setShowEmailAddressModal(true);
                }}
              >
                <ViewIcon /> Recipient Email Address{" "}
              </Dropdown.Item>
            </Dropdown.Menu>{" "}
          </Dropdown>
        </td>
        <td>{el.Title}</td>
        <td>{el.Recepient}</td>
        <td style={{ wordBreak: "break-all" }}>
          {Boolean(el.Email) && el.Email}{" "}
          {el?.Email?.split(" , ").length === 2 && "..."}
          {el?.Email?.split(" , ").length === 2 && (
            <div className="">
              <Button
                variant="light-blue"
                className="border-none "
                size="sm"
                onClick={() => {
                  setEmailAddressesId(el?.id);
                  setRecipientGroup(el.Recepient);

                  setShowEmailAddressModal(true);
                }}
              >
                Show more
              </Button>
            </div>
          )}
        </td>
        <td>
          {(el?.views &&
            parseViews(el?.views)?.length &&
            parseViews(el?.views)?.length) ||
            0}
        </td>
        <td>
          {formatDate(el.Date)}
          {"  "}
          {formatDate(el.Date, "h:mm aa")}
        </td>
      </>
    );
  };

  return (
    <>
      <EmailAdressModal
        show={showAddressModal}
        group={recipientGroup}
        hide={setShowEmailAddressModal}
        addressId={emailAddressesId}
      />

      <EmailContent
        content={content}
        show={showContentModal}
        title={title}
        hide={setContentModal}
      />

      {showEmailModal && (
        <CreateCustomersEmail
          show={showEmailModal}
          onclick={setShowEmailModal}
          refetch={refetch}
        />
      )}

      <main className="inner-page-outlet">
        <PageHeader
          name=" Email Campaign"
          description="Send bulk Email to customers"
          icon={<EmailIcon />}
        />

        {/* <CreateCustomersEmail /> */}

        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className={`filter-area ${showFilter ? "open" : "close"}`}>
                <div className="filter-header">
                  <h2>
                    <FilterTwoIcon /> Filters
                  </h2>

                  <Button
                    variant="white"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <FilterCollapseIcon />
                  </Button>
                </div>
                <div className="filter-body">
                  <Form>
                    <div className="body">
                      <Form.Group className="mb-2-5">
                        <Form.Label>Title</Form.Label>{" "}
                        <InputGroup>
                          <Form.Control
                            name="title"
                            value={filterParams?.title}
                            placeholder="Title"
                            onChange={(e) => handleFilterParamsChange(e)}
                            id="email-title-id"
                          />{" "}
                        </InputGroup>
                      </Form.Group>
                      <Form.Group className="mb-2-5">
                        <Form.Label>Email</Form.Label>{" "}
                        <InputGroup>
                          <Form.Control
                            name="email"
                            value={filterParams?.email}
                            placeholder="Email Address"
                            onChange={(e) => handleFilterParamsChange(e)}
                          />{" "}
                        </InputGroup>
                      </Form.Group>

                      <hr className="mt-3 mb-4" />
                      <Form.Group className="">
                        <Form.Label>Date Range</Form.Label>
                        <div className="position-relative">
                          <RsDateRangePicker
                            placement="topStart"
                            value={
                              filterParams.startDate && filterParams.endDate
                                ? [
                                    parse(
                                      filterParams.startDate,
                                      "yyyy-MM-dd",
                                      new Date()
                                    ),
                                    parse(
                                      filterParams.endDate,
                                      "yyyy-MM-dd",
                                      new Date()
                                    ),
                                  ]
                                : []
                            }
                            onClean={() => clearDateRange()}
                            onOk={(date) => filterByDateRange(date)}
                          />
                        </div>
                      </Form.Group>
                    </div>

                    <div className="buttons rounded">
                      <Button onClick={() => reset()} variant="white">
                        Reset
                      </Button>
                      <Button onClick={() => search()} variant="primary">
                        Search
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="content-body">
                <header>
                  <h1>
                    {!showFilter && (
                      <button
                        onClick={() => setShowFilter(!showFilter)}
                        className="btn filter"
                      >
                        <FilterTwoIcon />
                      </button>
                    )}
                    Bulk Mails
                    <button
                      title="Refresh"
                      onClick={() => refetch()}
                      className="btn text-primary"
                    >
                      <CachedIcon />
                    </button>
                  </h1>
                  <div className="actions">
                    <Button
                      onClick={() => setShowEmailModal(true)}
                      className="btn print text-black"
                      variant="secondary"
                    >
                      <EmailIcon /> Create mail
                    </Button>
                  </div>
                </header>

                <div className=" px-md-4">
                  <TableComponent
                    responsive
                    borderless
                    striped
                    tableHeadsFunction={tableHead}
                    mainDataArray={data?.data?.bulkEmails || []}
                    className="product-table text-nowrap"
                    tableDataRowFunction={tableData}
                    // tableRowClick={(e, el) => manageTransaction(e, el)}
                  />

                  {!isFetching &&
                  isSuccess &&
                  isEmpty(data?.data?.bulkEmails) ? (
                    <NoTableItem queryParams={queryParams} />
                  ) : null}
                </div>

                <div className="d-flex justify-content-between px-3 align-items-center pagination">
                  <div className="pagination_left">
                    <p className="m-0 p-0">Show</p>
                    <select
                      value={queryParams.limit}
                      name="limit"
                      className="form-select "
                      onChange={(e) => handleSearchQueryChange(e)}
                    >
                      <option value="10">10 rows</option>
                      <option value="20">20 rows</option>
                      <option value="30">30 rows</option>
                      <option value="40">40 rows</option>
                      <option value="50">50 rows</option>
                      <option value="100">100 rows</option>{" "}
                    </select>
                  </div>

                  <ReactPaginate
                    {...paginationOptions}
                    pageCount={Math.ceil(data?.data?.count / queryParams.limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={0}
                    onPageChange={({ selected }) => {
                      document.body.scrollTop = document.documentElement.scrollTop = 0;
                      setQueryParams({
                        ...queryParams,
                        page: selected + 1,
                      });
                    }}
                    forcePage={queryParams.page - 1}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </main>
    </>
  );
}
