import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Modal, Table, Form, Button, Dropdown } from "react-bootstrap";
// import "./../assets/scss/customer-select-modal.scss";
import useDebounce, { useIsAdmin, useIsSalesManager } from "../../utils/hooks";
import { services } from "../../config";
import queryString from "query-string";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { queryActions } from "../../utils/reactQueryActions";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import {
  copyText,
  customerFullName,
  formatDate,
  formatForQty,
  paginationOptions,
} from "../../utils/helpers";
import currency from "currency.js";
import { useAuth } from "../../hooks/useAuth";
import CachedIcon from "mdi-react/CachedIcon";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { parse, format } from "date-fns";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { DeleteIcon, EditIcon, LinkVariantIcon, RecieptIcon } from "../Icons";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmDialog from "../ConfirmDialogue";
import ModalLoader from "../utils/ModalLoader";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import CreateBatchConsumptionModal from "../CreateConsumption";
import { isEmpty } from "lodash";
import NoTableItem from "../utils/NoTableItem";

export default function BatchConsumptionModal({
  setShowBatchConsumptionModal,
  handleSelectedConsumption,
  selectedBatchItem,
  setSelectedBatchItem,
}) {
  const { backendUrl } = useAuth();
  const queryClient = useQueryClient();
  const isAdmin = useIsAdmin();
  const isSalesManager = useIsSalesManager();
  const [
    showCreateBatchConsumptionModal,
    setShowCreateBatchConsumptionModal,
  ] = useState(false);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 40,
    comsumptionType: "",
    parentBar_Code: selectedBatchItem.Bar_Code,
  });
  const debouncedqueryParams = useDebounce(queryParams, 500);

  const getBatchConsumption = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/consumption?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    data.consumptionTypes = data.consumptionTypes.map((el) => ({
      label: el.Cat_Name,
      value: el.Cat_Name,
    }));
    return data;
  };

  const {
    data = { consumptions: [], consumptionTypes: [] },
    refetch,
    isFetching,
    isSuccess,
  } = useQuery(
    ["CONSUMPTIONS", debouncedqueryParams],
    () => getBatchConsumption(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      page: 1,
      [e.target.name]: e.target.value,
    });
  };

  const clearDateRange = () => {
    setQueryParams({
      ...queryParams,
      startDate: "",
      endDate: "",
    });
  };

  const filterByDateRange = (date) => {
    setQueryParams({
      ...queryParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const deleteConsumptionApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/consumption/delete`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const deleteConsumptionMutation = useMutation(
    (payload) => deleteConsumptionApi(payload),
    {
      onSuccess: () => {
        refetch();
        toast.success(`Success`);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const deleteConsumption = async (el) => {
    if (
      await ConfirmDialog({
        title: "Delete",
        description: "Are you sure, you want to delete",
      })
    ) {
      deleteConsumptionMutation.mutate({
        id: el.id,
      });
    }
  };

  return (
    <>
      <>
        <Modal
          show={true}
          onHide={() => setShowBatchConsumptionModal(false)}
          backdropClassName={`global-backdrop`}
          dialogClassName="modal-90w"
          centered={true}
          animation={false}
          enforceFocus={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h1 className="h5">
                Consumption - {selectedBatchItem.ItemName}/
                {selectedBatchItem?.Bar_Code}/{selectedBatchItem.Batch_Name}
                <button
                  onClick={() => refetch()}
                  className="btn text-primary mx-3"
                >
                  <CachedIcon />
                </button>
              </h1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex mb-4">
              <div className="search-area d-flex justify-content-between align-items-center gap-3 w-100 px-3">
                <div className="global-search-area  flex-grow-1">
                  <MagnifyIcon />
                  <Form.Control
                    id="queryParams-q"
                    className=""
                    name="q"
                    value={queryParams.q}
                    onChange={(e) => handleSearchQueryChange(e)}
                    placeholder="Search..."
                  />
                </div>

                <Form.Group>
                  <Select
                    classNamePrefix="form-select"
                    placeholder="Comsumption Type"
                    isSearchable={false}
                    onChange={({ value }) =>
                      setQueryParams({
                        ...queryParams,
                        page: 1,
                        comsumptionType: value,
                      })
                    }
                    value={data?.consumptionTypes.find(
                      (el) => el.value === queryParams.comsumptionType
                    )}
                    options={data?.consumptionTypes}
                  />
                </Form.Group>
              </div>
              <div className="px-3 d-flex gap-3">
                <RsDateRangePicker
                  placement="bottomEnd"
                  defaultValue={
                    queryParams.startDate && queryParams.endDate
                      ? [
                          parse(
                            queryParams.startDate,
                            "yyyy-MM-dd",
                            new Date()
                          ),
                          parse(queryParams.endDate, "yyyy-MM-dd", new Date()),
                        ]
                      : []
                  }
                  onClean={() => clearDateRange()}
                  onOk={(date) => filterByDateRange(date)}
                />

                <Button
                  onClick={() => setShowCreateBatchConsumptionModal(true)}
                >
                  Create
                </Button>
              </div>
            </div>

            <hr />

            <div className="content px-3 pb-4 pe-2">
              <Table borderless striped responsive className="product-table">
                <thead className="sticky border-bottom">
                  <tr>
                    <th />
                    <th>Item Code</th>
                    <th>Item Name</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Qty</th>
                    <th>Total</th> <th>Remark</th>
                    <th>Date</th>
                  </tr>
                </thead>

                <tbody className="blue-hover">
                  {data?.consumptions &&
                    data.consumptions.map((el, index) => (
                      <tr key={index} className={`p-cursor`}>
                        <td>
                          {" "}
                          <Dropdown style={{ margin: 0 }}>
                            <Dropdown.Toggle
                              variant=""
                              className="text-primary"
                              bsPrefix="print more"
                            >
                              <DotsVerticalIcon />
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                              popperConfig={{
                                strategy: "fixed",
                              }}
                              renderOnMount
                              className="dropdown-with-icons"
                            >
                              {isSalesManager || isAdmin ? (
                                <>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => deleteConsumption(el)}
                                  >
                                    <DeleteIcon /> Delete
                                  </Dropdown.Item>
                                </>
                              ) : null}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                        <td>{el.Bar_Code}</td>
                        <td>{el.Item_Name}</td> <td>{el.type}</td>{" "}
                        <td>
                          {currency(el.amount, {
                            symbol: "",
                          }).format()}
                        </td>
                        <td>
                          {currency(el.quantity, {
                            symbol: "",
                            precision: 2,
                            format: formatForQty,
                          }).format()}
                        </td>
                        <td>
                          {currency(el.subTotal, {
                            symbol: "",
                          }).format()}
                        </td>{" "}
                        <td>{el.remark}</td>
                        <td className="text-nowrap">
                          {el?.salesDate ? formatDate(el.salesDate) : "..."}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>

              {!isFetching && isSuccess && isEmpty(data?.consumptions) ? (
                <NoTableItem queryParams={queryParams} />
              ) : null}
            </div>

            <div className="d-flex justify-content-between px-3 align-items-center pagination">
              <p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
              </p>

              <p className="fw-6">
                {currency(data?.totalConsumption, { symbol: "" }).format()}
              </p>

              <ReactPaginate
                {...paginationOptions}
                pageCount={Math.ceil(data.count / queryParams.limit)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={0}
                onPageChange={({ selected }) => {
                  document.body.scrollTop = document.documentElement.scrollTop = 0;
                  setQueryParams({
                    ...queryParams,
                    page: selected + 1,
                  });
                }}
                forcePage={queryParams.page - 1}
              />
            </div>
          </Modal.Body>
        </Modal>
      </>
      {showCreateBatchConsumptionModal && (
        <CreateBatchConsumptionModal
          showCreateBatchConsumptionModal={showCreateBatchConsumptionModal}
          setShowCreateBatchConsumptionModal={
            setShowCreateBatchConsumptionModal
          }
          refetch={refetch}
          selectedBatchItem={selectedBatchItem}
        />
      )}
      <ModalLoader show={deleteConsumptionMutation.isLoading} />
    </>
  );
}
