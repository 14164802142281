import { action, createStore, persist } from "easy-peasy";
import { initialGeneralSettings } from "./utils/helpers";

const store = createStore(
  persist(
    {
      itemMeasurements: [],
      setItemMeasurements: action((state, payload) => {
        state.itemMeasurements = payload;
      }),
      defaultCustomer: {},
      setDefaultCustomer: action((state, payload) => {
        state.defaultCustomer = payload;
      }),
      generalSettings: {
        ...initialGeneralSettings,
      },
      setGeneralSettings: action((state, payload) => {
        state.generalSettings = payload;
      }),
      updateGeneralSettings: action((state, payload) => {
        state.generalSettings = { ...state.generalSettings, ...payload };
      }),
      selectedCompanyForAnalytics: {
        value: "",
        label: "",

        url: "",
      },
      setSelectedCompanyForAnalytics: action((state, payload) => {
        state.selectedCompanyForAnalytics = payload;
      }),
      filePool: [],
      setFilePool: action((state, payload) => {
        state.filePool = payload;
      }),
      currentLoggedInCompany: {},
      setCurrentLoggedInCompany: action((state, payload) => {
        state.currentLoggedInCompany = payload;
      }),
      cart: [],
      addToCart: action((state, payload) => {
        state.cart = [...state.cart, payload];
      }),
      removeFromCart: action((state, payload) => {
        state.cart = state.cart.filter(
          (el) => el.Bar_Code !== payload.Bar_Code
        );
      }),
      addQuantityToCart: action((state, payload) => {
        if (payload.Quantity <= 0) {
          state.cart = state.cart.filter(
            (el) => el.Bar_Code !== payload.Bar_Code
          );
          return;
        }
        state.cart[payload.index].Quantity = payload.Quantity;
      }),
      addSaleType: action((state, payload) => {
        state.cart[payload.index].saleType = payload.saleType;
      }),
      setCart: action((state, payload) => {
        state.cart = payload;
      }),
      rootRoles: [],
      setRootRoles: action((state, payload) => {
        state.rootRoles = payload;
      }),

      savedStaffRoles: [],
      setStaffRoles: action((state, payload) => {
        state.savedStaffRoles = payload;
      }),
      isAccountExpired: null,
      setAccountExpire: action((state, payload) => {
        state.isAccountExpired = payload;
      }),
      savedDeptRoles: [],
      setDeptRoles: action((state, payload) => {
        state.savedDeptRoles = payload;
      }),
      raisedItemRequisitionData: {},
      setRaisedItemRequisitionData: action((state, payload) => {
        state.raisedItemRequisitionData = payload;
      }),
    },
    {
      storage: "localStorage",
    }
  )
);

export default store;
