import { useState, useEffect } from "react";
import { useAuth } from "./useAuth";
import Cookies from "universal-cookie";
export const useCheckLoginUser = () => {
  const { backendUrl } = useAuth();

  const checkLoggedInUser = async () => {
    try {
      let response = await fetch(`${backendUrl}/api/auth/check-login`, {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      });
      const data = await response.json();

      if (
        data?.message.includes("not logged in") ||
        data?.message.includes("Invalid Token")
      ) {
        localStorage.clear();
        const cookies = new Cookies();
        cookies.remove("x-access-token", { path: "/" });
        window.location.href = "/login";
      }
    } catch (error) {
      console.log(error);
    }
  };

  return {
    checkLoggedInUser,
  };
};
