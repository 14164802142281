import { Modal } from "react-bootstrap";
import "./../assets/scss/new-entity-modal.scss";
import { useQuery } from "react-query";
import { useState, useEffect } from "react";
import { format } from "date-fns";

import { useAuth } from "../hooks/useAuth";
import { fetchActionsUtil } from "../utils/helpers";
import ApprovalBadge from "./utils/ApprovalBadge";
import VendorRFQChildFormsModal from "./VendorRFQChildForms";

export default function ViewImageModal({
  url,
  showImage,
  setShowImage,
  title,
}) {
  const [image, setImage] = useState([]);

  useEffect(() => {
    const fechIt = async (api) => {
      try {
        const getPics = await fetch(api);
        if (getPics.ok) {
          const profilePics = await getPics.blob();
          setImage(profilePics);
        } else {
          setImage("No Image");
        }
      } catch (error) {
        setImage("No Image");
      }
    };

    fechIt(url);
  }, [url]);

  return (
    <div>
      <Modal
        show={showImage}
        onHide={() => setShowImage(false)}
        centered={true}
        animation={false}
        enforceFocus={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5>{title ? title : "..."}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {showImage && (
              <div className="filter-body row">
                {typeof image === "string" ? (
                  <div className="text-center">
                    <h5>{image}</h5>
                  </div>
                ) : (
                  <object
                    data={URL.createObjectURL(image)}
                    type="application/pdf"
                    width="335"
                    height="450"
                  >
                    <embed
                      src={URL.createObjectURL(image)}
                      type="application/pdf"
                      width="335"
                      height="450"
                    />
                  </object>
                )}
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
