import { Modal, Form, Button, Table } from "react-bootstrap";
import { ErrorMessage, FieldArray, useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import NumberCustomInput from "../utils/NumberCustomInput";
import { confirmable, createConfirmation } from "react-confirm";
import { useMemo } from "react";
import currency from "currency.js";
import { useBackendUrl, useEffectOnce } from "../../utils/hooks";
import { toast } from "react-toastify";
import { useState } from "react";
import { formatForQty } from "../../utils/helpers";

function AddItemSizeModal({
  proceed,
  show,
  item,
  maxQuantity = 0,
  previousSizes = [],
}) {
  const backendUrl = useBackendUrl();
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    total: 0,
    sizesAndColours: [
      {
        Bar_code: item?.Bar_Code,
        Size_Color: "",
        Quantity: 0,
      },
    ],
  };

  const schema = yup.object().shape({
    sizesAndColours: yup.array().of(
      yup.object().shape({
        Size_Color: yup.string().required("Required"), // these constraints take precedence
        Quantity: yup.number().required("Required"), // these constraints take precedence
      })
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      if (Number(total) > Number(maxQuantity)) {
        return formik.setFieldError(
          "total",
          `The total (${total}) is greater than Quantity (${maxQuantity}) Entered `
        );
      }
      proceed(values);
    },
  });

  useEffectOnce(() => {
    formik.setFieldValue("sizesAndColours", [
      ...previousSizes,
      ...formik.values.sizesAndColours,
    ]);
  });

  const total = useMemo(
    () =>
      currency(
        formik.values.sizesAndColours
          .map((el) => el.Quantity)
          .reduce(
            (a, b) =>
              currency(a, {
                precision: 2,
              }).add(b),
            0
          )
      ).value,
    [formik.values.sizesAndColours]
  );

  return (
    <Modal show={show} onHide={() => proceed(false)} centered={true}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6">
            Item: {item?.Item_Name} - {item?.Bar_Code} - {maxQuantity}
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {" "}
        <FormikProvider value={formik}>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <FieldArray
              name="sizesAndColours"
              render={(arrayHelpers) => (
                <>
                  <Table borderless className="">
                    <thead>
                      <tr>
                        <th>Size / Color</th>
                        <th>Quantity</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {formik.values.sizesAndColours?.map((el, index) => (
                        <tr key={index}>
                          <td>
                            <Form.Control
                              type="text"
                              name={`sizesAndColours[${index}].Size_Color`}
                              value={
                                formik.values.sizesAndColours[index].Size_Color
                              }
                              onChange={formik.handleChange}
                            />
                            <ErrorMessage
                              component="span"
                              className="text-danger mb-2"
                              name={`sizesAndColours[${index}].Size_Color`}
                            />
                          </td>
                          <td>
                            <NumberCustomInput
                              name={`sizesAndColours[${index}].Quantity`}
                              value={
                                formik.values.sizesAndColours[index].Quantity
                              }
                              onValueChange={(value, name) => {
                                formik.setFieldValue(name, value);
                              }}
                              placeholder="0"
                            />
                            <ErrorMessage
                              component="span"
                              className="text-danger mb-2"
                              name={`sizesAndColours[${index}].Quantity`}
                            />
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn p-1"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              ✖
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      {" "}
                      <p>
                        Total:{" "}
                        {currency(total, {
                          symbol: "",
                          precision: 2,
                          format: formatForQty,
                        }).format()}
                      </p>
                      <ErrorMessage
                        component="span"
                        className="text-danger mb-2"
                        name={`total`}
                      />
                    </div>
                    <Button
                      type="button"
                      onClick={() => {
                        arrayHelpers.push({
                          Bar_code: "",
                          Size_Color: "",
                          Quantity: 0,
                        });
                      }}
                      size="sm"
                      variant="light-blue"
                    >
                      + Add
                    </Button>
                  </div>
                </>
              )}
            />
          </Form>{" "}
        </FormikProvider>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => formik.submitForm()}>Save</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default function AddItemSizeDialog(props) {
  return createConfirmation(confirmable(AddItemSizeModal))(props);
}
