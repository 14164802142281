import currency from "currency.js";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { services } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import useDebounce from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/scoped/items-table.scoped.scss";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import { paginationOptions } from "../../utils/helpers";
import { useStoreState } from "easy-peasy";

export default function BatchSelector({
  handleSelectedBatchItem,
  hideItemsNotInStock = false,
}) {
  const { backendUrl } = useAuth();
  const generalSettings = useStoreState((state) => state.generalSettings);

  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 100,
    q: "",
    model: "StockBatches",
    withExpiry: true,
  });

  const debouncedqueryParams = useDebounce(queryParams, 500);

  const getItems = async (debouncedqueryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/items?${queryString.stringify(debouncedqueryParams)}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const { data = { items: [] } } = useQuery(
    [queryActions.GET_ITEMS, debouncedqueryParams],
    () => getItems(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );
  return (
    <div className="items-table-area rounded">
      <div className="search-area m-3">
        <MagnifyIcon />
        <input
          className="form-control search-input"
          name="q"
          value={queryParams.q}
          onChange={(e) =>
            setQueryParams({
              ...queryParams,
              page: 1,
              [e.target.name]: e.target.value,
            })
          }
          placeholder="Search Batch Item..."
          autoFocus
          autoComplete="off"
          type={"text"}
        />
      </div>

      <div className="content">
        <Table borderless hover striped className="product-table">
          <thead className="sticky text-nowrap">
            <tr>
              <th scope="col">Batch</th>
              <th scope="col">{generalSettings.Batch_Name}</th>
              <th scope="col">Item Name</th>{" "}
              {/* <th scope="col">Quantity</th> */}
            </tr>
          </thead>
          <tbody>
            {data.items &&
              data.items
                .filter((el) =>
                  hideItemsNotInStock ? Number(el.Quantity) > 0 : el
                )
                .map((el, index) => (
                  <tr
                    className="p-cursor"
                    key={index}
                    onClick={() =>
                      handleSelectedBatchItem && handleSelectedBatchItem(el)
                    }
                  >
                    <td>
                      {el.Bar_Code ? el.Bar_Code.split("-").pop() : "..."}
                    </td>
                    <td>{el.Batch_Name || "..."}</td>
                    <td title={el?.Quantity}>{el.ItemName}</td>
                    {/* <td>
                      {currency(el?.Quantity, {
                       precision: 2,
 format: formatForQty,
                        symbol: "",
                      }).format()}
                    </td> */}
                  </tr>
                ))}
          </tbody>
        </Table>
      </div>

      <div className="d-flex justify-content-between px-3 my-3 align-items-center pagination">
        <p className="m-0">
          Showing {data?.startIndex + 1} to{" "}
          {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
          {data.count} entries
        </p>

        <ReactPaginate
          {...paginationOptions}
          pageCount={Math.ceil(data.count / queryParams.limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={0}
          onPageChange={({ selected }) => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            setQueryParams({
              ...queryParams,
              page: selected + 1,
            });
          }}
          forcePage={queryParams.page - 1}
        />
      </div>
    </div>
  );
}
