import React from "react";
import PageHeader from "../PageHeader";
import { CircleTickedIcon, PaymentAndBillingIcon } from "../Icons";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export default function ConfirmPaymentAndBilling() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const createUserQuery = params.get("create-user");
  const referenceState = location?.state;
  return (
    <main className="inner-page-outlet ">
      <PageHeader
        name="Payment & Billing"
        description="Confirm Payment"
        icon={<PaymentAndBillingIcon />}
      />
      <main>
        <div className="outer-circle py-5">
          <div className=" circle mx-auto">
            <CircleTickedIcon />
          </div>
          <h4 className="text-center my-3 text-headers ">Payment Successful</h4>
          <p className="text-center">Your payment has been processed</p>
          <p className="text-center pb-3">
            Details of your payment has been sent to your email.
          </p>

          <div className="payment-detail-container mx-auto">
            <div className="row border-top">
              <h6 className="  text-center py-3 transaction-id-text">
                Transaction ID: {referenceState?.reference}
              </h6>

              <div className="row my-4 mx-auto btn-contanier">
                {Boolean(createUserQuery) && (
                  <Button
                    variant="primary"
                    className="  py-3 mt-2 col-md-5 mx-auto btn-users"
                    onClick={() =>
                      (window.location.href =
                        "/dashboard/user-managemnt?create-users=true")
                    }
                  >
                    Create users
                  </Button>
                )}

                <Button
                  variant=""
                  className="btn-lg mt-2 col-md-5 mx-2 py-3    btn-outline-primary mx-auto btn-users"
                  onClick={() => (window.location.href = "/dashboard")}
                >
                  Go to Dashboard
                </Button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </main>
  );
}
