import { useState } from "react";
import { Tooltip, OverlayTrigger, Dropdown } from "react-bootstrap";
import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";

export function Component(props) {
  //given that props.story contains 'PhotoStory' or 'VideoStory'
  const SpecificComponent = props.component;
  return <SpecificComponent {...props} />;
}

export function FormSwitch() {
  return "switch";
}

export function OverlayTooltip({ text, component }) {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      {component}
    </OverlayTrigger>
  );
}

export const HoverDropDown = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleMouseOver = () => {
    setDropdownOpen(true);
  };

  const handleMouseOut = () => {
    setDropdownOpen(false);
  };

  return (
    <Dropdown
      {...props}
      show={dropdownOpen}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {props.children}
    </Dropdown>
  );
};

export const OverflowDropdown = ({ toggle, menu }) => {
  const [show, setShow] = useState(false);

  const menuContainer = useRef(document.createElement("div"));

  React.useEffect(() => {
    document.body.appendChild(menuContainer.current);
    return () => {
      document.body.removeChild(menuContainer.current);
    };
  }, []);

  const handleMouseEnter = () => setShow(true);
  const handleMouseLeave = () => setShow(false);

  const clonedMenu = menu
    ? React.cloneElement(menu, {
        as: CustomMenu,
        container: menuContainer.current,
      })
    : null;

  return (
    <div
      style={{
        overflow: "hidden",
        width: "100%",
        height: "fit-content",
        position: "relative",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Dropdown
        show={show}
        onToggle={() => {}}
        alignRight
        drop="end"
        className="w-100"
        autoClose="outside"
      >
        {toggle}
        {clonedMenu}
      </Dropdown>
    </div>
  );
};

const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    return ReactDOM.createPortal(
      <div
        ref={ref}
        style={{ ...style, position: "absolute" }}
        className={className}
        aria-labelledby={labeledBy}
      >
        {children}
      </div>,
      document.body
    );
  }
);
