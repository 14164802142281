import { useFormik } from "formik";
import { Modal, Form, Button } from "react-bootstrap";
import DateTime from "react-datetime";
import Select from "react-select";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import NumberCustomInput from "../utils/NumberCustomInput";
import * as yup from "yup";
import { useAuth } from "../../hooks/useAuth";
import { useQuery, useMutation } from "react-query";
import { useMemo } from "react";
import currency from "currency.js";
import { toast } from "react-toastify";
import ModalLoader from "../utils/ModalLoader";

const paymentMethod = [
  {
    label: "CASH",
    value: "CASH",
  },
  {
    label: "CASH BY WIRE TRANSFER",
    value: "CASH BY WIRE TRANSFER",
  },
  {
    label: "CLEAN CREDIT",
    value: "CLEAN CREDIT",
  },
  {
    label: "BANK GUARANTEE",
    value: "BANK GUARANTEE",
  },
  {
    label: "OVER DRAFT",
    value: "OVER DRAFT",
  },
];

const paymentTerms = [
  {
    label: "One Installment",
    value: 1,
  },
  {
    label: "Two Installments",
    value: 2,
  },
  {
    label: "Three Installments",
    value: 3,
  },
  {
    label: "Four Installments",
    value: 4,
  },
  {
    label: "Five Installments",
    value: 5,
  },
  {
    label: "Six Installments",
    value: 6,
  },
  {
    label: "Seven Installments",
    value: 7,
  },
  {
    label: "Eight Installments",
    value: 8,
  },

  {
    label: "Nine Installments",
    value: 9,
  },
  {
    label: "Ten Installments",
    value: 10,
  },
];

const getSubCategories = (parentCat = "All", subCategories = []) => {
  subCategories = subCategories
    .filter((el) => el.Cat_Name === parentCat)
    .map((el) => ({ label: el.Sub_Cat, value: el.Sub_Cat }));
  return [{ label: "All", value: "All" }, ...subCategories];
};

export default function SetupAtcModal({
  showSetupAtcModal,
  setShowSetupAtcModal,
}) {
  const { backendUrl } = useAuth();

  const formik = useFormik({
    initialValues: {
      requestDate: new Date(),
      Qty: 0,
      Vendor_ID: "",
      Region: "",
      Plant: "",
      Depot: "",
      PaymentType: "",
      amountPaid: 0,
      UnitCost: 0,
      Product: "Cement",
      Cat_Name: "",
      Sub_Cat: "",
      payTerm: 1,
      nextInstallment: 45,
      bank: "",
      Trans_ID: "",
    },
    validationSchema: yup.object().shape({
      Trans_ID: yup.string().required("required"),
      Qty: yup.number().required("required"),
      UnitCost: yup.number().required("required"),
      Vendor_ID: yup.string().required("required"),
      Region: yup.string().required("required"),
      PaymentType: yup.string().required("required"),
      Cat_Name: yup.string().required("required"),
      Sub_Cat: yup.string().required("required"),
      //  bank: yup.string().required("required"),
    }),
    onSubmit: (values) => {
      // Select plant or depot
      if (values.Plant === "" && values.Depot === "") {
        formik.setFieldError("Plant", "Please select Plant or Depot");
        formik.setFieldError("Depot", "Please select Plant or Depot");
        return;
      }

      const formDataToSave = { ...values };
      formDataToSave.totalAmount = totalAmount;
      createAtcMutation.mutate(formDataToSave);
    },
  });

  const fetchSetUpData = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/incentive/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();

    data.vendorsSelect = data.vendors
      .filter((el) => el.Type !== "Bank")
      .map((el) => ({
        label: el.CompanyName,
        value: el.Vendor_ID,
      }));

    data.plantSelect = data.plant.map((el) => ({
      region: el.Region,
      label: el.Plant,
      value: el.Plant,
    }));

    data.depotSelect = data.depot.map((el) => ({
      region: el.Region,
      label: el.Depot,
      value: el.Depot,
    }));

    data.regionSelect = data.region.map((el) => ({
      label: el.region,
      value: el.region,
    }));

    data.category = [
      {
        label: "All",
        value: "All",
      },
      ...data.categories.map((el) => ({
        ...el,
        value: el.Cat_Name,
        label: el.Cat_Name,
      })),
    ];

    data.subcategory = [
      {
        label: "All",
        value: "All",
      },
      ...data.subCategories.map((el) => ({
        ...el,
        value: el.Sub_Cat,
        label: el.Sub_Cat,
      })),
    ];

    data.banks = data?.banks.map((el) => ({
      ...el,
      value: el.BankName,
      label: el.BankName,
    }));

    data.vendorBanks = data.vendorBanks.map((el) => ({
      ...el,
      value: el.CompanyName,
      label: el.CompanyName,
    }));

    return data;
  };

  const {
    data = {
      category: [],
      subcategory: [],
      region: [],
      depot: [],
      plant: [],
      subCategoriesWithParent: [],
      plantAndDepot: [],
      // -------------------------
      vendorsSelect: [],
      plantSelect: [],
      depotSelect: [],
      regionSelect: [],
      banks: [],
      vendorBanks: [],
    },
    refetch,
  } = useQuery(["INCENTIVE_SETUP"], () => fetchSetUpData(), {
    keepPreviousData: true,
  });

  const filterByRegion = (region = "", data = []) => {
    return data.filter((el) => el.region === region);
  };

  const bankSelectOptions = (paymentMethod) => {
    return ["OVER DRAFT", "CLEAN CREDIT", "BANK GUARANTEE"].includes(
      paymentMethod
    )
      ? data.vendorBanks
      : data.banks;
  };

  const totalAmount = useMemo(() => {
    return currency(formik.values.UnitCost, {
      symbol: "",
      separator: "",
    })
      .multiply(formik.values.Qty)
      .format();
  }, [formik.values.Qty, formik.values.UnitCost]);

  const createAtc = async (payload) => {
    let response = await fetch(`${backendUrl}/api/atc/setup-atc`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const createAtcMutation = useMutation((payload) => createAtc(payload), {
    onSuccess: ({ message, data }) => {
      toast.success(message);
      formik.resetForm();
      // refetch();
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const handlePaymentMethod = (selected) => {
    formik.setFieldValue("PaymentType", selected.value);
    formik.setFieldValue("bank", "");
    if (["CASH", "CASH BY WIRE TRANSFER"].includes(selected.value)) {
      formik.setFieldValue("amountPaid", totalAmount);
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={showSetupAtcModal}
        onHide={() => setShowSetupAtcModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            ATC Order Setup
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="row gap-4">
            <div className="col">
              <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-1">Request Date</Form.Label>
                <DateTime
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  dateFormat="MMM DD, YYYY"
                  inputProps={{
                    className: `date-input form-control`,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.requestDate}
                  onChange={(date) => {
                    formik.setFieldValue("requestDate", date, true);
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-1">Ref Number</Form.Label>
                <Form.Control
                  name="Trans_ID"
                  value={formik.values.Trans_ID}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.Trans_ID && !!formik.errors.Trans_ID
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Trans_ID}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-1">Qty in Bags</Form.Label>
                <NumberCustomInput
                  placeholder="0"
                  name="Qty"
                  value={formik.values.Qty}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value, true);
                  }}
                  isInvalid={formik.touched.Qty && !!formik.errors.Qty}
                  onBlur={() => formik.setFieldTouched("Qty", true)}
                  allowNegativeValue={false}
                  // allowDecimals={true}
                />
                {formik.touched.Qty && !!formik.errors.Qty ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.Qty}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-1">Region</Form.Label>
                <Select
                  classNamePrefix={"form-select"}
                  options={data?.regionSelect}
                  className={
                    formik.touched.Region && !!formik.errors.Region
                      ? "is-invalid"
                      : ""
                  }
                  isSearchable={false}
                  value={
                    (data?.regionSelect || []).find(
                      (el) => el.value === formik.values.Region
                    ) || ""
                  }
                  onChange={(selected) => {
                    formik.setFieldValue("Region", selected.value);
                  }}
                />
                {formik.touched.Region && !!formik.errors.Region ? (
                  <span className="text-danger mt-2">
                    {formik.errors.Region}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-1">Plant</Form.Label>
                <Select
                  classNamePrefix={"form-select"}
                  options={filterByRegion(
                    formik.values.Region,
                    data?.plantSelect
                  )}
                  className={
                    formik.touched.Plant && !!formik.errors.Plant
                      ? "is-invalid"
                      : ""
                  }
                  isSearchable={false}
                  value={
                    (
                      filterByRegion(formik.values.Region, data?.plantSelect) ||
                      []
                    ).find((el) => el.value === formik.values.Plant) || ""
                  }
                  onChange={(selected) => {
                    formik.setFieldValue("Plant", selected.value);
                    formik.setFieldValue("Depot", "");
                  }}
                />
                {formik.touched.Plant && !!formik.errors.Plant ? (
                  <span className="text-danger mt-2">
                    {formik.errors.Plant}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-1">Depot</Form.Label>
                <Select
                  classNamePrefix={"form-select"}
                  options={filterByRegion(
                    formik.values.Region,
                    data?.depotSelect
                  )}
                  className={
                    formik.touched.Depot && !!formik.errors.Depot
                      ? "is-invalid"
                      : ""
                  }
                  isSearchable={false}
                  value={
                    (
                      filterByRegion(formik.values.Region, data?.depotSelect) ||
                      []
                    ).find((el) => el.value === formik.values.Depot) || ""
                  }
                  onChange={(selected) => {
                    formik.setFieldValue("Depot", selected.value);
                    formik.setFieldValue("Plant", "");
                  }}
                />
                {formik.touched.Depot && !!formik.errors.Depot ? (
                  <span className="text-danger mt-2">
                    {formik.errors.Depot}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-1">Payment Method</Form.Label>
                <Select
                  classNamePrefix={"form-select"}
                  options={paymentMethod}
                  className={
                    formik.touched.PaymentType && !!formik.errors.PaymentType
                      ? "is-invalid"
                      : ""
                  }
                  isSearchable={false}
                  value={
                    (paymentMethod || []).find(
                      (el) => el.value === formik.values.PaymentType
                    ) || ""
                  }
                  onChange={(selected) => handlePaymentMethod(selected)}
                />
                {formik.touched.PaymentType && !!formik.errors.PaymentType ? (
                  <span className="text-danger mt-2">
                    {formik.errors.PaymentType}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-1">Bank</Form.Label>
                <Select
                  classNamePrefix={"form-select"}
                  options={bankSelectOptions(formik.values.PaymentType)}
                  value={
                    bankSelectOptions(formik.values.PaymentType).find(
                      (el) => el.value === formik.values.bank
                    ) || ""
                  }
                  onChange={(selected) => {
                    formik.setFieldValue("bank", selected.value);
                    if (selected?.Vendor_ID) {
                      formik.setFieldValue("Vendor_ID", selected.Vendor_ID);
                    }
                  }}
                  className={
                    formik.touched.bank && !!formik.errors.bank
                      ? "is-invalid"
                      : ""
                  }
                  isDisabled={["CASH" /* "CASH BY WIRE TRANSFER" */].includes(
                    formik.values.PaymentType
                  )}
                />
                {formik.touched.bank && !!formik.errors.bank ? (
                  <span className="text-danger mt-2">{formik.errors.bank}</span>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-1">Amount Paid</Form.Label>
                <CurrencyCustomInput
                  name="amountPaid"
                  value={formik.values.amountPaid}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={
                    formik.touched.amountPaid && !!formik.errors.amountPaid
                  }
                  placeholder="0.00"
                />
                {formik.touched.amountPaid && !!formik.errors.amountPaid ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.amountPaid}
                  </span>
                ) : null}
              </Form.Group>
            </div>

            <div className="col">
              <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-1">Vendor</Form.Label>
                <Select
                  classNamePrefix={"form-select"}
                  options={data?.vendorsSelect}
                  className={
                    formik.touched.Vendor_ID && !!formik.errors.Vendor_ID
                      ? "is-invalid"
                      : ""
                  }
                  isSearchable={false}
                  value={
                    (data?.vendorsSelect || []).find(
                      (el) => el.value === formik.values.Vendor_ID
                    ) || ""
                  }
                  onChange={(selected) => {
                    formik.setFieldValue("Vendor_ID", selected.value);
                  }}
                  isDisabled={[
                    "OVER DRAFT",
                    "CLEAN CREDIT",
                    "BANK GUARANTEE",
                  ].includes(formik.values.PaymentType)}
                />
                {formik.touched.Vendor_ID && !!formik.errors.Vendor_ID ? (
                  <span className="text-danger mt-2">
                    {formik.errors.Vendor_ID}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-1">Unit Cost</Form.Label>
                <CurrencyCustomInput
                  name="UnitCost"
                  value={formik.values.UnitCost}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={
                    formik.touched.UnitCost && !!formik.errors.UnitCost
                  }
                  placeholder="0.00"
                />
                {formik.touched.UnitCost && !!formik.errors.UnitCost ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.UnitCost}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-1">Total Amount</Form.Label>
                <CurrencyCustomInput value={totalAmount} readOnly />
              </Form.Group>

              <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-1">Product</Form.Label>
                <Form.Control
                  name="Product"
                  value={formik.values.Product}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.Product && !!formik.errors.Product}
                />
              </Form.Group>

              <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-1">Category</Form.Label>
                <Select
                  classNamePrefix={"form-select"}
                  options={data?.category}
                  value={
                    data?.category?.find(
                      (el) => el.value === formik.values.Cat_Name
                    ) || ""
                  }
                  onChange={({ value }) => {
                    formik.setFieldValue("Cat_Name", value);
                    formik.setFieldValue("Sub_Cat", "All");
                  }}
                  className={
                    formik.touched.Cat_Name && !!formik.errors.Cat_Name
                      ? "is-invalid"
                      : ""
                  }
                />
                {formik.touched.Cat_Name && !!formik.errors.Cat_Name ? (
                  <span className="text-danger mt-2">
                    {formik.errors.Cat_Name}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-1">Sub Category</Form.Label>
                <Select
                  classNamePrefix={"form-select"}
                  options={getSubCategories(
                    formik.values.Cat_Name,
                    data?.subCategoriesWithParent
                  )}
                  value={
                    getSubCategories(
                      formik.values.Cat_Name,
                      data?.subCategoriesWithParent
                    ).find((el) => el.value === formik.values.Sub_Cat) || ""
                  }
                  onChange={({ value }) =>
                    formik.setFieldValue("Sub_Cat", value)
                  }
                  className={
                    formik.touched.Sub_Cat && !!formik.errors.Sub_Cat
                      ? "is-invalid"
                      : ""
                  }
                />
                {formik.touched.Sub_Cat && !!formik.errors.Sub_Cat ? (
                  <span className="text-danger mt-2">
                    {formik.errors.Sub_Cat}
                  </span>
                ) : null}
              </Form.Group>

              <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-1">Payment term</Form.Label>
                <Select
                  classNamePrefix={"form-select"}
                  options={paymentTerms}
                  value={
                    paymentTerms?.find(
                      (el) => el.value === formik.values.payTerm
                    ) || ""
                  }
                  onChange={({ value }) => {
                    formik.setFieldValue("payTerm", value);
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3 pb-2">
                <Form.Label className="mb-1">
                  Next Installment (days)
                </Form.Label>
                <NumberCustomInput
                  name="nextInstallment"
                  value={formik.values.nextInstallment}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                />
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer className="gap-3">
          <Button onClick={() => formik.resetForm()} variant="outline-primary">
            Refresh
          </Button>
          <Button onClick={() => formik.submitForm()} className="px-3">
            Post
          </Button>
        </Modal.Footer>
      </Modal>
      <ModalLoader show={createAtcMutation.isLoading} />
    </>
  );
}
