import { useIsFetching } from "react-query";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useEffectOnce } from "../utils/hooks";
import { LoaderIcon } from "./Icons";
import OfflineCheck from "./OfflineCheck";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import GlobalModals from "./modals/GlobalModals";
import VendorNavBar from "./VendorNavBar";

export default function VendorMasterLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { authVendor, isExpired } = useAuth();
  //   console.log(authVendor, isExpired);
  const isFetching = useIsFetching();

  useEffectOnce(() => {
    document.title = `InvexERP - ${authVendor?.CompanyName}`;
    if (isExpired) {
      toast.error("Account Expired, Contact Support");
      return navigate("/vendor-login", { replace: true });
    }
  });

  if (isEmpty(authVendor)) {
    return <Navigate to="/vendor-login" replace state={{ from: location }} />;
  }

  return (
    <>
      <VendorNavBar />
      <Outlet />
      {isFetching ? (
        <div className="global-spinner">
          <LoaderIcon className="spin text-primary" />
        </div>
      ) : (
        <></>
      )}

      <footer className="d-flex py-3 align-items-center justify-content-center text-center">
        <p className="mb-0">
          ©{new Date().getFullYear()}{" "}
          <a
            href="https://invexerp.excellentbridge.com/"
            target="_blank"
            className="text-decoration-underline"
          >
            Excellentbridge Technologies
          </a>
          <br />
          <small>Version {process.env?.REACT_APP_VERSION}</small>
        </p>
      </footer>
      <OfflineCheck />
      <GlobalModals />
    </>
  );
}
