import {
  Modal,
  Form,
  Button,
  InputGroup,
  Table,
  Dropdown,
  Row,
  Col,
} from "react-bootstrap";
import "./../../assets/scss/new-entity-modal.scss";
import Select from "react-select";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import { DeleteIcon, EditIcon, NoSelectedItemIcon } from "./../Icons";
import NumberCustomInput from "../utils/NumberCustomInput";
import DateTime from "react-datetime";
import { useAuth } from "../../hooks/useAuth";
import { useQuery, useMutation } from "react-query";
// import { queryActions } from "../../utils/reactQueryActions";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import * as yup from "yup";
import currency from "currency.js";
import moment from "moment";
import { toast } from "react-toastify";
import CachedIcon from "mdi-react/CachedIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Popover } from "react-tiny-popover";
import {
  qtyFormat,
  qtyFormatToString,
  scrollToElement,
  styleHelper,
  tonsToPcs,
  Units,
} from "./../../utils/helpers";
import { isEmpty } from "lodash";
import { useStoreState } from "easy-peasy";
import { useRef } from "react";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { appSettings } from "../../config";
import ModalLoader from "../utils/ModalLoader";
import PurchaseHistorySelector from "./PurchaseHistotrySelector";
import ConfirmDialog from "../ConfirmDialogue";
import {
  CashSelectIcon,
  CreditSelectIcon,
  DirectTransferSelectIcon,
  ChequeSelectIcon,
} from "./../Icons";
import WarehouseItemTable from "./WarehouseItemTable";

const pointsOfDamages = [
  {
    label: "Company store",
    value: "Company store",
  },
  {
    label: "Transit",
    value: "Transit",
  },
  {
    label: "Customer Possession",
    value: "Customer Possession",
  },
  {
    label: "Manufacturer",
    value: "Manufacturer",
  },
  {
    label: "Point of sales",
    value: "Point of sales",
  },
  {
    label: "Others",
    value: "Others",
  },
];

const damageStatus = [
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Replaced By Vendor",
    value: "Replaced By Vendor",
  },
  {
    label: "Credit Note from Vendor",
    value: "Credit Note from Vendor",
  },
  {
    label: "Expired",
    value: "Expired",
  },
  {
    label: "Quarantined",
    value: "Quarantined",
  },
  {
    label: "Outright Damage",
    value: "Outright Damage",
  },
  {
    label: "Scrap",
    value: "Scrap",
  },
];

const paymentMethod = [
  {
    icon: <CreditSelectIcon />,
    label: "Imprest",
    value: "Imprest",
  },
  {
    icon: <CashSelectIcon />,
    label: "Cash",
    value: "Cash",
  },

  {
    icon: <ChequeSelectIcon />,
    label: "Cheque",
    value: "Cheque",
  },

  {
    icon: <DirectTransferSelectIcon />,
    label: "Direct Bank Transfer",
    value: "Direct Bank Transfer",
  },
  {
    icon: <CreditSelectIcon />,
    label: "Credit/Debit Card (POS)",
    value: "Credit/Debit Card",
  },
];

const cashOptions = [
  {
    label: "Cash On Hand",
    value: "Cash on hand",
  },
];

export default function DamageItemModal({
  showDamageItemModal,
  setShowDamageItemModal,
  refetch,
  isMulti = true,
}) {
  const barcodeInputRef = useRef();
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl, token } = useAuth();

  const [showPurchaseHistoryPopover, setShowPurchaseHistoryPopover] = useState(
    false
  );
  const [tableData, setTableData] = useState([]);

  const [selectFrom, setSelectFrom] = useState("Purchase History");

  const [showItemsPopover, setShowItemsPopover] = useState(false);

  const wares = JSON.parse(window.localStorage.getItem("warehouseName"));

  const setUp = async (department) => {
    let response = await fetch(`${backendUrl}/api/items/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.vendors = data?.vendors.map((el) => ({
      label: el.CompanyName,
      value: el.CompanyName,
      ...el,
    }));
    data.itemMeasurement = data?.itemMeasurement.map((el) => ({
      label: el.Size,
      value: el.Size,
    }));

    data.categories = data.categories.map((el) => ({
      value: el.Cat_Name,
      label: el.Cat_Name,
    }));

    data.products = data.products.map((el) => ({
      value: el.Product_Name,
      label: el.Product_Name,
    }));

    data.imprest = data?.imprest
      .filter((el) => el?.Description)
      .map((el) => ({
        ...el,
        label: el?.Description,
        value: el?.Description,
      }));

    data.banks = data?.banks
      .filter((el) => el?.BankName)
      .map((el) => ({
        ...el,
        label: `${el.BankName} ${el?.currency ? `(${el?.currency})` : `(NGN)`}`,
        value: el.BankName,
      }));

    return data;
  };
  const {
    data = {
      vendors: [],
      itemMeasurement: [],
    },
  } = useQuery(["WAREHOUSE_SETUP_ITEMS_DAMAGES"], () => setUp(), {
    enabled: true,
  });

  const damageStatusOptions = useMemo(() => {
    let options = damageStatus;
    if (selectFrom === "Items") {
      options = damageStatus.filter(
        (el) =>
          el.label !== "Replaced By Vendor" &&
          el.label !== "Credit Note from Vendor"
      );
    }

    return options;
  }, [selectFrom]);

  const formik = useFormik({
    initialValues: {
      FaultID: `FLT${Date.now()}`,
      Barcode: "",
      Item_Name: "",
      Quantity: "",
      Vendor: "",
      PointOfDamage: "Company store",
      Faults: "",
      CompanySentTo: "",
      DamageStatus: "Active",
      CompanyAddress: "",
      EntryDate: moment(),
      DateResolved: moment(),
      // Size: appSettings.isBatchStandard ? "" : "8 mm",
      // Unit: appSettings.isBatchStandard ? "Each" : "Tons",
      Size: "",
      Unit: "",
      Usage: "Sales",

      InvoiceNo: "",
      UnitCost: "",
      amount: "",

      Product_Name: "",
      Cat_Name: "",
      Branch: "HQ",
      quantityInStock: "",
      Item_Desc: "",
      Warehouse: "",
      PaymentType: "",
      From: "",
      scrapAmount: "",
      QuantityclosedOut: 0,
      PurchaseQuantity: 0,
      PurchaseID: "",
    },
    validationSchema: yup.object().shape({
      Barcode: yup.string().required("Item Code is required"),
      Item_Name: yup.string().required("Item Name is required"),
      Quantity: yup.string().required(),
      FaultID: yup.string().required(),
      // InvoiceNo: yup.string().required(),
    }),
    onSubmit: async (values) => {
      if (isMulti) {
        // Check if we have enough
        const realQuantity =
          values.Unit === "Tons"
            ? tonsToPcs(values.Quantity, values?.Item_Desc, itemMeasurements)
            : values.Quantity;

        // console.log(realQuantity, values.quantityInStock);
        // if (Number(realQuantity) > Number(values?.quantityInStock)) {
        //   return formik.setFieldError(
        //     "Quantity",
        //     `Not enough item in stock for ${values.Unit}`
        //   );
        // }

        if (selectFrom === "Purchase History") {
          const remaining =
            Number(values.PurchaseQuantity) - Number(values.QuantityclosedOut);
          if (Number(realQuantity) > Number(remaining)) {
            return formik.setFieldError(
              "Quantity",
              `Quantity remaining in purchase is  ${remaining}`
            );
          }
        } else {
          if (Number(realQuantity) > Number(values.quantityInStock)) {
            return formik.setFieldError(
              "Quantity",
              `Quantity remaining in purchase is  ${values.quantityInStock}`
            );
          }
        }

        if (values?.InvoiceNo === "" && selectFrom === "Purchase History") {
          return formik.setFieldError("InvoiceNo", `InvoiceNo is required`);
        }

        if (values?.DamageStatus === "Scrap") {
          if (values?.PaymentType === "") {
            return formik.setFieldError("PaymentType", `Select Payment Type`);
          }
          if (values?.From === "") {
            return formik.setFieldError("From", `Select From Account`);
          }
          if (values?.scrapAmount === "") {
            return formik.setFieldError("scrapAmount", `Enter scrap Account`);
          }
        }

        const foundIndex = tableData.findIndex(
          (el) => el.FaultID === values.FaultID
        );

        if (foundIndex !== -1) {
          tableData[foundIndex] = values;
          setTableData([...tableData]);
        } else {
          setTableData([...tableData, values]);
        }

        formik.resetForm();
        // formik.setFieldValue("InvoiceNo", values.InvoiceNo);
        //formik.setFieldValue("Vendor", values.Vendor);
        formik.setFieldValue("FaultID", `FLT${Date.now()}`);
        barcodeInputRef.current.focus();
        return;
      }
    },
    onReset: () => {
      // handleSelectedItem(null);
    },
  });

  const recordDamage = async (payload) => {
    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    let response = await fetch(`${backendUrl}/api/warehouse/record-damage`, {
      method: "POST",
      credentials: "include",
      body: formData,
      headers: {
        /*  Accept: "Application/json",
        "Content-Type": "Application/json", */
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const recordDamageMutation = useMutation((payload) => recordDamage(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      formik.resetForm();
      if (refetch) refetch();
      setShowDamageItemModal(false);
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const handleSelectedItem = (item, purchase) => {
    // console.log(purchase, item);

    if (wares?.cater === "Assets" && item.accountName === null) {
      return toast.error("Please edit this asset and add account", {
        position: "top-right",
      });
    }
    const vendor = data.vendors.find(
      (el) => el.Vendor_ID === purchase?.Vendor_ID
    );
    formik.resetForm();
    formik.setValues({
      ...formik.values,
      Item_Type: item.Item_Type,
      Barcode: item.Bar_Code,
      Item_Name: item.Item_Name,
      Size: item.Item_Desc,
      Unit: item.Product_Model,
      Quantity: "",
      Date_Log: moment(new Date(item.Date_Log)),
      Product_Name: item.Product_Name,
      Cat_Name: item.Cat_Name,
      Branch: item.Branch,
      Warehouse: wares.whID,
      QuantityclosedOut: purchase?.QuantityclosedOut
        ? purchase?.QuantityclosedOut
        : 0,
      PurchaseQuantity: purchase?.Quantity ? purchase?.Quantity : 0,
      PurchaseID: purchase?.PurchaseID ? purchase?.PurchaseID : "",

      // ----------
      quantityInStock: item.quantityInStock
        ? item.quantityInStock
        : item.Quantity,
      Item_Desc: item.Item_Desc,

      // -----------
      UnitCost: item.UnitCost,
      InvoiceNo: purchase?.InvoiceNo ? purchase?.InvoiceNo : "",
      Vendor: purchase?.Vendor_ID ? purchase?.Vendor_ID : "",
      CompanySentTo: vendor?.CompanyName ? vendor?.CompanyName : "",
      CompanyAddress: vendor?.Address ? vendor?.Address : "",
    });
    scrollToElement('input[name="Quantity"]', { scroll: false });
    setShowItemsPopover(false);
  };

  const handleSelectedItemToEdit = (item) => {
    formik.resetForm();
    formik.setValues({
      ...formik.values,
      ...item,
    });
    scrollToElement('input[name="Quantity"]', { scroll: false });
  };

  const handleRemoveItem = (index) => {
    setTableData([...tableData.filter((el, i) => i !== index)]);
  };

  const saveMulti = async () => {
    if (
      await ConfirmDialog({
        title: "Send Damage(s)",
        description: "Are you sure you want to send this Damage(s)",
      })
    ) {
      console.log(tableData);
      recordDamageMutation.mutate({
        isMulti: true,
        stockItems: tableData,
      });
    }
  };

  const calculateAmount = (Quantity, UnitCost) => {
    formik.setFieldValue(
      "amount",
      currency(Quantity, {
        symbol: "",
        separator: "",
      })
        .multiply(UnitCost)
        .format()
    );
  };

  const handleSelectedPurchaseHistory = (purchase) => {
    // console.log(purchase);
    if (!purchase.item) {
      return toast.error("Item for this Purchase was not found");
    }
    setShowPurchaseHistoryPopover(false);
    handleSelectedItem(purchase.item, purchase);
  };

  const handlePaymentMethod = (value) => {
    formik.setFieldValue("PaymentType", value);
    formik.setFieldValue("From", "");
  };

  const resolveFromOptions = (PaymentType) => {
    // console.log(PaymentType, data.banks);
    return PaymentType === "Imprest"
      ? data.imprest
      : ["Cheque", "Direct Bank Transfer", "Credit/Debit Card"].includes(
          PaymentType
        )
      ? data.banks
      : cashOptions;
  };

  return (
    <Modal
      show={showDamageItemModal}
      onHide={() => setShowDamageItemModal(false)}
      dialogClassName={`new-entity-modal ${isMulti ? "multi-update" : ""}`}
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
      fullscreen={styleHelper.isMobile}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>
            Enter Damage Item Information{" "}
            <CachedIcon
              onClick={() => formik.resetForm()}
              className="text-primary"
            />{" "}
          </h1>
          <p>Add new item by filling in the following forms.</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`px-0 ${isMulti ? "d-md-flex pb-1" : ""}`}>
        <Form
          noValidate
          onSubmit={formik.handleSubmit}
          className={`row new-item-form w-100 m-0 ${
            isMulti ? "flex-column border rounded mx-4" : ""
          } `}
          autoComplete="off"
        >
          <div className={`${isMulti ? "w-auto pt-4" : "col-md-6 px-4"}`}>
            {wares?.cater === "Assets" && (
              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Select From</Form.Label>

                <select
                  value={selectFrom}
                  className="form-select"
                  onChange={(e) => {
                    formik.resetForm();
                    setSelectFrom(e.target.value);
                  }}
                >
                  <option value="Items">Items</option>
                  <option value="Purchase History">Purchase History</option>
                </select>
              </Form.Group>
            )}

            {selectFrom === "Purchase History" ? (
              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Invoice No</Form.Label>
                <InputGroup>
                  <Form.Control
                    className=""
                    type="text"
                    placeholder="Enter Invoice No"
                    name="InvoiceNo"
                    value={formik.values.InvoiceNo}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.InvoiceNo && !!formik.errors.InvoiceNo
                    }
                    ref={barcodeInputRef}
                  />

                  <Popover
                    isOpen={showPurchaseHistoryPopover}
                    onClickOutside={() => setShowPurchaseHistoryPopover(false)}
                    content={() => (
                      <PurchaseHistorySelector
                        handleSelectedPurchaseHistory={
                          handleSelectedPurchaseHistory
                        }
                        wares={wares}
                      />
                    )}
                    position="bottom"
                  >
                    <InputGroup.Text
                      onClick={() =>
                        setShowPurchaseHistoryPopover(
                          !showPurchaseHistoryPopover
                        )
                      }
                    >
                      <MagnifyIcon />
                    </InputGroup.Text>
                  </Popover>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.InvoiceNo}
                </Form.Control.Feedback>
              </Form.Group>
            ) : (
              <Form.Group className="mb-2-5">
                <Form.Label>Item Code</Form.Label>

                <InputGroup>
                  <Form.Control
                    name="barcode"
                    placeholder="Enter Item Code"
                    value={formik.values.Barcode}
                    onChange={formik.handleChange}
                    isInvalid={
                      formik.touched.Barcode && !!formik.errors.Barcode
                    }
                    ref={barcodeInputRef}
                  />

                  <Popover
                    isOpen={showItemsPopover}
                    onClickOutside={() => setShowItemsPopover(false)}
                    content={() => (
                      <WarehouseItemTable
                        handleSelectedItem={handleSelectedItem}
                      />
                    )}
                    position="bottom"
                  >
                    <InputGroup.Text
                      onClick={() => setShowItemsPopover(!showItemsPopover)}
                    >
                      <MagnifyIcon />
                    </InputGroup.Text>
                  </Popover>
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Barcode}
                </Form.Control.Feedback>
              </Form.Group>
            )}

            {selectFrom === "Purchase History" && (
              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Item Code</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder="Enter Item Code"
                  name="Barcode"
                  value={formik.values.Barcode}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.Barcode && !!formik.errors.Barcode}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Barcode}
                </Form.Control.Feedback>
              </Form.Group>
            )}

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Item Name</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Enter Item Name"
                name="Item_Name"
                value={formik.values.Item_Name}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.Item_Name && !!formik.errors.Item_Name
                }
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Item_Name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Fault ID</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Enter FaultID"
                name="FaultID"
                value={formik.values.FaultID}
                onChange={formik.handleChange}
                isInvalid={formik.touched.FaultID && !!formik.errors.FaultID}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.FaultID}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 pb-2">
              <Form.Label className="mb-1">Unit</Form.Label>
              <Select
                classNamePrefix={`form-select`}
                options={Units}
                value={Units.find((el) => el.value === formik.values.Unit)}
                onChange={({ value }) => formik.setFieldValue("Unit", value)}
              />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            <Form.Group className="mb-3 pb-2">
              <Form.Label>Quantity ({formik.values.Unit})</Form.Label>
              <NumberCustomInput
                placeholder="0"
                name="Quantity"
                value={formik.values.Quantity}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                  calculateAmount(value, formik.values.UnitCost);
                }}
                isInvalid={formik.touched.Quantity && !!formik.errors.Quantity}
                onBlur={() => formik.setFieldTouched("Quantity", true)}
              />
              {formik.touched.Quantity && !!formik.errors.Quantity ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.Quantity}
                </span>
              ) : null}
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Quantity In Stock</Form.Label>
              <Form.Control
                placeholder="Quantity In Stock"
                value={
                  formik.values?.quantityInStock
                    ? qtyFormatToString(
                        qtyFormat(
                          formik.values?.quantityInStock,
                          formik.values?.Item_Desc,
                          itemMeasurements
                        )
                      )
                    : "..."
                }
                readOnly
              />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1"> Cost Price</Form.Label>
              <CurrencyCustomInput
                name="UnitCost"
                placeholder="0.00"
                value={formik.values.UnitCost}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                  calculateAmount(value, formik.values.Quantity);
                }}
                isInvalid={formik.touched.UnitCost && !!formik.errors.UnitCost}
                onBlur={() => formik.setFieldTouched("UnitCost", true)}
              />
              {formik.touched.UnitCost && !!formik.errors.UnitCost ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.UnitCost}
                </span>
              ) : null}
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Point of Damage</Form.Label>
              <Select
                classNamePrefix={"form-select"}
                options={pointsOfDamages}
                value={pointsOfDamages?.find(
                  (el) => el.value === formik.values.PointOfDamage
                )}
                onChange={({ value }) =>
                  formik.setFieldValue("PointOfDamage", value)
                }
                isSearchable={false}
              />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Reasons for Return</Form.Label>
              <Form.Control
                as="textarea"
                name="Faults"
                placeholder="Enter Reasons for Return"
                value={formik.values.Faults}
                onChange={formik.handleChange}
              />
            </Form.Group>

            <hr />

            <Row>
              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">Return To</Form.Label>
                <Select
                  classNamePrefix={`form-select`}
                  options={data.vendors}
                  value={data?.vendors?.find(
                    (el) => el.value === formik.values.CompanySentTo
                  )}
                  onChange={({ value }) =>
                    formik.setFieldValue("CompanySentTo", value)
                  }
                  isSearchable={false}
                />
                {formik.touched.CompanySentTo && formik.errors.CompanySentTo ? (
                  <span className="text-danger mt-2">
                    {formik.errors.CompanySentTo}
                  </span>
                ) : null}
              </Form.Group>
              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">Tran Date</Form.Label>
                <DateTime
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  dateFormat="MMM DD, YYYY"
                  inputProps={{
                    className: `date-input form-control`,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.EntryDate}
                  onChange={(date) => {
                    formik.setFieldValue("EntryDate", date, true);
                  }}
                />
              </Form.Group>
            </Row>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1"> Amount</Form.Label>
              <CurrencyCustomInput
                name="amount"
                placeholder="0.00"
                value={formik.values.amount}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={formik.touched.amount && !!formik.errors.amount}
                onBlur={() => formik.setFieldTouched("amount", true)}
              />
              {formik.touched.amount && !!formik.errors.amount ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.amount}
                </span>
              ) : null}
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Damage / Return Status</Form.Label>
              <Select
                classNamePrefix={"form-select"}
                options={damageStatusOptions}
                value={damageStatusOptions.find(
                  (el) => el.value === formik.values.DamageStatus
                )}
                onChange={({ value }) =>
                  formik.setFieldValue("DamageStatus", value)
                }
                isSearchable={false}
              />
            </Form.Group>
            {formik.values?.DamageStatus === "Scrap" && (
              <>
                <Row className="form-mb">
                  <Form.Group as={Col}>
                    <Form.Label>By</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      menuPlacement="top"
                      placeholder="Choose method"
                      isSearchable={false}
                      value={paymentMethod.find(
                        (el) => el.value === formik.values.PaymentType
                      )}
                      options={paymentMethod}
                      onChange={({ value }) => handlePaymentMethod(value)}
                      getOptionLabel={(el) => (
                        <div className="label-with-icon d-flex gap-2 align-items-center">
                          <span>{el.icon}</span>{" "}
                          <span className="fw-5">{el.label}</span>
                        </div>
                      )}
                      //  menuIsOpen={true}
                    />
                    {formik.touched.PaymentType &&
                    !!formik.errors.PaymentType ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.PaymentType}
                      </span>
                    ) : null}
                  </Form.Group>

                  <Form.Group as={Col}>
                    <Form.Label>From</Form.Label>
                    <Select
                      classNamePrefix="form-select"
                      value={resolveFromOptions(formik.values.PaymentType).find(
                        (el) => el.value === formik.values.From
                      )}
                      options={resolveFromOptions(formik.values.PaymentType)}
                      onChange={({ value }) =>
                        formik.setFieldValue("From", value)
                      }
                      key={formik.values?.PaymentType}
                    />
                    {formik.touched.From && !!formik.errors.From ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.From}
                      </span>
                    ) : null}
                  </Form.Group>
                </Row>
                <Form.Group className=" mb-3 pb-2">
                  <Form.Label className="mb-1">Scrap Amount</Form.Label>
                  <CurrencyCustomInput
                    name="scrapAmount"
                    placeholder="0.00"
                    value={formik.values.scrapAmount}
                    onValueChange={(value, name) => {
                      formik.setFieldValue(name, value);
                    }}
                    isInvalid={
                      formik.touched.scrapAmount && !!formik.errors.scrapAmount
                    }
                    onBlur={() => formik.setFieldTouched("scrapAmount", true)}
                  />
                  {formik.touched.scrapAmount && !!formik.errors.scrapAmount ? (
                    <span className="custom-invalid-feedback">
                      {formik.errors.scrapAmount}
                    </span>
                  ) : null}
                  <Form.Control.Feedback type="invalid" />
                </Form.Group>
              </>
            )}
          </div>

          {isMulti && (
            <div className="d-flex justify-content-end py-3 add-to-cart-holder">
              <Button
                variant="outline-primary"
                type="submit"
                className="action-btn"
              >
                Add To Cart
              </Button>
            </div>
          )}
        </Form>

        {isMulti && (
          <div className="item-cart-area px-4">
            {!isEmpty(tableData) ? (
              <>
                {" "}
                <h2 className="mb-4">Item Cart</h2>
                <Table
                  responsive
                  borderless
                  striped
                  className="product-table text-nowrap"
                >
                  <thead>
                    <tr>
                      <th />
                      <th>Fault ID</th>
                      <th>Item Code</th>
                      <th>Item Name</th>
                      <th>Quantity</th>
                      <th>Vendor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((el, index) => (
                      <tr key={index} className="p-cursor">
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant=""
                              className="bg-white border-0"
                              bsPrefix="print more"
                            >
                              <DotsVerticalIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              popperConfig={{
                                strategy: "fixed",
                              }}
                              renderOnMount
                              className="dropdown-with-icons"
                            >
                              <Dropdown.Item
                                as="button"
                                onClick={() => handleSelectedItemToEdit(el)}
                              >
                                <EditIcon />
                                Edit Item
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={() => handleRemoveItem(index)}
                              >
                                <DeleteIcon />
                                Remove Item
                              </Dropdown.Item>
                            </Dropdown.Menu>{" "}
                          </Dropdown>
                        </td>
                        <td>{el.FaultID}</td>
                        <td>{el.Barcode}</td>
                        <td>{el.Item_Name}</td>
                        <td>
                          {currency(el.Quantity, { symbol: "" }).format()}{" "}
                          {["Tons", "Pieces"].includes(el.Unit) ? el.Unit : ""}
                        </td>
                        <td>{el.CompanySentTo}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-between save-btn-holder mt-4">
                  <div className="gridChild gridChildBorderLeftBlue">
                    <div className="gridChildLeft">
                      <p className="gridChld1">
                        {currency(
                          tableData
                            .map((el) => el.amount)
                            .reduce(
                              (a, b) =>
                                currency(a, {
                                  precision: 2,
                                }).add(b),
                              0
                            ),
                          {
                            symbol: "",
                          }
                        ).format()}
                      </p>
                      <p className="gridChld2">Total</p>
                    </div>
                  </div>
                  <Button
                    disabled={recordDamageMutation.isLoading}
                    className="action-btn save-btn"
                    onClick={() => saveMulti()}
                  >
                    Post
                  </Button>
                </div>
              </>
            ) : (
              <div className="no-item my-5 py-4">
                <div className="info">
                  <NoSelectedItemIcon />
                  <h2 className="mb-1">No Item Selected</h2>
                  <p>
                    Please select an item and fill in the <br />
                    pricing details.
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </Modal.Body>

      <ModalLoader show={recordDamageMutation.isLoading} />
    </Modal>
  );
}
