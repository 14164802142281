import currency from "currency.js";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Table } from "react-bootstrap";
import { useQuery } from "react-query";
// import { services } from "../../config";
import { useAuth } from "../../hooks/useAuth";
import useDebounce from "../../utils/hooks";
import { queryActions } from "../../utils/reactQueryActions";
import "./../../assets/scss/scoped/items-table.scoped.scss";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import { paginationOptions, fetchActionsUtil } from "../../utils/helpers";
import { isEmpty } from "lodash";
import Select from "react-select";

export default function WarehouseItemTable({
  handleSelectedItem,
  hideItemsNotInStock = false,
  registerAsset,
  serialNumbers,
  useType,
}) {
  const { backendUrl } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 100,
    q: "",
    isRemoved: "Not Sold",
  });

  const debouncedqueryParams = useDebounce(queryParams, 500);

  const [itemsOptions] = useState([
    {
      label: "Assets Inventory",
      value: "Assets",
    },
    {
      label: "Stock Inventory",
      value: "Stock",
    },
  ]);

  const [changesType, setChangesType] = useState("Assets");

  const wareshouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );

  const fetchStockItems = async (queryParams, ware) => {
    // await waitFor(5000);
    const data = await fetchActionsUtil(
      `${backendUrl}/api/warehouse/items/${ware}?&${queryString.stringify(
        queryParams
      )}`,
      "GET"
    );
    return data?.data;
  };

  const fetchAssets = async (queryParams, ware) => {
    // await waitFor(5000);
    let data = null;
    if (registerAsset) {
      data = await fetchActionsUtil(
        `${backendUrl}/api/warehouse/fetch/all-assets?&${queryString.stringify(
          queryParams
        )}`,
        "GET"
      );
    } else {
      data = await fetchActionsUtil(
        `${backendUrl}/api/warehouse/assets/${ware}?&${queryString.stringify(
          queryParams
        )}`,
        "GET"
      );
    }

    return data?.data;
  };

  const fetchChangeType = async (queryParams) => {
    // await waitFor(5000);

    let data = null;
    if (changesType === "Assets") {
      data = await fetchActionsUtil(
        `${backendUrl}/api/warehouse/fetch/all-assets?&${queryString.stringify(
          queryParams
        )}&nonGroup=${true}`,
        "GET"
      );
    } else if (changesType === "Stock") {
      data = await fetchActionsUtil(
        `${backendUrl}/api/warehouse/batches-fetch-all?&${queryString.stringify(
          queryParams
        )}&warehouseType=non-stock`,
        "GET"
      );
    }

    return data?.data;
  };

  const { data = { warehouses: [] } } = useQuery(
    [
      "queryActions.GET_ITEMS_WAREHOUSE",
      { debouncedqueryParams, wareshouseLocal, changesType },
    ],
    () =>
      serialNumbers
        ? fetchActionsUtil(
            `${backendUrl}/api/warehouse/all/serial-item?&${queryString.stringify(
              queryParams
            )}&Warehouse=${wareshouseLocal?.whID}`
          )
        : useType
        ? fetchChangeType(queryParams)
        : wareshouseLocal?.cater === "Assets" ||
          wareshouseLocal?.cater === "non-stock"
        ? fetchAssets(queryParams, wareshouseLocal?.whID)
        : registerAsset
        ? fetchAssets(queryParams, wareshouseLocal?.whID)
        : fetchStockItems(queryParams, wareshouseLocal?.whID),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  // console.log(data);
  return (
    <div className="items-table-area rounded">
      <div className="search-area m-3 gap-3">
        <MagnifyIcon />
        <input
          className="form-control search-input"
          name="q"
          value={queryParams.q}
          onChange={(e) =>
            setQueryParams({
              ...queryParams,
              page: 1,
              [e.target.name]: e.target.value,
            })
          }
          placeholder="Search item..."
          autoFocus
          autoComplete="off"
          type={"text"}
        />

        {useType && (
          <div className="w-50">
            <Select
              placeholder="Select..."
              options={itemsOptions}
              value={itemsOptions.find((el) => el.value === changesType)}
              onChange={(selection) => setChangesType(selection?.value)}
            />
          </div>
        )}
      </div>

      <div className="content">
        {serialNumbers ? (
          <Table borderless hover striped className="product-table">
            <thead className="sticky text-nowrap">
              <tr>
                <th scope="col">Serial Number</th>
                <th scope="col">Item Code</th>
                <th scope="col">Item Name</th>
                <th scope="col">Unit Cost</th>
                {/* <th scope="col">Unit Price</th> */}
              </tr>
            </thead>
            <tbody>
              {data?.stockCounts &&
                data?.stockCounts
                  .filter((el) =>
                    hideItemsNotInStock
                      ? Number(el?.Items_Serial_WH?.Quantity) > 0
                      : el
                  )
                  .map((el, index) => (
                    <tr
                      className="p-cursor"
                      key={index}
                      onClick={() =>
                        handleSelectedItem && handleSelectedItem(el)
                      }
                    >
                      <td>{el?.serialNum}</td>
                      <td>{el?.Items_Serial_WH?.Bar_Code}</td>
                      <td title={el?.Items_Serial_WH?.Quantity}>
                        {el?.Items_Serial_WH?.Item_Name}
                      </td>
                      <td>
                        {currency(el?.Batch_Serial_WH?.UnitCost, {
                          precision: 2,
                          symbol: "",
                        }).format()}
                      </td>
                      {/* <td>
                        {currency(el?.Items_Serial_WH?.UnitPrice, {
                          precision: 2,
                          symbol: "",
                        }).format()}
                      </td> */}
                    </tr>
                  ))}
            </tbody>
          </Table>
        ) : (
          <Table borderless hover striped className="product-table">
            <thead className="sticky text-nowrap">
              <tr>
                <th scope="col">Item Code</th>
                <th scope="col">Item Name</th>
                {/* <th scope="col">Unit Cost</th> */}
                {!registerAsset && <th scope="col">Cost Price</th>}
              </tr>
            </thead>
            <tbody>
              {data.warehouses &&
                data.warehouses

                  .filter((el) =>
                    hideItemsNotInStock ? Number(el.Quantity) > 0 : el
                  )
                  .map((el, index) => (
                    <tr
                      className="p-cursor"
                      key={index}
                      onClick={() =>
                        handleSelectedItem && handleSelectedItem(el)
                      }
                    >
                      <td>{el.Bar_Code}</td>
                      {/* <td title={el?.Quantity}>{el.Item_Name}</td> */}
                      <td>{el.Item_Name ? el.Item_Name : el?.ItemName}</td>
                      {/* <td>
                      {currency(el.UnitCost, {
                        precision: 2,
                        symbol: "",
                      }).format()}
                    </td> */}
                      {!registerAsset && (
                        <td>
                          {currency(el.UnitCost, {
                            precision: 2,
                            symbol: "",
                          }).format()}
                        </td>
                      )}
                    </tr>
                  ))}
            </tbody>
          </Table>
        )}
      </div>

      <div className="d-flex justify-content-between px-3 my-3 align-items-center pagination">
        <p className="m-0">
          Showing {data?.startIndex + 1} to{" "}
          {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
          {data.count} entries
        </p>

        <ReactPaginate
          {...paginationOptions}
          pageCount={Math.ceil(data.count / queryParams.limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={0}
          onPageChange={({ selected }) => {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
            setQueryParams({
              ...queryParams,
              page: selected + 1,
            });
          }}
          forcePage={queryParams.page - 1}
        />
      </div>
    </div>
  );
}
