import { useFormik } from "formik";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ExpensesSelector from "../utils/ExpensesSelector";
import { Popover } from "react-tiny-popover";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import * as yup from "yup";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { useAuth } from "../../hooks/useAuth";
import DateTime from "react-datetime";
import ConfirmDialog from "../ConfirmDialogue";
import { formatDate } from "../../utils/helpers";
import { useCurrencies } from "../../utils/hooks";

export function VendorOpeningBalance({
  show = true,
  setShow,
  vendor,
  refetch,
}) {
  const { backendUrl } = useAuth();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { deploymentCurrencies: currenciesOptions } = useCurrencies();

  const saveOpeningAmountApi = async (payload) => {
    let response = await fetch(
      `${backendUrl}/api/vendors/save-opening-amount`,
      {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(payload),
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const saveOpeningAmountApiMutation = useMutation(
    (payload) => saveOpeningAmountApi(payload),
    {
      onSuccess: ({ message, data }) => {
        if (refetch) refetch();
        toast.success(message);
        setShow(false);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      openingAccountID: "300",
      openingAccountDescription: "Opening Balance Equity",
      openingAccountType: "OWNER'S EQUITY",
      openingAccountAmount: 0,
      Date_Log: new Date(),
    },
    validationSchema: yup.object().shape({
      openingAccountID: yup.string().required("required"),
      openingAccountDescription: yup.string().required("required"),
    }),
    onSubmit: async (values) => {
      if (
        await ConfirmDialog({
          title: `Post Opening Balance for ${vendor?.CompanyName}`,
          description: `Are you sure?`,
          summary: {
            "Account to Debit": "Accounts Payable",
            "Account to Credit": `${values.openingAccountID} / ${
              values.openingAccountDescription
            } / ${values.openingAccountType}`,
            Amount: values.openingAccountAmount,
            "Post Date": `${formatDate(values?.Date_Log)}`,
          },
        })
      )
        saveOpeningAmountApiMutation.mutate({
          vendor: {
            ...vendor,
            ...values,
          },
        });
    },
  });

  // GA ===============================================================
  const [showJournalPopover, setShowJournalPopover] = useState(false);
  const handleSelectedAccount = (account) => {
    // console.log(account);
    formik.setFieldValue("openingAccountID", account?.AccountID);
    formik.setFieldValue("openingAccountDescription", account?.Description);
    formik.setFieldValue("openingAccountType", account?.Type);
    setShowJournalPopover(false);
  };
  // ===================================================================

  return (
    <>
      <Modal show={show} onHide={handleClose} centered enforceFocus={false}>
        <Modal.Header closeButton>
          <Modal.Title className="h5">Vendor Opening Balance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate onSubmit={formik.handleSubmit}>
            {/*  <p>{vendor?.Vendor_ID}</p> */}
            <p>
              <b> {vendor?.CompanyName}</b>
            </p>
            <p>
              {vendor?.Email} {vendor?.Phone}
            </p>
            <hr />
            <Form.Group className="mb-3">
              <Form.Label>Account to Debit</Form.Label>
              <InputGroup
                hasValidation
                isInvalid={
                  formik.touched.openingAccountID &&
                  !!formik.errors.openingAccountID
                }
              >
                <Form.Control
                  placeholder="Select Account"
                  value={`${formik.values?.openingAccountID || ""} / ${formik
                    .values?.openingAccountDescription || ""} / ${formik.values
                    ?.openingAccountType || ""}`}
                  onChange={() => {}}
                  readOnly
                  isInvalid={
                    formik.touched.openingAccountID &&
                    !!formik.errors.openingAccountID
                  }
                />

                <Popover
                  isOpen={showJournalPopover}
                  onClickOutside={() => setShowJournalPopover(false)}
                  content={() => (
                    <ExpensesSelector
                      handleSelectedExpense={handleSelectedAccount}
                      usage={"chart of accounts"}
                      onHide={() => setShowJournalPopover(false)}
                      Type="OWNER'S EQUITY"
                    />
                  )}
                  position="bottom"
                >
                  <InputGroup.Text onClick={() => setShowJournalPopover(true)}>
                    <MagnifyIcon />
                  </InputGroup.Text>
                </Popover>
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Opening Account Amount</Form.Label>
              <CurrencyCustomInput
                name="openingAccountAmount"
                value={formik.values.openingAccountAmount}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                placeholder="0.00"
              />
            </Form.Group>
            <Form.Group className="col-6 mb-1 pb-2">
              <Form.Label className="mb-1">Post Date</Form.Label>
              <DateTime
                timeFormat={false}
                closeOnSelect={true}
                closeOnClickOutside={true}
                dateFormat="MMM DD, YYYY"
                inputProps={{
                  className: `date-input form-control`,
                  placeholder: "Select date",
                  readOnly: true,
                }}
                value={formik.values.Date_Log}
                onChange={(date) => {
                  formik.setFieldValue("Date_Log", date, true);
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            className="px-4"
            onClick={() => formik.submitForm()}
            disabled={saveOpeningAmountApiMutation.isLoading}
          >
            {saveOpeningAmountApiMutation.isLoading ? "Please wait..." : "Post"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
