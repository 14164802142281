import { Dropdown, Table, Form, Button, InputGroup } from "react-bootstrap";
import { CSVLink } from "react-csv";
import DetailedIcon, {
  ExportIcon,
  FilterIcon,
  FilterTwoIcon,
  BusinessBillIcon,
  PrintIcon,
  SummaryChartIconUp,
  SummaryChartIconDown,
  SummaryCloseIcon,
  FilterCollapseIcon,
  MessageIcon,
  ExcelIcon,
  PDFIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import Select from "react-select";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { services } from "../../config";
import { reportActions } from "../../utils/reactQueryActions";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import ReactPaginate from "react-paginate";
import { customerFullName, paginationOptions } from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import queryString from "query-string";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { Popover } from "react-tiny-popover";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import CustomerSelector from "../utils/CustomerSelector";
import NoTableItem from "../utils/NoTableItem";
import { isEmpty, lowerCase } from "lodash";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import { IsPrivileged } from "../DisplayChildElement";

function AccountPayables() {
  useScrollTop();
  const reportTypeOptions = [
    {
      value: "Summary",
      label: "Summary",
    },
    {
      value: "Detailed",
      label: "Detailed",
    },
  ];
  const initialFilterParams = {
    customerLastname: "",
    customerFirstname: "",
  };

  const { backendUrl } = useAuth();
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);
  const [btnSummryActive, setBtnSummaryActive] = useState(false);
  const [showMoreSummary, setShowMoreSummary] = useState(true);
  const [excelData, setExcelData] = useState([]);
  const [searchText, setSearchText] = useState();

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const close = () => {
    setShowMoreSummary(true);
    setBtnSummaryActive(false);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchPayables = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/accounts-payables?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    return data;
  };

  const {
    data = {
      count: 0,
      recievables: [],
      vendorSummary: [],
    },
    refetch,
    isSuccess,
    isFetching,
  } = useQuery(
    [reportActions.ACCOUNT_PAYABLES, queryParams],
    () => fetchPayables(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // download excel function
  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/reports/accounts-payables?${queryString.stringify(
        rest
      )}`,
      "GET"
    );

    const company = exData.data?.company?.CompName;

    const recievables = exData.data.recievables.map((d, i) => [
      data?.startIndex + i + 1,
      d.Cust_ID,
      d.Customer,
      d.PhoneNo1,
      currency(d.TotalDebt, {
        symbol: "",
        precision: 2,
      }).format(),
    ]);

    const vendorSummary = exData.data.vendorSummary.map((d, i) => [
      d["S/N"],
      d.Vendor_ID,
      d.Vendors,
      d.Phone,
      currency(d.TotalDebt, {
        symbol: "",
        precision: 2,
      }).format(),
    ]);

    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";

    exData = [
      [company],
      ["Account Payables Report"],
      [date],
      [""],
      ["S/N", "Cust ID", "Customer", "Phone No", "Total Debt"],
      ...recievables,
      [""],
      ["Summary"],
      [
        "Total Amount",
        currency(data.totalDebt, {
          symbol: "",
        }).format(),
      ],
      [""],
      [""],
      [""],
      ["Vendor's Details"],
      ["S/N", "Vendor ID", "Vendors", "Phone", "Total Amount"],
      ...vendorSummary,
      [""],
      ["Summary"],
      [
        "Total Amount",
        currency(data.totalDebtVendor, {
          symbol: "",
        }).format(),
      ],
    ];
    console.log(exData);
    setExcelData(exData);
  };

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSelectedCustomer = (customer) => {
    setFilterParams({
      ...filterParams,
      customerLastname: customer.LastName,
      customerFirstname: customer.FirstName,
    });
    setShowItemsPopover(false);
  };
  // isprivileged
  return (
    <IsPrivileged roleName="Accounts Payables">
      <main className="cash-book">
        <div className="content">
          <div className="content-main">
            {/*  <div className={`filter-area ${showFilter ? "open" : "close"}`}>
            <div className="filter-header">
              <h2>
                <FilterTwoIcon /> Filters
              </h2>

              <Button
                variant="white"
                onClick={() => setShowFilter(!showFilter)}
              >
                <FilterCollapseIcon />
              </Button>
            </div>
            <div className="filter-body">
              <Form>
                <div className="body">
                 

                  <Form.Group className="mb-2-5">
                    <Form.Label>Customer Lastname</Form.Label>
                    <InputGroup>
                      <Form.Control
                        name="customerLastname"
                        placeholder="Enter Customer Lastname"
                        value={filterParams?.customerLastname}
                        onChange={(e) => handleFilterParamsChange(e)}
                      />
                      <Popover
                        isOpen={showItemsPopover}
                        onClickOutside={() => setShowItemsPopover(false)}
                        content={() => (
                          <CustomerSelector
                            handleSelectedCustomer={handleSelectedCustomer}
                            setShowCustomerSelector={setShowItemsPopover}
                          />
                        )}
                        position="bottom"
                      >
                        <InputGroup.Text
                          onClick={() => setShowItemsPopover(!showItemsPopover)}
                        >
                          <MagnifyIcon />
                        </InputGroup.Text>
                      </Popover>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-2-5">
                    <Form.Label>Customer First Name</Form.Label>
                    <Form.Control
                      name="customerFirstName"
                      value={filterParams?.customerFirstname}
                      placeholder="Enter Customer firstname"
                      onChange={(e) => handleFilterParamsChange(e)}
                    />
                  </Form.Group>

                  <hr className="mt-3 mb-4" />

                  <Form.Group className="">
                    <Form.Label>Date Range</Form.Label>
                    <div className="position-relative">
                      <RsDateRangePicker
                        disabled
                        placement="auto"
                        value={
                          filterParams.startDate && filterParams.endDate
                            ? [
                                parse(
                                  filterParams.startDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                                parse(
                                  filterParams.endDate,
                                  "yyyy-MM-dd",
                                  new Date()
                                ),
                              ]
                            : []
                        }
                        onClean={() => clearDateRange()}
                        onOk={(date) => filterByDateRange(date)}
                      />
                    </div>
                  </Form.Group>
                </div>

                <div className="buttons rounded">
                  <Button onClick={() => reset()} variant="white">
                    Reset
                  </Button>
                  <Button onClick={() => search()} variant="primary">
                    Search
                  </Button>
                </div>
              </Form>
            </div>
          </div> */}

            <div className="content-body">
              <header>
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  {/*  Creditors Spreadsheet (Customers/Vendors) */}
                  Vendors{" "}
                  <div className="global-search-area  mx-3">
                    <MagnifyIcon />
                    <Form.Control
                      id="queryParams-q"
                      className=""
                      name="searchText"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      placeholder="Search..."
                    />
                  </div>
                  <button
                    title="Refresh"
                    onClick={() => refetch()}
                    className="btn text-primary"
                  >
                    <CachedIcon />
                  </button>
                </h1>

                <div className="actions mr-5">
                  {/*  <button className="btn print">
                  Print <PrintIcon />
                </button>

                <button className="btn print">
                  Export <ExportIcon />
                </button> */}
                  <CSVLink
                    className="btn print d-none"
                    filename={`Account Payables(${format(
                      new Date(),
                      "dd-MMM-yyyy hh:mm:ss a"
                    )}).csv`}
                    data={excelData}
                    ref={CSVLinkRef}
                  />

                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="btn print"
                      disabled={isfetchingExcel}
                      bsPrefix=""
                    >
                      Export
                      <ExportIcon color="#008000" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      popperConfig={{
                        strategy: "fixed",
                      }}
                      renderOnMount
                      className="text-center"
                    >
                      <Dropdown.Item
                        as="div"
                        onClick={onDownloadExcelData}
                        className="p-cursor"
                      >
                        Excel <ExcelIcon color="#008000" />
                      </Dropdown.Item>
                      <Dropdown.Item as="div">
                        <a
                          href={`${backendUrl}/api/reports/pdf/accounts-payables?${queryString.stringify(
                            rest
                          )}`}
                          target="blank"
                        >
                          PDF <PDFIcon color="#ff0000" />
                        </a>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </header>

              {/* <div className="px-md-4">
              <Table
                responsive
                borderless
                hover
                striped
                className="product-table"
              >
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Cust ID</th>
                    <th>Business Name</th>
                    <th>Phone No</th>
                    <th>Total Debt</th>
                  </tr>
                </thead>
                <tbody className="can-hover">
                  {data.recievables.map((el, index) => (
                    <tr
                      className="p-cursor"
                      onClick={() =>
                        navigate(
                          `/reports/customer-ledger?customerId=${
                            el.Cust_ID
                          }&customerName=${el.Customer}&enableDateRange=false`
                        )
                      }
                      key={index}
                    >
                      <td>{data?.startIndex + index + 1}</td>
                      <td>{el.Cust_ID}</td>
                      <td>{el.Customer}</td>
                      <td>{el.PhoneNo1}</td>
                      <td>
                        {currency(el.TotalDebt, {
                          symbol: "",
                          precision: 2,
                        }).format()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {!isFetching && isSuccess && isEmpty(data?.recievables) ? (
                <NoTableItem queryParams={queryParams} />
              ) : null}
            </div> */}

              {/*  <div className="d-flex justify-content-between px-3 align-items-center pagination">
            
              <div className="pagination_left">
                <p className="m-0 p-0">Show</p>
                <select
                  value={queryParams.limit}
                  name="limit"
                  className="form-select "
                  onChange={(e) => handleSearchQueryChange(e)}
                >
                  <option value="10">10 rows</option>
                  <option value="20">20 rows</option>
                  <option value="30">30 rows</option>
                  <option value="40">40 rows</option>
                  <option value="50">50 rows</option>
                  <option value="100">100 rows</option>{" "}
                </select>
              </div>

              <ReactPaginate
                {...paginationOptions}
                pageCount={Math.ceil(data.count / queryParams.limit)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={0}
                onPageChange={({ selected }) => {
                  document.body.scrollTop = document.documentElement.scrollTop = 0;
                  setQueryParams({
                    ...queryParams,
                    page: selected + 1,
                  });
                }}
                forcePage={queryParams.page - 1}
              />
            </div> */}

              {/*  <div
              className={
                btnSummryActive
                  ? `summaryNew border-top open`
                  : `summaryNew border-top`
              }
            >
              <div className="summaryHeader">
                {btnSummryActive && (
                  <button onClick={() => close()} className="btn p-0">
                    <SummaryCloseIcon />
                  </button>
                )}
              </div>

              <div className="gridCont">
                <div className="gridChild gridChildBorderLeftBlue">
                  <div className="gridChildLeft">
                    <p className="gridChld1">
                      {currency(data.totalDebt, {
                        symbol: "",
                      }).format()}
                    </p>
                    <p className="gridChld2">Total Amount</p>
                  </div>

                  <div className="gridChildRight">
                    <SummaryChartIconUp />
                    <p>5%</p>
                  </div>
                </div>
              </div>
            </div> */}

              <div className="px-md-4">
                <Table responsive borderless striped className="product-table">
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Vendor ID</th>
                      <th>Vendors</th>
                      <th>Phone</th>
                      <th>Total Amount</th>
                    </tr>
                  </thead>
                  <tbody className="can-hover">
                    {data.vendorSummary
                      .filter((el) =>
                        searchText
                          ? lowerCase(el.Vendors).includes(
                              lowerCase(searchText)
                            )
                          : true
                      )
                      .map((el, index) => (
                        <tr
                          key={index}
                          className="p-cursor"
                          onClick={() =>
                            navigate(
                              `/vendor/vendors-ledger?customerId=${
                                el.Vendor_ID
                              }&NoDate=${true}&customerName=${el.Vendors}`
                            )
                          }
                        >
                          <td>{el["S/N"]}</td>
                          <td>{el.Vendor_ID}</td>
                          <td>{el.Vendors}</td>
                          <td>{el.Phone}</td>
                          <td>
                            {currency(el["Total Amount"], {
                              symbol: "",
                              precision: 2,
                            }).format()}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {!isFetching && isSuccess && isEmpty(data?.vendorSummary) ? (
                  <NoTableItem queryParams={queryParams} />
                ) : null}

                <div
                  className={
                    btnSummryActive
                      ? `summaryNew border-top open`
                      : `summaryNew border-top`
                  }
                >
                  <div className="summaryHeader">
                    {btnSummryActive && (
                      <button onClick={() => close()} className="btn p-0">
                        <SummaryCloseIcon />
                      </button>
                    )}
                  </div>

                  <div className="gridCont">
                    <div className="gridChild gridChildBorderLeftBlue">
                      <div className="gridChildLeft">
                        <p className="gridChld1">
                          {currency(data.totalDebtVendor, {
                            symbol: "",
                          }).format()}
                        </p>
                        <p className="gridChld2">Total Amount</p>
                      </div>

                      <div className="gridChildRight">
                        <SummaryChartIconUp />
                        <p>5%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalLoader show={isfetchingExcel} />
      </main>
    </IsPrivileged>
  );
}

export default AccountPayables;
