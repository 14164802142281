import React from "react";
import { Modal } from "react-bootstrap";
import TableComponent from "../TableComponent";
import { useAuth } from "../../hooks/useAuth";
import { useQuery } from "react-query";
import { format, parse } from "date-fns";
import NoTableItem from "../utils/NoTableItem";
import { isEmpty } from "lodash";

export default function CompanyBranches({
  showCompanyBranchModal,
  companyID,
  setShowCompanyModal,
  companyName,
}) {
  const { backendUrl, token } = useAuth();
  const fetchCompanyBranches = async () => {
    const res = await fetch(
      `${backendUrl}/api/company/get-company-branches?companyId=${companyID}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      }
    );

    const data = await res.json();

    return data;
  };

  const { data, isFetching, isSuccess } = useQuery(
    ["GET_COMPANY_BRANCH"],
    () => fetchCompanyBranches(),
    {
      enabled: true,
      keepPreviousData: false,
    }
  );

  const tableHead = () => {
    return (
      <thead className="position-sticky top-0">
        <tr>
          <th />
          <th>S/N</th>
          <th>Branch ID</th>

          <th>Name</th>
          <th>Phone</th>

          <th>Date Created</th>
        </tr>
      </thead>
    );
  };

  const tableData = (el, index) => {
    return (
      <>
        <td />
        <td>{index + 1}</td>

        <td>{el?.branchId}</td>
        <td>{el?.name}</td>

        <td>{el?.phone}</td>

        <td>
          {!isNaN(new Date(el?.dateCreated))
            ? format(new Date(el?.dateCreated), "dd MMM, yyyy")
            : "......."}
        </td>
      </>
    );
  };

  return (
    <Modal
      show={showCompanyBranchModal}
      onHide={() => setShowCompanyModal(false)}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title className="h6">{companyName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="m-3">
          <TableComponent
            responsive
            borderless
            striped
            tableHeadsFunction={tableHead}
            mainDataArray={data?.branches || []}
            className="product-table text-nowrap"
            tableDataRowFunction={tableData}
            // tableRowClick={(e, el) => manageTransaction(e, el)}
          />

          {!isFetching && isSuccess && isEmpty(data?.branches) ? (
            <NoTableItem />
          ) : null}
        </div>
      </Modal.Body>
    </Modal>
  );
}
