import { Table, Pagination, Form } from "react-bootstrap";
import CachedIcon from "mdi-react/CachedIcon";
import { useQuery } from "react-query";
import currency from "currency.js";
import { format, parse } from "date-fns";
import { Fragment, useEffect, useRef, useState } from "react";
import queryString from "query-string";
import { CSVLink } from "react-csv";
import { isEmpty } from "lodash";
import { useMemo } from "react";

import { ExcelIcon, PDFIcon } from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import useDebounce, {
  useCurrencies,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import { useAuth } from "../../hooks/useAuth";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import NoTableItem from "../utils/NoTableItem";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { calculateAccounts } from "../../utils/helpers";
import { IsPrivileged } from "../DisplayChildElement";
import Select from "react-select";

function GeneralLedger() {
  useScrollTop();
  const initialFilterParams = {
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
  };

  const { backendUrl } = useAuth();
  const [excelData, setExcelData] = useState([]);
  const {
    deploymentCurrencies: currenciesOptions,
    prevailingCurrency,
    getCurrencySymbol,
  } = useCurrencies();
  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
    currency: "",
    convertCurrency: prevailingCurrency,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams]);

  const fetchCustomerLedgers = async (queryParams) => {
    // await waitFor(5000);
    let response = await fetch(
      `${backendUrl}/api/reports/general-ledger?&${queryString.stringify(
        queryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();

    const types = [...new Set(data.journal.map((d) => d.Description))];
    let allAccouts = {};
    types.forEach((el) => {
      allAccouts[el] = data.journal.filter((d) => d.Description === el);
      allAccouts[el]["key"] = [
        ...new Set(allAccouts[el].map((d) => d.AccountID)),
      ][0];
      allAccouts[el]["EndingBalance"] = data.EndingBalance.filter(function(e) {
        return e.name === el;
      }, types);
    });

    data.allAccouts = Object.entries(allAccouts).map((el) => [
      el[0],
      el[1].sort(
        (e, k) => Number(new Date(e.DateLog)) - Number(new Date(k.DateLog))
      ),
    ]);

    console.log(data.allAccouts);
    return data;
  };

  const {
    data,
    refetch,
    isFetching,
    // isSuccess,
  } = useQuery(
    ["GENERAL_LEDGER", queryParams],
    () => fetchCustomerLedgers(queryParams),
    {
      keepPreviousData: true,
    }
  );

  // download excel function
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/reports/general-ledger?${queryString.stringify(
        queryParams
      )}`,
      "GET"
    );

    const { company, journal } = exData.data;

    const types = [...new Set(journal.map((d) => d.Description))];

    let allAccouts = {};
    types.forEach((el) => {
      allAccouts[el] = data.journal.filter((d) => d.Description === el);
      allAccouts[el]["key"] = [
        ...new Set(allAccouts[el].map((d) => d.AccountID)),
      ][0];
      allAccouts[el]["EndingBalance"] = data.EndingBalance.filter(function(e) {
        return e.name === el;
      }, types);
    });

    allAccouts = Object.entries(allAccouts);

    allAccouts = allAccouts.map((el) => {
      const mainData = el[1].map((d) => [
        format(new Date(d?.DateLog), "dd MMM, yyyy"),
        d["AccountDescription.Type"],
        d.Description,
        d["AccountDescription.AccountID"],
        `${d.Ref}`.split("-")[0],
        `${d.Ref}`.split("-")[1],
        currency(d.Debit, {
          symbol: currencySymbol,
        }).value,
        currency(d.Credit, {
          symbol: currencySymbol,
        }).value,
      ]);

      if (el[1].at(-1)) {
        mainData.push([
          "",
          "",
          "",
          "",
          "",
          "Current Period Change",
          currency(el[1].reduce((p, c) => p + c.Debit, 0), {
            symbol: "",
          }).value,
          currency(el[1].reduce((p, c) => p + c.Credit, 0), {
            symbol: "",
          }).value,
          currency(
            calculateAccounts(
              el[1]["key"],
              el[1].reduce((p, c) => p + c.Credit, 0),
              el[1].reduce((p, c) => p + c.Debit, 0)
            ),
            {
              symbol: "",
            }
          ).value,
        ]);
        mainData.push([
          "",
          "",
          "",
          "",
          "",
          "Ending Balance",
          "",
          "",
          currency(
            calculateAccounts(
              el[1]["key"],
              el[1]["EndingBalance"][0].CreditJounal,
              el[1]["EndingBalance"][0].DebitJounal
            ),
            {
              symbol: "",
            }
          ).value,
        ]);
      }

      return mainData;
    });

    const spreadData = [];

    allAccouts.forEach((e) => {
      e.forEach((d) => {
        spreadData.push(d);
      });
    });

    const date =
      queryParams.startDate && queryParams.endDate
        ? `Date Prepared: Between ${format(
            new Date(queryParams.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(
            new Date(queryParams.endDate),
            "E MMM d yyyy k:mm:ss z"
          )}`
        : "";
    exData = [
      [company[0]?.CompName],
      ["General Ledger Report"],
      [date],
      [""],
      [
        "Date",
        "Account Type",
        "Account Description",
        "Account ID",
        "Reference",
        "Trans Description",
        "Debit",
        "Credit",
        "",
      ],
      ...spreadData,
    ];

    //  console.log(exData);
    setExcelData(exData);
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const currencySymbol = useMemo(() => {
    return getCurrencySymbol(
      filterParams.convertCurrency
        ? filterParams.convertCurrency
        : prevailingCurrency
    );
  }, [filterParams.convertCurrency, prevailingCurrency]);

  // isprivileged
  return (
    <IsPrivileged roleName="Financials">
      <main className="cash-book table-sticky-header">
        <div className="content">
          <header className="container pt-5">
            <h1>
              General Ledger Report
              <button
                title="Refresh"
                onClick={() => refetch()}
                className="btn text-primary"
              >
                <CachedIcon />
              </button>
            </h1>
            <div className="actions gap-3">
              <CSVLink
                className="btn print d-none"
                filename={`General Ledger Report(${format(
                  new Date(),
                  "dd-MMM-yyyy hh:mm:ss a"
                )}).csv`}
                data={excelData}
                ref={CSVLinkRef}
              />

              <Form.Group className="position-relative">
                <Form.Label className="fw-5 position-absolute bottom-100 start-0 m-1">
                  Filter By
                </Form.Label>
                <Select
                  classNamePrefix="form-select"
                  placeholder="All Currencies"
                  isSearchable={true}
                  options={currenciesOptions}
                  value={currenciesOptions.find(
                    (el) => el.value == filterParams.currency
                  )}
                  onChange={(selected) =>
                    setFilterParams({
                      ...filterParams,
                      page: 1,
                      currency: selected?.value,
                    })
                  }
                  isClearable={true}
                />
              </Form.Group>

              <Form.Group className="position-relative">
                <Form.Label className="fw-5 position-absolute bottom-100 start-0 m-1">
                  Report In{" "}
                </Form.Label>
                <Select
                  classNamePrefix="form-select"
                  placeholder="Select Currency"
                  isSearchable={true}
                  options={currenciesOptions}
                  value={currenciesOptions.find(
                    (el) => el.value === filterParams.convertCurrency
                  )}
                  onChange={({ value }) =>
                    setFilterParams({
                      ...filterParams,
                      page: 1,
                      convertCurrency: value,
                    })
                  }
                />
              </Form.Group>

              <div className="position-relative">
                <RsDateRangePicker
                  placement="bottomStart"
                  value={
                    filterParams.startDate && filterParams.endDate
                      ? [
                          parse(
                            filterParams.startDate,
                            "yyyy-MM-dd",
                            new Date()
                          ),
                          parse(filterParams.endDate, "yyyy-MM-dd", new Date()),
                        ]
                      : []
                  }
                  onClean={() => clearDateRange()}
                  onOk={(date) => filterByDateRange(date)}
                />
              </div>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${backendUrl}/api/reports/pdf/general-ledger?${queryString.stringify(
                  queryParams
                )}`}
                className="btn print"
              >
                PDF <PDFIcon color="#ff0000" />
              </a>
              <button className="btn print" onClick={onDownloadExcelData}>
                Excel <ExcelIcon color="#008000" />
              </button>
            </div>
          </header>

          <div>
            <Table
              // responsive
              borderless
              striped
              className="product-table container"
            >
              <thead className="position-sticky top-0">
                <tr>
                  <th>Date</th>
                  <th>Account Type</th>
                  <th>Account Description</th>
                  <th>Account ID</th>
                  <th>Reference</th>
                  <th>Trans Description</th>
                  <th>Debit</th>
                  <th>Credit</th>
                  <th>{"   "}</th>
                </tr>
              </thead>
              <tbody>
                {data?.allAccouts?.map((el, index) => (
                  <Fragment key={index}>
                    {el[1].map((d, i) => (
                      <tr key={i}>
                        <td className="text-nowrap">
                          {format(new Date(d?.DateLog), "dd MMM, yyyy")}
                        </td>
                        <td>{d["AccountDescription.Type"]}</td>
                        <td>{d.Description}</td>
                        <td>{d["AccountDescription.AccountID"]}</td>
                        <td>{`${d.Ref}`.split("-")[0]}</td>
                        <td>{`${d.Ref}`.split("-")[1]}</td>
                        <td>
                          {currency(d.Debit, {
                            symbol: currencySymbol,
                          }).format()}
                        </td>
                        <td>
                          {currency(d.Credit, {
                            symbol: currencySymbol,
                          }).format()}
                        </td>
                        <td />
                      </tr>
                    ))}

                    {el[1].at(-1) && (
                      <tr>
                        <td />
                        <td />
                        <td />
                        <td /> <td />
                        <td className="text-nowarp">Current Period Change</td>
                        <td>
                          {currency(el[1].reduce((p, c) => p + c.Debit, 0), {
                            symbol: currencySymbol,
                          }).format()}
                        </td>
                        <td>
                          {currency(el[1].reduce((p, c) => p + c.Credit, 0), {
                            symbol: currencySymbol,
                          }).format()}
                        </td>
                        <td>
                          {currency(
                            calculateAccounts(
                              el[1]["key"],
                              el[1].reduce((p, c) => p + c.Credit, 0),
                              el[1].reduce((p, c) => p + c.Debit, 0)
                            ),
                            {
                              symbol: currencySymbol,
                            }
                          ).format()}
                        </td>
                      </tr>
                    )}
                    {el[1].at(-1) && (
                      <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td className="fw-bold">Ending Balance</td>
                        <td />
                        <td />
                        <td className="fw-bold">
                          {currency(
                            calculateAccounts(
                              el[1]["key"],
                              el[1]["EndingBalance"][0].CreditJounal,
                              el[1]["EndingBalance"][0].DebitJounal
                            ),
                            {
                              symbol: currencySymbol,
                            }
                          ).format()}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                ))}
              </tbody>
            </Table>
          </div>
          {!isFetching && isEmpty(data?.allAccouts) ? (
            <NoTableItem queryParams={queryParams} />
          ) : null}
          <div className="pagination">
            {/* Left */}
            {/* <div className="pagination_left">
            <p className="m-0 p-0">Show</p>
            <select name="rows" className="form-select ">
              <option value="10">10 rows</option>
              <option value="20">20 rows</option>
              <option value="30">30 rows</option>
              <option value="40">40 rows</option>
            </select>
          </div> */}

            {/* right */}
            {/* <div className="pagintion_right">
            <Pagination>
              <Pagination.Item active>{1}</Pagination.Item>
              <Pagination.Item>{2}</Pagination.Item>

              <Pagination.Ellipsis className="border-none" />

              <Pagination.Item>{6}</Pagination.Item>
              <Pagination.Next className="next" />
            </Pagination>
          </div> */}
          </div>
        </div>
        <ModalLoader show={isFetching || isfetchingExcel} />
      </main>
    </IsPrivileged>
  );
}

export default GeneralLedger;
