import React, { useEffect, useMemo, useState } from "react";
import PageHeader from "../PageHeader";
import { PaymentAndBillingIcon } from "../Icons";
import { Button, Card, Form, Modal, Table } from "react-bootstrap";
import PaystackPop from "@paystack/inline-js";
import Select from "react-select";

import * as yup from "yup";

import {
  Form as FormikForm,
  ErrorMessage,
  useFormik,
  FieldArray,
  Formik,
  Field,
  FormikProvider,
} from "formik";
import { waitFor } from "../../utils/helpers";
import currency from "currency.js";
import ConfirmDialog, { ConfirmBillingDialog } from "../ConfirmDialogue";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import { useMutation, useQuery } from "react-query";
import NumberCustomInput, {
  NumberCustomInputForPayment,
} from "../utils/NumberCustomInput";
import { useLocation, useNavigate } from "react-router-dom";
import { values } from "lodash";
import CreateUserAccount from "../modals/CreateUserAccount";
import { backendApis } from "../../config";
import * as Yup from "yup";

const allDepartment = [
  "",
  "Admin",
  "Accountant",
  "Cashier",
  "Content Management",
  "Contracts",
  "HR",
  "Sales",
  // "Shout Out",
  "Store",
  "Warehouse",
  "Production",
  "Sales Manager",
  "Operations",
  "Business Development",
  "Procurement",
  "QHSE",
  "Maintenance",
  "Document Control",
  "Government InvexERP",
  "Supply and Logistics",
  "Hospital Management Board",
];

const initialValues = {
  addUsers: 1,
  totalUsers: 0,
  totalAmount: 0,
  currentBalance: 0,
  billing: 0,
  currentPlan: "",
  currentUsers: null,
  coreUsers: 0,
  passiveUsers: 0,
  coreUsersAmount: 0,
  passiveUsersAmount: 0,
  planDurationType: "",
  billingPerUser: 0,
};

export default function RenewBusinessPlan() {
  const { backendUrl, token, user } = useAuth();

  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const [companyUrl, setCompanyUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleShow = () => {
    setShow(true);
  };

  const handleHide = () => {
    setShow(false);
  };
  const paystackConfig = {
    //  reference: new Date().getTime().toString(),
    //  email: "user@example.com",
    // amount: 20000, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
    key: process.env.REACT_APP_BILLING_AND_PAYMENT_PAYSTACK_PUBLIC_KEY,
  };

  const pay = async ({ amount, reference, email }) => {
    try {
      await waitFor(500);
      const paystack = new PaystackPop();
      paystack.newTransaction({
        ...paystackConfig,

        reference,
        email,
        amount: currency(amount).multiply(100).value,
        metadata: {
          type: "billing",
          companyUrl: companyUrl,
        },
        // other params

        onSuccess: async (transaction) => {
          // await fetch(`${backendUrl}/api/company/company-billing`, {
          //   method: "GET",
          //   credentials: "include",
          //   headers: {
          //     Accept: "Application/json",
          //     "Content-Type": "Application/json",
          //     ...(token ? { Authorization: `Bearer ${token}` } : {}),
          //   },
          // });

          navigate(
            `/process-billing-payment-transaction?reference=${
              transaction.reference
            }&q=${Number(formik.values.currentUsers) +
              Number(formik.values.addUsers)}`
          );
        },
        onCancel: () => {
          // user closed popup
          console.log("closed");
        },
      });
    } catch (err) {
      console.log(err);
      toast.error(JSON.stringify(err));
    }
  };

  const formik = useFormik({
    initialValues,

    onSubmit: async () => {
      let checkAmount =
        process.env.REACT_APP_SITE_TITLE === "InvexERP"
          ? Number(formik.values.coreUsersAmount) *
              Number(formik.values.coreUsers) +
            Number(formik.values.passiveUsersAmount) *
              Number(formik.values.passiveUsers)
          : Number(formik.values.billing);

      if (!Boolean(checkAmount)) {
        toast.error("invalid number of users entered");

        return;
      }
      if (process.env.REACT_APP_SITE_TITLE === "InvexERP") {
        if (
          Number(formik.values.coreUsers) +
            Number(formik.values.passiveUsers) !==
            Number(formik.values.addUsers) ||
          Number(formik.values.coreUsers) > Number(formik.values.addUsers) ||
          Number(formik.values.passiveUsers) > Number(formik.values.addUsers) ||
          Number(formik.values.coreUsersAmount) *
            Number(formik.values.coreUsers) +
            Number(formik.values.passiveUsersAmount) *
              Number(formik.values.passiveUsers) <=
            0
        ) {
          formik.setErrors({ error: "user limit exceeded" });
          throw new Error("user limit exceeded");
        }
      }

      let reference = new Date().getTime().toString();

      setIsLoading(true);
      const res = await fetch(
        `${backendUrl}/api/company/save-company-billing-metadata`,
        {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            metadata: {
              totalUsers:
                Number(formik.values.addUsers) +
                Number(formik.values.currentUsers),
              email: user?.email,
              amount:
                process.env.REACT_APP_SITE_TITLE === "InvexERP"
                  ? Number(formik.values.coreUsersAmount) *
                      Number(formik.values.coreUsers) +
                    Number(formik.values.passiveUsersAmount) *
                      Number(formik.values.passiveUsers)
                  : Number(formik.values.billing),

              users: formik.values.addUsers,
              addedUsers: formik.values.addUsers,
              removedUsers: 0,
              userActionType: "activeAddedUsers",
              currentUsers: formik.values.currentUsers,
              coreUsers:
                process.env.REACT_APP_SITE_TITLE === "InvexERP"
                  ? Number(formik.values.coreUsers)
                  : "",
              passiveUsers:
                process.env.REACT_APP_SITE_TITLE === "InvexERP"
                  ? Number(formik.values.passiveUsers)
                  : "",
              invexVersion: process.env.REACT_APP_SITE_TITLE,
              backendApis: backendApis,
              planDurationType: formik.values.planDurationType,
              coreUsers: formik.values.coreUsers,
              passiveUsers: formik.values.passiveUsers,
              coreUsersAmount: formik.values.coreUsersAmount,
              passiveUsersAmount: formik.values.passiveUsersAmount,
              pricePerUser: formik.values.billingPerUser,
              userDetails: "",
              type: "billing",
              companyUrl: companyUrl,
              staffName: user?.Name,
              reference,
            },
          }),
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
          },
        }
      );

      setIsLoading(false);

      if (!res.ok) {
        toast.error("try again!");

        return;
      }

      await pay({
        reference,
        amount:
          process.env.REACT_APP_SITE_TITLE === "InvexERP"
            ? Number(formik.values.coreUsersAmount) *
                Number(formik.values.coreUsers) +
              Number(formik.values.passiveUsersAmount) *
                Number(formik.values.passiveUsers)
            : Number(formik.values.billing),
        backendUrl: backendUrl,
        email: user?.email,
        type: "billing",
      });
    },
  });

  function isLeapYear(year) {
    const daysInNonLeapYear = 365;
    const daysInLeapYear = 366;
    if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
      return daysInLeapYear;
    } else {
      return daysInNonLeapYear;
    }
  }
  function getDaysInMonth(month, year) {
    // Months are zero-based in JavaScript Date object (January is 0, February is 1, etc.)
    // To get the number of days in the month, we set the date to 0 (last day of previous month)
    // and then get the day of the month, which gives us the total number of days in the month
    return new Date(year, month, 0).getDate();
  }

  function calculateRemainingBillFromExpirationDate(
    expirationDate,
    billPerUser,
    planDurationtype
  ) {
    const today = new Date();
    let expDate = new Date(expirationDate);

    const month = today.getMonth() + 1;
    const currentYear = today.getFullYear();
    // console.log("current year", month);

    const daysInMonths = getDaysInMonth(month, currentYear);

    // console.log(" ======= ", month);
    expDate.setDate(expDate.getDate() - 9);

    const year = expDate.getFullYear();

    const diffTime = new Date(expDate).getTime() - today.getTime();

    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    const yearToDays = isLeapYear(year);

    let planType = planDurationtype === "yearly" ? yearToDays : daysInMonths;

    const billPerUserPerDay = billPerUser / Number(planType);

    const bill = Math.round(diffDays * billPerUserPerDay);
    return bill;
  }

  async function fetchData(url) {
    const res = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
      },
    });

    const data = await res.json();

    let companyData = data?.company;

    companyData = {
      ...companyData,
      planDurationType: data?.company?.planDurationType || "yearly",
    };

    return companyData;
  }

  const { data } = useQuery(
    ["GET_COMPANY_DETAILS"],
    () => fetchData(`${backendUrl}/api/company/details`),
    {
      cacheTime: 0,

      onSuccess: (data) => {
        // formik.setFieldValue("currentUsers", parseInt(Number(data?.Num_Users)));
        const billingPerUser = calculateRemainingBillFromExpirationDate(
          data?.ExpiryDate,
          process.env?.REACT_APP_SITE_TITLE === "InvexERP" &&
            data?.planDurationType === "yearly"
            ? 250000
            : process.env?.REACT_APP_SITE_TITLE !== "InvexERP" &&
              data?.planDurationType === "yearly"
            ? 125000
            : process.env?.REACT_APP_SITE_TITLE === "InvexERP" &&
              data?.planDurationType === "monthly"
            ? 20850
            : process.env?.REACT_APP_SITE_TITLE !== "InvexERP" &&
              data?.planDurationType === "monthly"
            ? 10500
            : 0,
          data.planDurationType
        );

        const passiveBillingPerUser = calculateRemainingBillFromExpirationDate(
          data?.ExpiryDate,
          process.env?.REACT_APP_SITE_TITLE === "InvexERP" &&
            data?.planDurationType === "yearly"
            ? 150000
            : process.env?.REACT_APP_SITE_TITLE === "InvexERP" &&
              data?.planDurationType === "monthly"
            ? 12500
            : 0,
          data.planDurationType
        );

        formik.setFieldValue("email", data?.Email);
        formik.setFieldValue("currentPlan", "Business plan");
        formik.setFieldValue("currentUsers", Number(data?.Num_Users));
        formik.setFieldValue("billing", 1 * billingPerUser);
        formik.setFieldValue("billingPerUser", 1 * billingPerUser);
        formik.setFieldValue("coreUsers", 1);

        formik.setFieldValue("coreUsersAmount", billingPerUser);

        formik.setFieldValue("passiveUsersAmount", passiveBillingPerUser);
        formik.setFieldValue("planDurationType", data?.planDurationType);

        const companyHqUrl = backendApis
          .map((url) => {
            if (url.isHq) {
              return url.url;
            }
          })

          .join("");

        setCompanyUrl(companyHqUrl);
      },
      enabled: true,
    }
  );

  return (
    <>
      {/* <CreateUserAccount
        onHide={handleHide}
        show={show}
        refetch={() => console.log("success")}
      /> */}

      {/* <SkipModal handleShow={handleShow} show={show} /> */}
      <main className="inner-page-outlet">
        <PageHeader
          name="Payment & Billing"
          description="Renew your business plan"
          icon={<PaymentAndBillingIcon />}
        />

        <Form noValidate onSubmit={formik.handleSubmit}>
          <main className=" container mx-auto" style={{ maxWidth: "600px" }}>
            <div>
              <div className=" w-100 p-5 px-3">
                <Card>
                  <Card.Body className="p-4 ">
                    <h6 className="card-header-title  my-3">Billing</h6>
                    <div className=" mb-3">
                      <b>
                        Current Plan:
                        <span className=" fw-normal  mx-2">Business plan</span>
                      </b>
                    </div>
                    <p className=" fw-bold border-bottom my-3 pb-2">
                      Billing Details:
                    </p>
                    <div className="mb-3   col-md-6">
                      <div className=" mb-2">Users:</div>
                      <NumberCustomInput
                        placeholder="0"
                        name="addUsers"
                        value={Number(formik.values.addUsers)}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, Number(value));

                          if (!Boolean(value)) {
                            formik.setFieldValue(name, 0);
                          }

                          process.env.REACT_APP_SITE_TITLE === "InvexERP"
                            ? formik.setFieldValue(
                                "billing",
                                Boolean(value)
                                  ? value * formik.values.coreUsersAmount
                                  : 0
                              )
                            : formik.setFieldValue(
                                "billing",
                                Boolean(value)
                                  ? value * formik.values.billingPerUser
                                  : 0 * formik.values.billingPerUser
                              );

                          formik.setFieldValue("coreUsers", value);
                          formik.setFieldValue("passiveUsers", 0);
                        }}
                        onBlur={() => formik.setFieldTouched("addUsers", true)}
                      />
                    </div>
                    {process.env.REACT_APP_SITE_TITLE === "InvexERP" && (
                      <div className="row my-4">
                        <div className="col-md-6 mb-2 mb-sm-4">
                          <div className=" mb-2">Core users</div>
                          <NumberCustomInputForPayment
                            placeholder="0"
                            name="coreUsers"
                            expectedTotalValue={Number(formik.values.addUsers)}
                            comparedValue={
                              Number(formik.values.coreUsers) +
                              Number(formik.values.passiveUsers)
                            }
                            value={formik.values.coreUsers}
                            onValueChange={(value, name) => {
                              if (value > formik.values.addUsers) {
                                formik.setErrors({
                                  error: "user limit exceeded",
                                });
                                return;
                              }

                              formik.setFieldValue(name, value);
                              formik.setFieldValue(
                                "passiveUsers",
                                formik.values.addUsers - value
                              );

                              formik.setFieldValue(
                                "coreUsersTotalAMount",
                                value * formik.values.coreUsersAmount
                              );
                            }}
                            onBlur={() =>
                              formik.setFieldTouched("addUsers", true)
                            }
                          />
                        </div>
                        <div className="col-md-6 mb-2 ">
                          <div className=" mb-2">Passive users</div>
                          <NumberCustomInputForPayment
                            placeholder="0"
                            name="passiveUsers"
                            expectedTotalValue={Number(formik.values.addUsers)}
                            comparedValue={
                              Number(formik.values.coreUsers) +
                              Number(formik.values.passiveUsers)
                            }
                            value={formik.values.passiveUsers}
                            onValueChange={(value, name) => {
                              if (value > formik.values.addUsers) {
                                formik.setErrors({
                                  error: "user limit exceeded",
                                });
                                return;
                              }

                              formik.setFieldValue(name, value);
                              formik.setFieldValue(
                                "coreUsers",
                                formik.values.addUsers - value
                              );

                              formik.setFieldValue(
                                "passiveUsersTotalAmount",
                                value * formik.values.passiveUsersTotalAmount
                              );
                            }}
                            onBlur={() =>
                              formik.setFieldTouched("addUsers", true)
                            }
                          />
                        </div>
                        <div className=" text-danger">
                          {formik.touched &&
                            formik.errors &&
                            formik?.errors?.error}
                        </div>
                      </div>
                    )}
                    <div className="my-3 d-flex align-items-center gap-2">
                      {process.env.REACT_APP_SITE_TITLE === "InvexOne" && (
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicCheckbox"
                        >
                          <Form.Check
                            type="radio"
                            label={`${currency(
                              formik.values.billingPerUser
                            ).format({
                              symbol: "",
                            })}  / ${
                              data?.planDurationType === "yearly"
                                ? "user / year"
                                : "user / month"
                            } ( your current plan is billed at ${
                              data?.planDurationType === "yearly"
                                ? "yearly"
                                : "monthly"
                            })`}
                            name="yearly"
                            checked
                          />
                        </Form.Group>
                      )}

                      {process.env.REACT_APP_SITE_TITLE === "InvexERP" && (
                        <span className="fw-bold">
                          {" "}
                          your current plan is billed at{" "}
                          {data?.planDurationType === "yearly"
                            ? "yearly"
                            : "monthly"}{" "}
                          / user
                        </span>
                      )}
                    </div>
                    {process.env.REACT_APP_SITE_TITLE === "InvexERP" && (
                      <div>
                        <Table responsive className="">
                          <tbody>
                            <tr className=" ">
                              <td />
                              <td />
                              <td />
                              <td>Sub total</td>
                            </tr>
                            <tr className=" ">
                              <td> Core Users</td>
                              <td>
                                {Boolean(formik.values.coreUsers)
                                  ? formik.values.coreUsers
                                  : 0}
                              </td>
                              <td>
                                {currency(formik.values.coreUsersAmount).format(
                                  {
                                    symbol: "",
                                  }
                                )}
                                / user
                              </td>
                              <td>
                                {currency(
                                  Boolean(formik.values.coreUsers)
                                    ? formik.values.coreUsers *
                                        formik.values.coreUsersAmount
                                    : 0
                                ).format({
                                  symbol: "",
                                })}
                              </td>
                            </tr>
                            <tr className=" ">
                              <td>Passive Users</td>
                              <td>
                                {Boolean(formik.values.passiveUsers)
                                  ? formik.values.passiveUsers
                                  : 0}
                              </td>
                              <td>
                                {currency(
                                  formik.values.passiveUsersAmount
                                ).format({
                                  symbol: "",
                                })}
                                / user
                              </td>
                              <td>
                                {" "}
                                {currency(
                                  Boolean(formik.values.passiveUsers)
                                    ? formik.values.passiveUsers *
                                        formik.values.passiveUsersAmount
                                    : 0
                                ).format({
                                  symbol: "",
                                })}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    )}
                    {/* {Boolean(formik.values.coreUsers) && (
                      <div className=" mb-3">
                        Core users:
                        <span span className=" mx-2">
                          {formik.values.coreUsers} x{" "}
                          {formik.values.coreUsersAmount} ={" "}
                          {formik.values.coreUsers *
                            formik.values.coreUsersAmount}
                        </span>
                      </div>
                    )}

                    {Boolean(formik.values.passiveUsers) && (
                      <div className=" mb-3">
                        Passive users:
                        <span span className=" mx-2">
                          {formik.values.passiveUsers} x{" "}
                          {formik.values.passiveUsersAmount} ={" "}
                          {formik.values.passiveUsers *
                            formik.values.passiveUsersAmount}
                        </span>
                      </div>
                    )} */}

                    <div className=" mb-3">
                      Total User:
                      <span span className=" mx-2">
                        {Boolean(formik.values.addUsers)
                          ? Number(formik.values.currentUsers) +
                            Number(formik.values.addUsers)
                          : 0}
                      </span>
                    </div>
                    <div className=" mb-3">
                      Prorated Amount:{" "}
                      <span className=" mx-2">
                        {process.env.REACT_APP_SITE_TITLE === "InvexOne" &&
                          currency(formik.values.billing).format({
                            symbol: "",
                          })}
                        {process.env.REACT_APP_SITE_TITLE === "InvexERP" &&
                          currency(
                            Boolean(formik.values.passiveUsers) ||
                              Boolean(formik.values.coreUsers)
                              ? formik.values.coreUsersAmount *
                                  formik.values.coreUsers +
                                  formik.values.passiveUsersAmount *
                                    formik.values.passiveUsers
                              : 0
                          ).format({
                            symbol: "",
                          })}
                      </span>
                    </div>
                    <div className=" row">
                      <div className=" col-md-8 mb-3">
                        <Button
                          variant="primary"
                          className="  py-3 mt-2 col-md-5"
                          type="submit"
                        >
                          {isLoading ? "Please wait" : "    Make Payment"}
                        </Button>
                        <Button
                          variant=""
                          className="btn-lg mt-2 col-md-5 mx-2 py-3    btn-outline-primary"
                          onClick={() => navigate("/payment-and-billing")}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className=" col-md-12">
                        <div style={{ maxWidth: "8rem" }}>
                          <img src="/card.png" className=" img-fluid" />
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </main>
        </Form>
      </main>
    </>
  );
}
