import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, InputGroup, Modal, Form } from "react-bootstrap";
import TableComponent from "../TableComponent";
import { useMutation, useQuery } from "react-query";
import { fetchActionsUtil, paginationOptions } from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import { backendApis } from "../../config";
import { isEmpty, result, uniqBy } from "lodash";
import NoTableItem from "../utils/NoTableItem";
import { useNavigate } from "react-router-dom";
import { useStoreActions } from "easy-peasy";
import useDebounce, { useQueryParams } from "../../utils/hooks";
import queryString from "query-string";
import ReactPaginate from "react-paginate";

export default function GlobalItemSearchModal({ show, setShow }) {
  const { backendUrl } = useAuth();
  const [loading, setLoading] = useState(false);
  const initialFilterParams = {
    page: 1,
    limit: 5,
  };
  const [count, setCount] = useState(0);

  const [data, setTableData] = useState([]);
  const navigate = useNavigate();
  const setRaisedItemRequisitionData = useStoreActions(
    (actions) => actions.setRaisedItemRequisitionData
  );
  // setRaisedItemRequisitionData

  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 5,
    q: "",
    ...initialFilterParams,
  });

  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  async function searchItems(query) {
    try {
      const promises = backendApis.map(async (api) => {
        let data = await fetchActionsUtil(
          `${api?.url}/api/items/global-search?${queryString.stringify(query)}`,
          "GET"
        );

        data.branch = api;

        return data;
      });

      const results = await Promise.all(promises);

      const count = results.reduce((acc, curr) => acc + curr?.count, 0);

      setCount(count);

      const addBranchDetails = results.map((result) => {
        return result.items.map((item) => ({
          ...item,
          branch: result.branch,
        }));
      });

      const data = addBranchDetails.flat();

      return data;
    } catch (error) {
      console.log(error);
    }
  }
  const { data: mydata, refetch, isFetching, isSuccess } = useQuery(
    ["GLOBAL SEARCH ITEMS", queryParams],
    async () => await searchItems(queryParams),

    {
      enabled: true,
      keepPreviousData: true,
      onSuccess: (data) => {
        const uniqItem = data;

        // uniqBy(data, "Item_Name");

        setTableData(uniqItem);

        setLoading(false);
      },
    }
  );

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams, setQueryParams]);

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  // const seachMutation = useMutation(
  //   async (payload) => {
  //     try {
  //       const promises = backendApis.map(async (api) => {
  //         let data = await fetchActionsUtil(
  //           `${api?.url}/api/items/global-search/${payload}`,
  //           "GET"
  //         );

  //         data.branch = api;

  //         return data;
  //       });

  //       const results = await Promise.all(promises);

  //       const addBranchDetails = results.map((result) => {
  //         return result.items.map((item) => ({
  //           ...item,
  //           branch: result.branch,
  //         }));
  //       });

  //       const data = addBranchDetails.flat();

  //       return data;
  //     } catch (error) {
  //       console.log(error);
  //     }

  //     // let data = addBranchDetails.map((result) => {
  //     //   return result.items;
  //     // });
  //   },
  //   {
  //     onMutate: () => {
  //       setLoading(true);
  //     },

  //     onSuccess: (data) => {
  //       console.log(data);
  //       const uniqItem = data;

  //       // uniqBy(data, "Item_Name");

  //       setTableData(uniqItem);

  //       setLoading(false);
  //     },

  //     onError: () => {
  //       setLoading(false);
  //     },
  //   }
  // );
  // const formik = useFormik({
  //   initialValues: {
  //     search: "",
  //   },
  //   onSubmit: (values) => {
  //     seachMutation.mutate(values.search);
  //   },
  // });

  console.log(data);

  const tableHead = (el, index) => {
    return (
      <>
        <th>S/N</th>
        <th>Item</th>
        <th>Quantity</th>
        <th>Warehouse</th>
        <th>Branch</th>
        <th>Action</th>
      </>
    );
  };

  const tableData = (el, index) => {
    return (
      <>
        <td>{index + 1}</td>

        <td className="w-75  text-wrap ">{el?.Item_Name}</td>
        <td>{el?.Quantity}</td>
        <td className="w-25">{el?.warehouse?.map((wareh) => wareh?.W_name)}</td>
        <td className="w-25">{el?.branch?.name}</td>
        <td className="w-25">
          <Button
            variant="outline-primary"
            onClick={() => {
              setRaisedItemRequisitionData({ ...el });
              setShow(false);
              navigate("/requisition/request?global=true");
            }}
          >
            Raise Requisition
          </Button>
        </td>
      </>
    );
  };
  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      enforceFocus={false}
      className={"roles-priviledges "}
      size="lg"
      centered
    >
      <div className="  px-5 pb-5 ">
        <Modal.Header closeButton>
          <Modal.Title as="h6">Global Item Search</Modal.Title>
        </Modal.Header>
        <Form>
          <div className="d-flex justify-content-center my-3">
            <Form.Group className=" col-md-6">
              <InputGroup>
                <Form.Control
                  type={"text"}
                  name="q"
                  placeholder="Search item name or bar code"
                  autoFocus
                  // value={formik.values.search}
                  // onChange={formik.handleChange}
                  value={filterParams?.q}
                  onChange={(e) => handleFilterParamsChange(e)}
                />
                <Button
                  disabled={loading}
                  onClick={() => search()}
                  variant="primary"
                  className="text-primary forgot  text-center text-white"
                  type="button"
                  style={{ fontSize: "0.75rem" }}
                >
                  {loading ? "Searching..." : "Search"}
                </Button>
              </InputGroup>
            </Form.Group>
          </div>
        </Form>

        <TableComponent
          responsive
          borderless
          striped
          tableHeadsFunction={tableHead}
          mainDataArray={data || []}
          className="product-table text-nowrap"
          tableDataRowFunction={tableData}
          // tableRowClick={(e, el) => manageTransaction(e, el)}
        />

        {!loading && isEmpty(data) ? <NoTableItem queryParams={""} /> : null}
      </div>

      <div className="d-flex justify-content-between px-3 align-items-center pagination">
        {/*<p className="m-0">
                Showing {data?.startIndex + 1} to{" "}
                {data?.endIndex <= data?.count ? data?.endIndex : data?.count}{" "}
                of {data.count} entries
                  </p> */}
        <div className="pagination_left">
          <p className="m-0 p-0">Show</p>
          <select
            value={queryParams.limit}
            name="limit"
            className="form-select "
            onChange={(e) => handleFilterParamsChange(e)}
          >
            <option value="10">10 rows</option>
            <option value="20">20 rows</option>
            <option value="30">30 rows</option>
            <option value="40">40 rows</option>
            <option value="50">50 rows</option>
            <option value="100">100 rows</option>
          </select>
        </div>

        <ReactPaginate
          {...paginationOptions}
          // pageCount={Math.ceil(count / queryParams.limit)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={0}
          onPageChange={({ selected }) => {
            // scrollToTop();
            setQueryParams({
              ...queryParams,
              page: selected + 1,
            });
          }}
          forcePage={queryParams.page - 1}
        />
      </div>
    </Modal>
  );
}
