import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import Select from "react-select";
import { useFormik } from "formik";
import * as yup from "yup";
import CreatableSelect from "react-select/creatable";

import { useAuth } from "../../hooks/useAuth";
import { fetchActionsUtil } from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import ModalLoader from "../utils/ModalLoader";
import AddAccounts from "./AddAccounts";
import CloseIcon from "mdi-react/CloseIcon";

const wareCategory = [
  { label: "Select Category", value: "" },
  { value: "Stock", label: "Stock" },
  { value: "non-stock", label: "Non-Stock" },
  { value: "Assets", label: "Fixed Asset" },
  { value: "Comsumables", label: "Consumables" },
];
const wareTYpe = [
  { value: "", label: "Select Type" },
  { value: "Distribution", label: "Distribution" },
  { value: "Storage", label: "Storage" },
];

const CreateWarehouseModal = (props) => {
  const { backendUrl, user } = useAuth();

  const [openAccountModal, setOpenAccountModal] = useState(false);

  const getThings = async () => {
    const staffs = await fetchActionsUtil(`${backendUrl}/api/users`, "GET");
    staffs.staff = staffs.staff.map((el) => ({
      value: el.Staff_ID,
      label: el.Name,
    }));

    const acc = await fetchActionsUtil(
      `${backendUrl}/api/accounts/accountLists/FIXED ASSET`,
      "GET"
    );

    const { ContentTypes } = await fetchActionsUtil(
      `${backendUrl}/api/warehouse/contentTypes`,
      "GET"
    );

    staffs.contentTypes = ContentTypes.map((el) => ({
      value: el.serial,
      label: el.name,
    }));

    staffs.accounts = acc.accountDes.map((el) => ({
      value: el.AccountID,
      label: el.Description,
    }));

    return staffs;
  };

  const { data, isFetching, refetch } = useQuery(
    ["FETCH_WAREHOUSE_STAFFS"],
    () => getThings(),
    {
      keepPreviousData: true,
    }
  );

  // console.log(data);
  const saveWarehouse = useMutation(
    (payload) =>
      fetchActionsUtil(`${backendUrl}/api/warehouse`, "POST", "", payload),
    {
      onSuccess: ({ data }) => {
        toast.success("Warehouse saved Successfully");
        formik.resetForm({
          users: [],
          W_name: "",
          W_type: "",
          W_Category: "",
          account_WH: "",
          Warehouse_Type: "",
        });
        props?.refetch();
        props?.onHide();
        props?.StorageWarehouseName(data);
        props?.showStorage();
      },
      onError: () => {
        toast.error("Unable to save warehouse");
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      users: [],
      W_name: "",
      W_type: "",
      W_Category: "",
      account_WH: "",
      Warehouse_Type: "",
    },
    validationSchema: yup.object().shape({
      users: yup.array().required("Choose Users"),
      W_name: yup.string().required("Warehouse Name is required"),
      W_type: yup.string().required("Content Type is required"),
      W_Category: yup.string().required("Warehouse Category is required"),
      Warehouse_Type: yup.string().required("Warehouse Type is required"),
    }),
    onSubmit: async (values) => {
      // if (
      //   formik.values.W_Category !== "Comsumables" &&
      //   formik.values.account_WH === ""
      // ) {
      //   return formik.setFieldError("account_WH", "Acount is required");
      // }
      /*  if (values.W_Category === "Assets") {
        return toast.error("You have not subscribe to create assets warehouse");
      }*/
      const WHuser = [...values.users.map((d) => d.value), user?.Staff_ID];
      values.users = [...new Set(WHuser)];

      //console.log(values);

      if (
        await ConfirmDialog({
          title: "Create Warehouse",
          description: `Are you sure you want to Create this warehouse `,
        })
      ) {
        saveWarehouse.mutate(values);
      }
    },
  });

  // const openAccount = () => {
  //   if (formik.values.W_name === "") {
  //     return formik.setFieldError("W_name", "Warehouse Name is required");
  //   } else {
  //     setOpenAccountModal(true);
  //   }
  // };

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          <div>
            <div className="d-flex justify-content-between">
              <h6>Create Warehouse</h6>
              <div role="button" className="text-lg" onClick={props.onHide}>
                <CloseIcon />
              </div>
            </div>
            <p className="text-muted">
              Create a new warehouse and assign users to it
            </p>
          </div>
          <div className="mt-3">
            {/*  */}
            <div className="mb-3">
              <label className="col-form-label fw-bold">Warehouse Name</label>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  value={formik.values.W_name}
                  name="W_name"
                  onChange={formik.handleChange}
                />
                <span className="form-control-feedback text-danger">
                  {formik.errors.W_name}
                </span>
              </div>
            </div>
            {/*  */}
            <div className="mb-3">
              <label className="col-form-label fw-bold">Content Type</label>
              <div className="form-group">
                <CreatableSelect
                  isClearable
                  onChange={(selected) => {
                    formik.setFieldValue("W_type", selected?.value);
                  }}
                  // onCreateOption={saveWarehouseContent}
                  options={data?.contentTypes}
                  value={data?.contentTypes?.find(
                    (el) => el.value === formik.values.W_type
                  )}
                />
                <span className="form-control-feedback text-danger">
                  {formik.errors.W_type}
                </span>
              </div>
            </div>
            {/*  */}
            <div className="mb-3">
              <label className="col-form-label fw-bold">
                Warehouse Category
              </label>
              <div className="form-group">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={formik.values.W_Category}
                  name="W_Category"
                  onChange={formik.handleChange}
                >
                  {wareCategory.map((a, i) => (
                    <option key={i} value={a.value}>
                      {a.label}
                    </option>
                  ))}
                </select>

                {formik.touched.W_Category && !!formik.errors.W_Category ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.W_Category}
                  </span>
                ) : null}
              </div>
            </div>
            {/*  */}

            <div className="mb-3">
              <label className="col-form-label fw-bold">Warehouse Type</label>
              <div className="form-group">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={formik.values.Warehouse_Type}
                  name="Warehouse_Type"
                  onChange={formik.handleChange}
                >
                  {wareTYpe.map((a, i) => (
                    <option key={i} value={a.value}>
                      {a.label}
                    </option>
                  ))}
                </select>

                {formik.touched.Warehouse_Type &&
                !!formik.errors.Warehouse_Type ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.Warehouse_Type}
                  </span>
                ) : null}
              </div>
            </div>
            {/*  */}

            {/* {formik.values.W_Category === "Comsumables" ? null : (
              <div className="mb-3 row">
                <label className="col-form-label fw-bold">
                  Map to GL account
                </label>
                <div className="form-group col-10">
                  <Select
                    closeMenuOnSelect={true}
                    value={data?.accounts?.find(
                      (el) => el.value === formik.values.account_WH
                    )}
                    onChange={(selected) => {
                      formik.setFieldValue("account_WH", selected.value);
                    }}
                    options={data?.accounts}
                  />
                  <span className="form-control-feedback text-danger">
                    {formik.errors.account_WH}
                  </span>
                </div>
                <div className="col-2">
                  <button
                    size="sm"
                    className="btn btn-primary"
                    onClick={openAccount}
                  >
                    + Add
                  </button>
                </div>
              </div>
            )} */}
            {/*  */}
            <div className="mb-3">
              <label className="col-form-label fw-bold">Add Users</label>
              <div className="form-group">
                <Select
                  closeMenuOnSelect={true}
                  isLoading={isFetching}
                  isMulti
                  isSearchable={true}
                  onChange={(selected) => {
                    formik.setFieldValue("users", selected);
                  }}
                  options={data?.staff}
                />
                {formik.touched.users && !!formik.errors.users ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.users}
                  </span>
                ) : null}
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </Modal.Body>
      <div className="d-inline-flex justify-content-end m-4">
        <button className="btn bg print mx-4" onClick={props.onHide}>
          Cancel
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={formik.handleSubmit}
        >
          Save Warehouse
        </button>
      </div>
      <ModalLoader show={isFetching || saveWarehouse.isLoading} />
      {openAccountModal && (
        <AddAccounts
          onHide={() => setOpenAccountModal(false)}
          show={openAccountModal}
          warehouse={formik.values.W_name}
          // setAccountReturn_WH={setAccountReturn_WH}
          refetch={refetch}
        />
      )}
    </Modal>
  );
};

export default CreateWarehouseModal;
