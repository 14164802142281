import currency from "currency.js";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Table, Popover, Overlay, Button } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import { useRef, useState } from "react";
import { toast } from "react-toastify";

export const ItemSelectTable = ({
  tableData,
  setTableData,
  isLoading,
  ElementItemTable,
  useType,
}) => {
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const [activeTableIndex, setActiveTableIndex] = useState(null);

  const ref = useRef(null);

  const handleRowClick = (e, index) => {
    setShow(true);
    setTarget(e.target);
  };

  const createNew = (e) => {
    setShow(true);
    setTarget(e.target);
    setActiveTableIndex(-1);
  };

  const handleSelectedItem = (item) => {
    // console.log({ item });
    if (item?.Item_Name && item?.accountName === null) {
      return toast.error("Please edit this asset and add account", {
        position: "top-right",
      });
    }

    item = {
      barcode: item.Bar_Code,
      itemName: item.Item_Name ? item.Item_Name : item?.ItemName,
      warehouse: item.Warehouse ? item.Warehouse : " ",
      unitCost: item.UnitCost,
      quantity: 1,
      checkIt: item?.accountName,
    };

    let oldTableData = tableData;

    if (activeTableIndex === -1) {
      oldTableData = [...oldTableData, item];
    } else {
      oldTableData[activeTableIndex] = item;
    }
    setTableData([...oldTableData]);
    if (activeTableIndex === -1) setActiveTableIndex(oldTableData.length - 1);
    setShow(false);
  };

  const handleInputChange = ({ index, name, value }) => {
    const oldTableData = tableData;
    oldTableData[index][name] = value;
    setTableData([...oldTableData]);
  };

  const removeSelection = () => {
    const oldTableData = tableData.filter(
      (el, index) => index !== activeTableIndex
    );
    setTableData([...oldTableData]);
  };

  return (
    <div>
      <Table responsive borderless striped className="product-table">
        <thead>
          <tr>
            <th>S/N</th>
            <th>Item Code</th>
            <th>Item Name</th>
            <th>Unit Cost</th>
            <th>Quantity</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody ref={ref}>
          {tableData &&
            tableData.map((el, index) => (
              <tr
                key={index}
                className={`${activeTableIndex === index && "active-row"}`}
                onClick={() =>
                  activeTableIndex !== index && setActiveTableIndex(index)
                }
              >
                <td>{index + 1}</td>
                <td
                  className="p-cursor"
                  onClick={(e) => handleRowClick(e, index)}
                >
                  {el.barcode}
                </td>
                <td>{el.itemName}</td>

                <td>
                  <CurrencyInput
                    className="form-control border-0 px-1"
                    value={el.unitCost}
                    onKeyDown={(e) => e.keyCode === 13 && e.target.blur()}
                    name="unitCost"
                    onValueChange={(value, name) =>
                      handleInputChange({
                        index,
                        name,
                        value,
                      })
                    }
                    decimalsLimit={2}
                  />
                </td>
                <td>
                  <CurrencyInput
                    className="form-control border-0 px-1"
                    value={el.quantity}
                    onKeyDown={(e) => e.keyCode === 13 && e.target.blur()}
                    name="quantity"
                    onValueChange={(value, name) =>
                      handleInputChange({
                        index,
                        name,
                        value,
                      })
                    }
                  />
                </td>
                <td>
                  <CurrencyInput
                    className="form-control border-0 px-0"
                    name="subtotal"
                    disabled
                    value={
                      !isNaN(el.unitCost * el.quantity)
                        ? currency(el.unitCost, {
                            precision: 2,
                          }).multiply(el.quantity)
                        : 0
                    }
                    onKeyDown={(e) => e.keyCode === 13 && e.target.blur()}
                    onValueChange={(value, name) =>
                      handleInputChange({
                        index,
                        name,
                        value,
                      })
                    }
                    decimalsLimit={2}
                  />
                </td>
              </tr>
            ))}

          <tr className={`p-cursor ${activeTableIndex === -1 && "active-row"}`}>
            <td onClick={(e) => createNew(e)}>
              <span className="">
                <MagnifyIcon />
              </span>
            </td>
          </tr>
        </tbody>
      </Table>

      <Overlay
        show={show}
        onHide={() => setShow(false)}
        target={target}
        container={document.body}
        containerPadding={0}
        rootClose={true}
        popperConfig={{
          strategy: "fixed",
          modifiers: [
            {
              name: "ga-custom",
              enabled: true,
              phase: "beforeWrite",
              fn({ state }) {
                const oldTransfrom = String(state.styles.popper?.transform);
                const y = Number(
                  String(oldTransfrom)
                    .split(",")[1]
                    .split(")")[0]
                    .split("px")[0]
                    .trim()
                );

                const x = Number(
                  String(oldTransfrom)
                    .split(",")[0]
                    .split("(")[1]
                    .split("px")[0]
                    .trim()
                );

                state.styles.popper.transform =
                  y < -210
                    ? `translate(${x}px, ${-210}px)`
                    : `translate(${x}px, ${y}px)`;
              },
            },
          ],
        }}
      >
        <Popover
          style={{
            maxWidth: "unset",
            height: "28.75rem",
            overflow: "hidden",
            boxShadow: "0px 4px 20px 0px #131B2814",
          }}
        >
          <ElementItemTable
            handleSelectedItem={handleSelectedItem}
            useType={useType}
          />
        </Popover>
      </Overlay>
      <div className="text-white my-3 ">
        {![null, -1].includes(activeTableIndex) ? (
          <Button
            disabled={isLoading}
            onClick={() => removeSelection()}
            variant="danger"
            size="sm"
            className="text-white px-3 "
          >
            Remove Selection
          </Button>
        ) : (
          <span />
        )}
      </div>
    </div>
  );
};
