import {
  Button,
  Dropdown,
  Form,
  InputGroup,
  Modal,
  Table,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import {
  DeleteIcon,
  DropdownCheckIcon,
  DropdownCloseIcon,
  DropdownEyeIcon,
  EditIcon,
  ExportIcon,
  FilterCollapseIcon,
  PDFIcon,
} from "../Icons";
import "./../../assets/scss/reports/cashbook.scss";
import { useEffect, useRef, useState } from "react";
import CachedIcon from "mdi-react/CachedIcon";
import { FilterTwoIcon, ExcelIcon } from "../Icons";
import useDebounce, {
  useIsAdmin,
  useLocalStorage,
  useQueryParams,
  useScrollTop,
} from "../../utils/hooks";
import queryString from "query-string";
import { useMutation, useQuery } from "react-query";
import currency from "currency.js";
import { addDays, endOfDay, format, parse, startOfDay } from "date-fns";
import ReactPaginate from "react-paginate";
import {
  formatDate,
  formatForQty,
  paginationOptions,
  reactSelectTheme,
  scrollToTop,
} from "../../utils/helpers";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import TableComponent from "../TableComponent";
import { useDownloadExcel } from "../../hooks/useDownloadExcel";
import ModalLoader from "../utils/ModalLoader";
import { isEmpty } from "lodash";
import NoTableItem from "../utils/NoTableItem";

import CreateShippingCostModal from "../CreateShippingCostModal";
import { fetchActionsUtil } from "../../utils/helpers";
import AddStockCount from "../AddStockCount";
import EditStockCount from "../EditStockCount";
import ConfirmDialog from "../ConfirmDialogue";
import { toast } from "react-toastify";
import ApproveStockCount from "../ApproveStockCount";
import Select from "react-select";
import LoginDialog from "../LoginDialog";
import { Popover } from "react-tiny-popover";
import ItemsTable from "../utils/ItemsTable";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import ChartBarIcon from "mdi-react/ChartBarIcon";
import { SerialNumberInAndOutChart } from "../Chart/SerialNumberInAndOutChart";
import { IsPrivileged } from "../DisplayChildElement";

const isRemovedOptions = [
  { label: "All", value: "All" },
  { label: "Sold", value: "Sold" },
  { label: "Not Sold", value: "Not Sold" },
];

export default function SerialNumberInAndOutReport() {
  const { backendUrl } = useAuth();
  const [showFilter, setShowFilter] = useLocalStorage("showFilter", true);

  useScrollTop();
  const [showAddStockCount, setShowAddStockCount] = useState(false);
  const [showEditStockCount, setShowEditStockCount] = useState(false);
  const [
    showSerialNumberInAndOutChart,
    setShowSerialNumberInAndOutChart,
  ] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const isAdmin = useIsAdmin();

  const initialFilterParams = {
    page: 1,
    limit: 40,
    barcode: "",
    itemName: "",
    q: "",
    startDate: format(addDays(new Date(), -Number(30)), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
  };
  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...queryParams,
    // ...initialFilterParams,
  });

  const [showItemsPopover, setShowItemsPopover] = useState(false);

  const [excelData, setExcelData] = useState([]);

  const CSVLinkRef = useRef(null);

  // fetch excel hook
  const [isfetchingExcel, fetchExcelData] = useDownloadExcel(
    excelData,
    CSVLinkRef
  );

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams((q) => ({ ...q, ...debouncedFilterParams }));
  }, [debouncedFilterParams, setQueryParams]);

  const {
    data = { count: 0, items: [], staffs: [] },
    refetch,
    isFetching,
  } = useQuery(
    ["GET_SERIAL_ITEMS", queryParams],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/items/serial-number-in-and-out?&${queryString.stringify(
          queryParams
        )}`
      ),
    {}
  );

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };

  const deleteApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/stock/delete`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteStockCountMutation = useMutation(
    (payload) => deleteApi(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        refetch();
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );
  const handleDelete = async (stockCount) => {
    if (await LoginDialog()) {
      if (
        await ConfirmDialog({
          title: "Delete Stock Count",
          description: "Are you sure you want to delete this Stock Count",
        })
      ) {
        deleteStockCountMutation.mutate({ ...stockCount });
      }
    }
  };

  const actionApi = async (payload) => {
    let response = await fetch(`${backendUrl}/api/stock/action`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const actionMutation = useMutation((payload) => actionApi(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      refetch();
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });
  const handleAction = async ({ action, id }) => {
    if (
      await ConfirmDialog({
        title: action === "Approved" ? "Approve" : "Reject",
        description: `Are you sure you want to ${
          action === "Approved" ? "Approve" : "Reject"
        }`,
      })
    ) {
      actionMutation.mutate({ action, id });
    }
  };

  const handleSelectedItem = (item) => {
    setFilterParams({
      ...filterParams,
      barcode: item.Bar_Code,
      itemName: item.Item_Name,
    });
    setShowItemsPopover(false);
  };

  // The main table data
  const tableBodyData = (el, index) => {
    return (
      <>
        <td>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="bg-white border-0"
              bsPrefix="print more"
            >
              <DotsHorizontalIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
              className="dropdown-with-icons"
            >
              <Dropdown.Item
                as="div"
                className="p-cursor"
                onClick={() => {
                  setSelectedItem(el);
                }}
              >
                <DropdownEyeIcon />
                Serial Numbers
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
        <td>{data?.startIndex + index + 1}</td>
        <td>
          {el?.actionDate ? formatDate(el?.actionDate, "dd MMM, yyyy") : "..."}
        </td>
        <td>{el?.Bar_Code}</td>
        <td>{el?.Item_Name}</td>
        <td>
          {currency(el.qtyInbound, {
            symbol: "",
            precision: 2,
            format: formatForQty,
          }).format()}
        </td>
        <td>
          {currency(el.qtyOutbound, {
            symbol: "",
            precision: 2,
            format: formatForQty,
          }).format()}
        </td>
      </>
    );
  };

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th />
          <th>S/N</th>
          <th>Date</th>
          <th>Item Code</th>
          <th>Item Name</th>
          <th>Qty Inbound</th>
          <th>Qty Outbound</th>
        </tr>
      </thead>
    );
  };

  const { limit, page, ...rest } = queryParams;
  const onDownloadExcelData = async () => {
    let exData = await fetchExcelData(
      `${backendUrl}/api/customers/get-shopping-cost?${queryString.stringify(
        rest
      )}`,
      "GET"
    );

    const company = exData?.company?.CompName;

    // console.log(exData.data);
    exData = exData?.items.map((row) => ({
      ...row,
      Date: format(new Date(row.Date), "dd-MMM-yyyy"),
    }));

    exData = exData.map((d, i) => [
      data?.startIndex + i + 1,
      d.state,
      d.LGA,
      currency(d?.cost, {
        symbol: "",
      }).format(),
      d.Date,
    ]);
    const date =
      rest.startDate && rest.endDate
        ? `Date Prepared: Between ${format(
            new Date(rest.startDate),
            "E MMM d yyyy k:mm:ss z"
          )} to ${format(new Date(rest.endDate), "E MMM d yyyy k:mm:ss z")}`
        : "";
    exData = [
      [company],
      ["Shipping Cost Report"],
      [date],
      [""],
      ["S/N", "State", "City", "Cost", "Date"],
      ...exData,
      [""],
    ];
    // console.log(exData);
    setExcelData(exData);
  };

  const search = (params) => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
      ...params,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  return (
    <IsPrivileged roleName="Inbound/Outbound Inventory">
      <section className="cashbook-wrapped">
        <main className="cash-book">
          <div className="content">
            <div className="content-main">
              <div className={`filter-area ${showFilter ? "open" : "close"}`}>
                <div className="filter-header">
                  <h2>
                    <FilterTwoIcon /> Filters
                  </h2>

                  <Button
                    variant="white"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <FilterCollapseIcon />
                  </Button>
                </div>
                <div className="filter-body">
                  <Form>
                    <div className="body">
                      <Form.Group className="mb-2-5">
                        <Form.Label>Item Code</Form.Label>

                        <InputGroup>
                          <Form.Control
                            name="barcode"
                            placeholder="Enter Item Code"
                            value={filterParams?.barcode}
                            onChange={(e) => handleFilterParamsChange(e)}
                          />

                          <Popover
                            isOpen={showItemsPopover}
                            onClickOutside={() => setShowItemsPopover(false)}
                            content={() => (
                              <ItemsTable
                                handleSelectedItem={handleSelectedItem}
                                defaultSort={"name-asc"}
                              />
                            )}
                            position="bottom"
                          >
                            <InputGroup.Text
                              onClick={() =>
                                setShowItemsPopover(!showItemsPopover)
                              }
                            >
                              <MagnifyIcon />
                            </InputGroup.Text>
                          </Popover>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group className="mb-2-5">
                        <Form.Label>Item Name</Form.Label>
                        <Form.Control
                          name="itemName"
                          value={filterParams?.itemName}
                          placeholder="Enter Item Name"
                          onChange={(e) => handleFilterParamsChange(e)}
                        />
                      </Form.Group>

                      <hr className="mt-3 mb-4" />
                      <Form.Group className="">
                        <Form.Label>Date Range</Form.Label>
                        <div className="position-relative">
                          <RsDateRangePicker
                            placement="topStart"
                            value={
                              filterParams.startDate && filterParams.endDate
                                ? [
                                    parse(
                                      filterParams.startDate,
                                      "yyyy-MM-dd",
                                      new Date()
                                    ),
                                    parse(
                                      filterParams.endDate,
                                      "yyyy-MM-dd",
                                      new Date()
                                    ),
                                  ]
                                : []
                            }
                            onClean={() => clearDateRange()}
                            onOk={(date) => filterByDateRange(date)}
                            cleanable={false}
                          />
                        </div>
                      </Form.Group>
                    </div>
                    <div className="buttons rounded w-100">
                      <Button onClick={() => reset()} variant="white">
                        Reset
                      </Button>
                      <Button onClick={() => search()} variant="primary">
                        Search
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="content-body">
                <header>
                  <h1>
                    {!showFilter && (
                      <button
                        onClick={() => setShowFilter(!showFilter)}
                        className="btn filter"
                      >
                        <FilterTwoIcon />
                      </button>
                    )}
                    Inbound/Outbound Inventory
                    <button
                      onClick={() => refetch()}
                      className="btn text-primary"
                      title="Refresh"
                    >
                      <CachedIcon />
                    </button>
                  </h1>
                  <div className="actions mr-5">
                    {/*   <a
                    href={`${backendUrl}/api/items/pdf/stock-count-report?${queryString.stringify(
                      rest
                    )}`}
                    target="blank"
                    className="btn print"
                  >
                    Stock Count Report <PDFIcon color="#ff0000" />
                  </a> */}
                    <Button
                      variant=""
                      className="print text-black"
                      onClick={() => setShowSerialNumberInAndOutChart(true)}
                    >
                      <ChartBarIcon /> Chart
                    </Button>
                  </div>
                </header>

                <div className="px-md-4">
                  <TableComponent
                    responsive
                    borderless
                    striped
                    tableHeadsFunction={tableHead}
                    mainDataArray={data?.items}
                    tableDataRowFunction={tableBodyData}
                    className="product-table text-nowrap"
                  />
                  {isEmpty(data.items) && !isFetching ? (
                    <div className="d-flex justify-content-center text-center w-100 my-4">
                      <NoTableItem queryParams={queryParams} />
                    </div>
                  ) : null}
                </div>

                {/*  <div className="d-flex justify-content-between px-3 align-items-center pagination">
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>
                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                    <option value="50">50 rows</option>
                    <option value="100">100 rows</option>
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={Math.ceil(data.count / queryParams.limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    scrollToTop();
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={queryParams.page - 1}
                />
              </div> */}
              </div>
            </div>
          </div>

          {showSerialNumberInAndOutChart && (
            <SerialNumberInAndOutChart
              showSerialNumberInAndOutChart={showSerialNumberInAndOutChart}
              setShowSerialNumberInAndOutChart={
                setShowSerialNumberInAndOutChart
              }
              filterParams={filterParams}
              chartData={data?.items}
              clearDateRange={clearDateRange}
              filterByDateRange={filterByDateRange}
            />
          )}

          {selectedItem && (
            <SerialItemModal
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
            />
          )}

          <ModalLoader show={isfetchingExcel} />
        </main>
      </section>
    </IsPrivileged>
  );
}

export const SerialItemModal = ({ selectedItem, setSelectedItem }) => {
  const initialFilterParams = {
    Bar_Code: selectedItem?.Bar_Code,
    q: "",
    startDate: selectedItem.actionDate,
    endDate: selectedItem.actionDate,
  };

  const { backendUrl } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });

  const debouncedqueryParams = useDebounce(queryParams, 800);

  const { data = { items: [] }, refetch } = useQuery(
    ["GET_SERIAL_NUMBERS_001", debouncedqueryParams],
    () =>
      fetchActionsUtil(
        `${backendUrl}/api/items/serial-number-in-and-out-by-items?${queryString.stringify(
          debouncedqueryParams
        )}`
      ),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      page: 1,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  return (
    <>
      <Modal
        show={selectedItem}
        onHide={() => {
          setSelectedItem(null);
          // setShowBatchesModal(false);
        }}
        dialogClassName="requisition-details-modal approve-modal"
        backdropClassName={`global-backdrop`}
        centered={true}
        animation={false}
        enforceFocus={false}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h1 className="h5">Serial Numbers</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4 d-flex flex-column pt-0">
          <div className="search-area d-flex justify-content-between align-items-center gap-3 w-100 px-3 mb-4 h6">
            <h2>
              {selectedItem?.Bar_Code} - {formatDate(selectedItem?.actionDate)}
              <button
                title="Refresh"
                onClick={() => refetch()}
                className="btn text-primary"
              >
                <CachedIcon />
              </button>
            </h2>
          </div>

          <div className="col-6 d-flex gap-3 align-items-center pe-3 mb-3">
            <div className="global-search-area w-75">
              <MagnifyIcon />
              <input
                className="form-control search-input"
                name="q"
                value={queryParams.q}
                onChange={(e) =>
                  setQueryParams({
                    ...queryParams,
                    page: 1,
                    [e.target.name]: e.target.value,
                  })
                }
                placeholder={`Search Serial number`}
                autoFocus
                autoComplete="off"
                type={"text"}
              />
            </div>
          </div>

          <div className="content px-1 pb-4 pe-2 flex-grow-1">
            <Table borderless responsive striped className="product-table">
              <thead className="sticky border-bottom">
                <tr>
                  <th>Serial Number</th>
                  <th>Batch Code</th>
                  <th>Bar Code</th>
                  <th>Item Name</th>
                  <th>Delivery Date</th>
                  <th>Date Sold</th>
                </tr>
              </thead>

              <tbody className="blue-hover">
                {data?.items &&
                  data.items.map((el, index) => (
                    <tr key={index} className={`p-cursor`}>
                      <td>{el?.serialNum}</td>
                      <td>{el?.BatchCode}</td>
                      <td>{el?.Bar_Code}</td>
                      <td>{el?.Item_Name}</td>
                      <td>
                        {el?.Reg_date
                          ? formatDate(el.Reg_date, "dd MMM, yyyy HH:mm:ss")
                          : "..."}
                      </td>
                      <td>
                        {el?.dateRemoved
                          ? formatDate(el?.dateRemoved, "dd MMM, yyyy HH:mm:ss")
                          : ""}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
