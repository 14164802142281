import { useFormik } from "formik";
import { cloneDeep, debounce, isEmpty } from "lodash";
import { useMemo, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import * as yup from "yup";
import { FieldArray, FormikProvider } from "formik";
import NumberCustomInput from "../utils/NumberCustomInput";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import currency from "currency.js";
import {
  convertFromBaseCurrency,
  getNumberFromString,
  initialServiceItem,
} from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import { useStoreState } from "easy-peasy";
import AsyncCreatableSelect from "react-select/async-creatable";
import { DeleteIcon } from "../Icons";
import { Table, Form } from "react-bootstrap";

function AddServiceItemModal({
  tableDataToEdit = [],
  setEditedItemIndex,
  handleAddItem,
}) {
  const { backendUrl } = useAuth();
  const generalSettings = useStoreState((state) => state.generalSettings);

  // ----------------------------------------------------------
  const formik = useFormik({
    initialValues: {
      //  tax: "",
      // taxType: "None",
      // PaymentType: "Proforma",
      // proformaNumber: "",
      // splitPaymentType: "",
      // printWaybill: true,
      // salesDate: moment(),
      // invoiceCat: "",
      // OverwriteOfficer: "Retail",
      // chequeNumber: "",
      // BankName: "",
      // cashAmount: "",
      // sendEmail: false,
      // printReciept: true,
      // dueIn: 1,
      // pendingTransaction: "",
      // supplyNow: true,
      // ShipTo: "",
      // customerBalance: "",
      // amountToPayFromCustomerCredit: "",
      // shippingCost: 0,
      // terms: [{ text: "" }],
      // remark: generalSettings.pendingInvoiceType === "Quotation" ? "" : "",
      // otherCharges: 0,
      // currency: generalSettings?.prevailingCurrency,
      // linkedPaymentID: "",
      // jobNumber: nanoid(12, "number"),
      // poNumber: "",
      // title: "",
      // description: "",
      // currentMilestone: "",
      tableDataInForm: tableDataToEdit.map((el) => {
        const discountPerItem = currency(el?.Discount || 0).divide(
          getNumberFromString(
            el?.Warrant_Duration ? el?.Warrant_Duration : el?.QTY ? el.QTY : 1
          )
        ).value;
        //  console.log(discountPerItem);
        return {
          ...el,
          discountPerItem: isNaN(discountPerItem) ? 0 : discountPerItem,
        };
      }),
      // conversionAmount: generalSettings.dollarInBaseCurrency,
      // bankOnInvoice: "",
    },
    validationSchema: yup.object().shape({}),
    onSubmit: async (values) => {
      if (isEmpty(tableData)) return toast.error(`Please add an Item`);
      handleAddItem(tableData.filter((el) => el.Bar_Code && el.Item_Name));
      setEditedItemIndex(null);
    },

    onReset: () => {
      setTableData([]);
    },
  });

  const tableData = useMemo(() => {
    return formik.values?.tableDataInForm
      ? cloneDeep(formik.values.tableDataInForm)
      : [];
  }, [formik.values?.tableDataInForm]);

  const setTableData = (items) => {
    formik.setFieldValue("tableDataInForm", [...items]);
  };

  const handleRemoveItem = (index) => {
    setTableData([...tableData.filter((el, i) => i !== index)]);
  };

  const calculateServiceOtherValuesOnChange = ({
    index,
    Quantity = 0,
    PriceSold = 0,
    UnitCost = 0,
    //  Discount = 0,
    Warrant_Duration,
    discountPerItem = 0,
  }) => {
    const Discount = currency(discountPerItem).multiply(
      getNumberFromString(Warrant_Duration || Quantity)
    ).value;

    const Profit = currency(PriceSold)
      .subtract(UnitCost)
      .multiply(getNumberFromString(Warrant_Duration))
      .multiply(Quantity).value;

    const SubTotal = currency(PriceSold)
      // .subtract(Discount)
      .multiply(getNumberFromString(Warrant_Duration))
      .multiply(Quantity)
      .subtract(Discount).value;

    formik.setFieldValue(`tableDataInForm[${index}].Profit`, Profit);
    formik.setFieldValue(`tableDataInForm[${index}].SubTotal`, SubTotal);
    formik.setFieldValue(`tableDataInForm[${index}].Discount`, Discount);
  };

  const fetchItems = async (inputValue, callback) => {
    let response = await fetch(
      `${backendUrl}/api/items?page=1&Limit=50&q=${inputValue}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    if (data.items)
      callback(
        (data.items = data.items.map((el) => ({
          ...el,
          label: el.Item_Name,
          value: el.Item_Name,
          Warrant_Duration: "",
        })))
      );
  };

  const debouncedFetchItems = debounce(fetchItems, 500);

  const loadOptions = (inputValue, callback) => {
    debouncedFetchItems(inputValue, callback);
  };

  const handleEditItemByIndex = (item, index) => {
    item.Discount = item.Discount || 0;

    item.Profit = currency(item.PriceSold)
      .subtract(item.UnitCost)
      .multiply(getNumberFromString(item.Warrant_Duration))
      .multiply(item.Quantity).value;

    item.SubTotal = currency(item.PriceSold)
      .subtract(item.Discount)
      .multiply(getNumberFromString(item.Warrant_Duration))
      .multiply(item.Quantity).value;

    tableData[index] = item;
    setTableData([...tableData]);
  };

  return (
    <>
      <Modal
        show={true}
        onHide={() => setEditedItemIndex(null)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Services
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormikProvider value={formik}>
            {" "}
            <Form noValidate onSubmit={formik.handleSubmit} autoComplete="off">
              <div>
                <Table responsive borderless hover striped>
                  <thead>
                    <tr>
                      <th />
                      <th scope="col" className="col-4">
                        Service Name
                      </th>
                      <th scope="col" className="col">
                        Number of Days
                      </th>
                      <th title="Price Sold" scope="col" className="col-3">
                        Cost
                      </th>
                      <th scope="col" className="col">
                        Quantity
                      </th>
                      <th scope="col" className="col-3">
                        Discount
                      </th>
                      <th scope="col" className="col">
                        Subtotal
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <FieldArray
                      name="tableDataInForm"
                      render={(arrayHelpers) => (
                        <>
                          {formik.values?.tableDataInForm &&
                            formik.values.tableDataInForm.map((el, index) => (
                              <tr
                                key={index}
                                className="p-cursor align-items-center"
                              >
                                <td>
                                  <Button
                                    variant=""
                                    type="button"
                                    onClick={() => handleRemoveItem(index)}
                                  >
                                    <DeleteIcon />
                                  </Button>
                                </td>

                                <td>
                                  <AsyncCreatableSelect
                                    classNamePrefix="form-select"
                                    menuPosition="fixed"
                                    value={{
                                      label:
                                        formik.values.tableDataInForm[index]
                                          .Item_Name,
                                      value:
                                        formik.values.tableDataInForm[index]
                                          .Item_Name,
                                    }}
                                    onChange={(selected) => {
                                      if (selected?.__isNew__) {
                                        const newItem = initialServiceItem();
                                        handleEditItemByIndex(
                                          {
                                            ...newItem,
                                            Item_Name: selected.value,
                                          },
                                          index
                                        );
                                      } else {
                                        if (
                                          formik.values.currency &&
                                          formik.values.currency !==
                                            generalSettings?.prevailingCurrency
                                        ) {
                                          selected = convertFromBaseCurrency({
                                            data: selected,
                                            conversionAmount:
                                              formik.values.conversionAmount,
                                          });
                                        }
                                        handleEditItemByIndex(
                                          {
                                            ...selected,
                                            Quantity: 1,
                                            PriceSold: selected?.UnitPrice,
                                            Warrant_Duration: "",
                                          },
                                          index
                                        );
                                      }
                                    }}
                                    defaultOptions={!el?.Item_Name}
                                    isSearchable={true}
                                    isClearable={true}
                                    loadOptions={loadOptions}
                                  />
                                </td>

                                <td>
                                  <div style={{ width: "10rem" }}>
                                    <NumberCustomInput
                                      name={`tableDataInForm[${index}].Warrant_Duration`}
                                      value={
                                        formik.values.tableDataInForm[index]
                                          .Warrant_Duration
                                      }
                                      onValueChange={(value, name) => {
                                        value =
                                          typeof value === "undefined"
                                            ? ""
                                            : value;
                                        formik.setFieldValue(name, value);
                                        calculateServiceOtherValuesOnChange({
                                          index,
                                          Quantity:
                                            formik.values.tableDataInForm[index]
                                              .Quantity,
                                          PriceSold:
                                            formik.values.tableDataInForm[index]
                                              .PriceSold,
                                          UnitCost:
                                            formik.values.tableDataInForm[index]
                                              .UnitCost,
                                          discountPerItem:
                                            formik.values.tableDataInForm[index]
                                              .discountPerItem,
                                          Warrant_Duration: value,
                                        });
                                      }}
                                      onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                          e.preventDefault();
                                          arrayHelpers.push(
                                            initialServiceItem()
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </td>

                                <td>
                                  <CurrencyCustomInput
                                    currencySymbol={""}
                                    name={`tableDataInForm[${index}].PriceSold`}
                                    value={
                                      formik.values.tableDataInForm[index]
                                        .PriceSold
                                    }
                                    onValueChange={(value, name) => {
                                      formik.setFieldValue(name, value);
                                      calculateServiceOtherValuesOnChange({
                                        index,
                                        Quantity:
                                          formik.values.tableDataInForm[index]
                                            .Quantity,
                                        PriceSold: value,
                                        UnitCost:
                                          formik.values.tableDataInForm[index]
                                            .UnitCost,
                                        discountPerItem:
                                          formik.values.tableDataInForm[index]
                                            .discountPerItem,
                                        Warrant_Duration:
                                          formik.values.tableDataInForm[index]
                                            .Warrant_Duration,
                                      });
                                    }}
                                    placeholder="0.00"
                                    onKeyDown={(e) => {
                                      if (e.keyCode === 13) {
                                        e.preventDefault();
                                        arrayHelpers.push(initialServiceItem);
                                      }
                                    }}
                                  />
                                </td>

                                <td>
                                  <div style={{ width: "10rem" }}>
                                    <NumberCustomInput
                                      name={`tableDataInForm[${index}].Quantity`}
                                      value={
                                        formik.values.tableDataInForm[index]
                                          .Quantity
                                      }
                                      onValueChange={(value, name) => {
                                        formik.setFieldValue(name, value);
                                        calculateServiceOtherValuesOnChange({
                                          index,
                                          Quantity: value,
                                          PriceSold:
                                            formik.values.tableDataInForm[index]
                                              .PriceSold,
                                          UnitCost:
                                            formik.values.tableDataInForm[index]
                                              .UnitCost,
                                          discountPerItem:
                                            formik.values.tableDataInForm[index]
                                              .discountPerItem,
                                          Warrant_Duration:
                                            formik.values.tableDataInForm[index]
                                              .Warrant_Duration,
                                        });
                                      }}
                                      onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                          e.preventDefault();
                                          arrayHelpers.push(initialServiceItem);
                                        }
                                      }}
                                    />
                                  </div>
                                </td>

                                <td>
                                  <CurrencyCustomInput
                                    currencySymbol={""}
                                    name={`tableDataInForm[${index}].discountPerItem`}
                                    value={
                                      formik.values.tableDataInForm[index]
                                        .discountPerItem
                                    }
                                    onValueChange={(value, name) => {
                                      formik.setFieldValue(name, value);
                                      calculateServiceOtherValuesOnChange({
                                        index,
                                        Quantity:
                                          formik.values.tableDataInForm[index]
                                            .Quantity,
                                        PriceSold:
                                          formik.values.tableDataInForm[index]
                                            .PriceSold,
                                        UnitCost:
                                          formik.values.tableDataInForm[index]
                                            .UnitCost,
                                        discountPerItem: value,
                                        Warrant_Duration:
                                          formik.values.tableDataInForm[index]
                                            .Warrant_Duration,
                                      });
                                    }}
                                    placeholder="0.00"
                                    onKeyDown={(e) => {
                                      if (e.keyCode === 13) {
                                        e.preventDefault();
                                        arrayHelpers.push(initialServiceItem);
                                      }
                                    }}
                                  />
                                </td>

                                <td>
                                  {currency(el.SubTotal, {
                                    symbol: "",
                                  })
                                    .subtract()
                                    .format()}
                                </td>
                              </tr>
                            ))}
                        </>
                      )}
                    />
                  </tbody>
                </Table>
              </div>
            </Form>{" "}
          </FormikProvider>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => formik.submitForm()}>Save Changes</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddServiceItemModal;
