import CachedIcon from "mdi-react/CachedIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import React, { useEffect, useState } from "react";
import { Form, Button, InputGroup, Modal, Dropdown } from "react-bootstrap";
import TableComponent from "../TableComponent";
import {
  EditIcon,
  FilterCollapseIcon,
  FilterTwoIcon,
  ViewIcon,
} from "../Icons";
import useDebounce, {
  useLocalStorage,
  useQueryParams,
} from "../../utils/hooks";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import Datetime from "react-datetime";
import {
  FieldArray,
  FormikProvider,
  useFormik,
  ErrorMessage,
  Form as FormikForm,
} from "formik";
import CitySelector from "../CitySelector";
import moment from "moment";
import * as yup from "yup";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import { format, parse } from "date-fns";
import queryString from "query-string";
import { formatDate, paginationOptions } from "../../utils/helpers";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import ReactPaginate from "react-paginate";
import NoTableItem from "../utils/NoTableItem";
import { isEmpty } from "lodash";
import { useNavigate, useNavigation } from "react-router-dom";
import CompanyBranches from "./CompanyBranchModal";

const validationSchema = yup.object().shape({
  CompanyName: yup.string().required("Company name is required"),
  CompanyDateCreated: yup.string().required("Company name is required"),
  CompanyEmail: yup
    .string()
    .email()
    .required("Company email is required"),
  CompanyAddress: yup.string().required("Company address is required"),
  CompanyPhone: yup.string().required("Company address is required"),
  CompanyToatalUsers: yup.number().required("Company total user  is required"),
  CompanyPassiveUsers: yup
    .number()
    .required("Total company core users  is required"),
  CompanyCoreUsers: yup
    .number()
    .required("Total company core users  is required"),
  CompanyBranch: yup.array().of(
    yup.object().shape({
      name: yup.string().required("branch name is required"),
      email: yup
        .string()
        .email("Invalid email format")
        .required("Branch email is required"),
      phone: yup.string().required("Branch phone number is required"),
      address: yup.string().required("Branch address is required"),
      FrontendUrl: yup.string().required("Branch Frontend URL is required"),
      BackendUrl: yup.string().required("Branch Backend URL is required"),
    })
  ),
});

function CreateCompanyMondal({
  showCreateCompanyModal,
  setShowCreateCompanyModal,
  updateCompanyModal,
  refetch,
  companyName,
  companyID,
}) {
  const initialValues = {
    CompanyName: "",
    companyState: "",
    companyLGA: "",
    CompanyDateCreated: moment(),
    CompanyID: new Date().getTime(),
    CompanyEmail: "",
    CompanyAddress: "",
    CompanyPhone: "",
    CompanyToatalUsers: "",
    state: "",
    LGA: "",
    CompanyPassiveUsers: 0,
    CompanyCoreUsers: 0,
    CompanyExpiringDate: moment(),
    sync: false,
    CompanyBranch: [
      {
        name: "",
        email: "",
        phone: "",
        address: "",
        branchId: new Date().getTime() + 1,
        dateCreated: moment(),
        isHq: false,
        FrontendUrl: "",
        BackendUrl: "",
      },
    ],
  };

  const { backendUrl, token } = useAuth();

  const fetchCompanyBranches = async () => {
    const res = await fetch(
      `${backendUrl}/api/company/get-company-branches?companyId=${companyID}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      }
    );

    const data = await res.json();

    return data;
  };

  const submit = async (details) => {
    let endpoint;
    if (Boolean(updateCompanyModal)) {
      endpoint = "update-eb-company";
    } else {
      endpoint = "save-company";
    }

    try {
      const res = await fetch(`${backendUrl}/api/company/${endpoint}`, {
        method: "POST",
        body: JSON.stringify(details),
        headers: {
          Accept: "Application/json",
          "Content-Type": "application/json",
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },

        credentials: "include",
      });
      const data = await res.json();
      if (res.ok) {
        refetch();
        toast.success(data?.message);
        setShowCreateCompanyModal(false);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      toast.error("An error occured. Please try again");
    }
  };

  const { mutate, isLoading, isError, isSuccess } = useMutation(submit);

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,

    onSubmit: async (value) => {
      await handleSubmit();
    },
  });

  function getExpiringDate(date) {
    let expDate = new Date(date);

    expDate.setDate(expDate.getDate() - 9);

    return expDate;
  }

  const { data, isFetching } = useQuery(
    ["GET_COMPANY_BRANCH"],
    () => fetchCompanyBranches(),
    {
      onSuccess: (data) => {
        if (Boolean(updateCompanyModal)) {
          formik.setFieldValue(
            "CompanyID",
            data?.company?.CompanyID || new Date().getTime()
          );
          formik.setFieldValue("CompanyName", data?.company?.CompanyName || "");
          formik.setFieldValue(
            "CompanyDateCreated",
            Boolean(data?.company?.CompanyDateCreated)
              ? moment(data?.company?.CompanyDateCreated)
              : moment()
          );
          formik.setFieldValue(
            "CompanyEmail",
            data?.company?.CompanyEmail || ""
          );
          formik.setFieldValue(
            "CompanyAddress",
            data?.company?.CompanyAddress || ""
          );
          formik.setFieldValue(
            "CompanyPhone",
            data?.company?.CompanyPhone || ""
          );
          formik.setFieldValue(
            "CompanyToatalUsers",
            data?.company?.CompanyToatalUsers || ""
          );
          if (process.env.REACT_APP_SITE_TITLE === "InvexERP") {
            formik.setFieldValue(
              "CompanyPassiveUsers",
              Number(data?.company?.CompanyPassiveUsers || "")
            );
            formik.setFieldValue(
              "CompanyCoreUsers",
              Number(data?.company?.CompanyCoreUsers || "")
            );
          }
          // formik.setFieldValue(" state", data?.company?.state);
          // formik.setFieldValue(" LGA", data?.company?.LGA);

          formik.setFieldValue("companyState", data?.company?.state || "");
          formik.setFieldValue("companyLGA", data?.company?.LGA || "");
          formik.setFieldValue(
            "CompanyExpiringDate",
            !isNaN(new Date(data?.company?.CompanyExpiringDate).getTime())
              ? moment(getExpiringDate(data?.company?.CompanyExpiringDate))
              : moment()
          );

          const companyBranch = data?.branches.map((branch) => ({
            ...branch,
            branchId: branch?.branchId || new Date().getTime() + 1,
            dateCreated: !isNaN(new Date(branch.dateCreated))
              ? moment(branch.dateCreated)
              : moment(),
            name: branch?.name || "",
            email: branch?.email || "",
            phone: branch?.phone || "",
            address: branch?.address || "",
            isHq: branch?.isHq || false,
            FrontendUrl: branch?.FrontendUrl || "",
            BackendUrl: branch?.BackendUrl || "",
          }));
          formik.setFieldValue("CompanyBranch", companyBranch);
        }
      },
      enabled: true,
      keepPreviousData: false,
    }
  );

  async function handleSubmit() {
    let { values } = formik;

    const { CompanyDateCreated, CompanyBranch, CompanyExpiringDate } = values;
    if (typeof CompanyDateCreated === "string") {
      formik.setFieldError("CompanyDateCreated", "Invalid date");
      return;
    }
    let branchDetails;
    if (CompanyBranch.length > 0) {
      branchDetails = CompanyBranch.map((branch) => ({
        ...branch,
        branchId: branch.branchId + "",
        dateCreated: branch.dateCreated.format(),
      }));
    }

    try {
      mutate({
        ...values,
        CompanyDateCreated: CompanyDateCreated.format(),
        CompanyID: values.CompanyID + "",
        CompanyBranch: branchDetails,
        CompanyExpiringDate: CompanyExpiringDate.format(),
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Modal
      onHide={() => setShowCreateCompanyModal(false)}
      show={showCreateCompanyModal}
      dialogClassName="new-entity-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1> {Boolean(companyName) ? companyName : "Create New Company"} </h1>

          <p>
            {Boolean(companyName)
              ? `Update ${companyName} by filling in the following forms`
              : "Create new compnay by filling in the following forms"}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormikProvider value={formik}>
          <Form
            className="row pb-2"
            autoComplete="off"
            onSubmit={formik.handleSubmit}
            noValidate
          >
            <h2 className="mb-3 pb-1 text-center   border-bottom">
              Company Information
            </h2>

            <div className=" col-md-6">
              <Form.Group className="mb-3 pb-2 ">
                <Form.Label className=" ">Company ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter company ID"
                  name="CompanyID"
                  value={formik.values.CompanyID}
                  readOnly
                />
              </Form.Group>
              <Form.Group className="mb-3 pb-2 ">
                <Form.Label className="">Company Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter company name"
                  name="CompanyName"
                  value={formik.values.CompanyName}
                  onChange={(e) => {
                    formik.handleChange(e); // Manually bind handleChange
                  }}
                />
                <ErrorMessage
                  component="div"
                  name="CompanyName"
                  className="text-danger"
                />
                {/* {formik.touched && formik.errors && (
                  <div>{formik.CompanyName}</div>
                )} */}
              </Form.Group>

              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Date Created</Form.Label>
                <Datetime
                  dateFormat="MMM DD, YYYY"
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  name="CompanyDateCreated"
                  inputProps={{
                    className: `date-input form-control  `,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.CompanyDateCreated}
                  onChange={(date) => {
                    formik.setFieldValue("CompanyDateCreated", date, true);
                  }}
                  onBlur={() =>
                    formik.setFieldTouched("CompanyDateCreated", true)
                  }
                />
                <ErrorMessage
                  component="div"
                  name="CompanyDateCreated"
                  className="text-danger"
                />
              </Form.Group>

              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Company Address</Form.Label>

                <Form.Control
                  as="textarea"
                  placeholder="Enter company address"
                  name={`CompanyAddress`}
                  rows={4}
                  value={formik.values.CompanyAddress}
                  onChange={(e) => {
                    formik.handleChange(e); // Manually bind handleChange
                  }}
                />
                <ErrorMessage
                  component="div"
                  name="CompanyAddress"
                  className="text-danger"
                />
              </Form.Group>
              {process.env.REACT_APP_SITE_TITLE === "InvexERP" && (
                <Form.Group className=" mb-3 pb-2">
                  <Form.Label className="mb-1">Core Users</Form.Label>

                  <Form.Control
                    type="number"
                    min={0}
                    placeholder="Enter company core users"
                    name={`CompanyCoreUsers`}
                    value={formik.values.CompanyCoreUsers}
                    onChange={(e) => {
                      formik.handleChange(e); // Manually bind handleChange
                      formik.setFieldValue(
                        "CompanyToatalUsers",
                        Number(e.target.value) +
                          Number(formik.values.CompanyPassiveUsers)
                      );
                    }}
                  />
                  <ErrorMessage
                    component="div"
                    name="CompanyCoreUsers"
                    className="text-danger"
                  />
                </Form.Group>
              )}

              {process.env.REACT_APP_SITE_TITLE === "InvexERP" && (
                <Form.Group className=" mb-3 pb-2">
                  <Form.Label className="mb-1">Passive Users</Form.Label>

                  <Form.Control
                    type="number"
                    min={0}
                    placeholder="Enter company passive users"
                    name={`CompanyPassiveUsers`}
                    value={formik.values.CompanyPassiveUsers}
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.setFieldValue(
                        "CompanyToatalUsers",
                        Number(e.target.value) +
                          Number(formik.values.CompanyCoreUsers)
                      );

                      // Manually bind handleChange
                    }}
                  />
                  <ErrorMessage
                    component="div"
                    name="CompanyPassivelUsers"
                    className="text-danger"
                  />
                </Form.Group>
              )}
            </div>
            <div className=" col-md-6">
              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Company Email</Form.Label>
                <Form.Control
                  name={`CompanyEmail`}
                  placeholder="Email"
                  value={formik.values.CompanyEmail}
                  onChange={(e) => {
                    formik.handleChange(e); // Manually bind handleChange
                  }}
                />
                <ErrorMessage
                  component="div"
                  name="CompanyEmail"
                  className="text-danger"
                />
              </Form.Group>
              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Company Phone</Form.Label>

                <Form.Control
                  name={`CompanyPhone`}
                  placeholder="Phone"
                  value={formik.values.CompanyPhone}
                  onChange={(e) => {
                    formik.handleChange(e); // Manually bind handleChange
                  }}
                />
                <ErrorMessage
                  component="div"
                  name="CompanyPhone"
                  className="text-danger"
                />
              </Form.Group>

              <CitySelector formik={formik} />

              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Total Users</Form.Label>
                <Form.Control
                  type="number"
                  min={0}
                  name={`CompanyToatalUsers`}
                  readOnly={process.env.REACT_APP_SITE_TITLE === "InvexERP"}
                  value={formik.values.CompanyToatalUsers}
                  onChange={(e) => {
                    if (process.env.REACT_APP_SITE_TITLE === "InvexOne") {
                      formik.handleChange(e);
                    }
                  }}
                />
                <ErrorMessage
                  component="div"
                  name="CompanyToatalUsers"
                  className="text-danger"
                />
              </Form.Group>
              <Form.Group className=" mb-3 pb-2">
                <Form.Label className="mb-1">Company Expiring Date</Form.Label>
                <Datetime
                  dateFormat="MMM DD, YYYY"
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  name="CompanyExpiringDate"
                  inputProps={{
                    className: `date-input form-control $`,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.CompanyExpiringDate}
                  onChange={(date) => {
                    formik.setFieldValue("CompanyExpiringDate", date, true);
                  }}
                  onBlur={() =>
                    formik.setFieldTouched("CompanyExpiringDate", true)
                  }
                />
                <ErrorMessage
                  component="div"
                  name="CompanyExpiringDate"
                  className="text-danger"
                />
              </Form.Group>

              <Form.Group className="d-flex gap-2  align-items-start">
                <Form.Check // prettier-ignore
                  type="switch"
                  id="sync"
                  value={formik.values.sync}
                  onChange={(e) => {
                    formik.handleChange(e); // Manually bind handleChange
                  }}
                />
                <Form.Label className=" ">Synchronize Branches</Form.Label>
              </Form.Group>
            </div>

            <h2 className="mb-3 pb-1 pt-3 text-center border-bottom">
              Branch Information
            </h2>

            <FieldArray
              name="CompanyBranch"
              render={(arrayHelpers) => (
                <>
                  <div className="row">
                    {formik.values.CompanyBranch?.map((el, index) => (
                      <div
                        className="d-flex flex-column gap-3 mb-3 pb-2 mb-2 col-md-6 "
                        key={index}
                      >
                        <Form.Group className="   w-100">
                          <Form.Label className="mb-1">Branch ID</Form.Label>

                          <Form.Control
                            type="text"
                            placeholder="Enter branch ID"
                            name={`CompanyBranch[${index}].branchId`}
                            readOnly
                            value={formik.values.CompanyBranch[index].branchId}
                            onChange={(e) => {
                              formik.handleChange(e); // Manually bind handleChange
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="   w-100">
                          <Form.Label className="mb-1">Branch Name</Form.Label>

                          <Form.Control
                            type="text"
                            placeholder="Enter branch name"
                            name={`CompanyBranch[${index}].name`}
                            rows={3}
                            value={formik.values.CompanyBranch[index].name}
                            onChange={(e) => {
                              formik.handleChange(e); // Manually bind handleChange
                            }}
                          />
                          <ErrorMessage
                            component="div"
                            name={`CompanyBranch[${index}].name`}
                            className="text-danger"
                          />
                          {/* { name: "", email:"", phone:"" , address:"", branchId:"", dateCreated:""  } */}
                        </Form.Group>

                        <Form.Group className="   w-100">
                          <Form.Label className="mb-1">Branch Email</Form.Label>

                          <Form.Control
                            type="text"
                            placeholder="Enter branch email"
                            name={`CompanyBranch[${index}].email`}
                            rows={3}
                            value={formik.values.CompanyBranch[index].email}
                            onChange={(e) => {
                              formik.handleChange(e); // Manually bind handleChange
                            }}
                          />
                          <ErrorMessage
                            component="div"
                            name={`CompanyBranch[${index}].email`}
                            className="text-danger"
                          />
                        </Form.Group>
                        <Form.Group className="   w-100">
                          <Form.Label className="mb-1">Branch Phone</Form.Label>

                          <Form.Control
                            type="text"
                            placeholder="Enter branch phone"
                            name={`CompanyBranch[${index}].phone`}
                            rows={3}
                            value={formik.values.CompanyBranch[index].phone}
                            onChange={(e) => {
                              formik.handleChange(e); // Manually bind handleChange
                            }}
                          />
                          <ErrorMessage
                            component="div"
                            name={`CompanyBranch[${index}].phone`}
                            className="text-danger"
                          />
                        </Form.Group>
                        <Form.Group className="   w-100">
                          <Form.Label className="mb-1">
                            Branch Backend URL
                          </Form.Label>

                          <Form.Control
                            type="text"
                            placeholder="Enter branch backend URL"
                            name={`CompanyBranch[${index}].BackendUrl`}
                            rows={3}
                            value={
                              formik.values.CompanyBranch[index].BackendUrl
                            }
                            onChange={(e) => {
                              formik.handleChange(e); // Manually bind handleChange
                            }}
                          />
                          <ErrorMessage
                            component="div"
                            name={`CompanyBranch[${index}].BackendUrl`}
                            className="text-danger"
                          />
                        </Form.Group>
                        <Form.Group className="   w-100">
                          <Form.Label className="mb-1">
                            Branch Frontend URL
                          </Form.Label>

                          <Form.Control
                            type="text"
                            placeholder="Enter branch frontend URL"
                            name={`CompanyBranch[${index}].FrontendUrl`}
                            value={
                              formik.values.CompanyBranch[index].FrontendUrl
                            }
                            onChange={(e) => {
                              formik.handleChange(e); // Manually bind handleChange
                            }}
                          />
                          <ErrorMessage
                            component="div"
                            name={`CompanyBranch[${index}].FrontendUrl`}
                            className="text-danger"
                          />
                        </Form.Group>

                        <Form.Group className="   w-100">
                          <Form.Label className="mb-1">
                            Branch Address
                          </Form.Label>

                          <Form.Control
                            as="textarea"
                            placeholder="Enter branch address"
                            name={`CompanyBranch[${index}].address`}
                            rows={3}
                            value={formik.values.CompanyBranch[index].address}
                            onChange={(e) => {
                              formik.handleChange(e); // Manually bind handleChange
                            }}
                          />
                          <ErrorMessage
                            component="div"
                            name={`CompanyBranch[${index}].address`}
                            className="text-danger"
                          />
                        </Form.Group>
                        <Form.Group className="">
                          <Form.Check
                            inline
                            label="Is branch HQ?"
                            name={`CompanyBranch[${index}].isHq`}
                            type={"checkbox"}
                            checked={formik.values.CompanyBranch[index].isHq}
                            onChange={(e) => {
                              if (e.target.checked) {
                                formik.setFieldValue(
                                  `CompanyBranch[${index}].isHq`,
                                  true
                                );
                              } else {
                                formik.setFieldValue(
                                  `CompanyBranch[${index}].isHq`,
                                  false
                                );
                              }
                            }}
                          />
                        </Form.Group>
                        <Form.Group className="   w-100">
                          <Form.Label className="mb-1">Date created</Form.Label>
                          <Datetime
                            dateFormat="MMM DD, YYYY"
                            timeFormat={false}
                            closeOnSelect={true}
                            closeOnClickOutside={true}
                            name={`CompanyBranch[${index}].dateCreated`}
                            inputProps={{
                              className: `date-input form-control $`,
                              placeholder: "Select date",
                              readOnly: true,
                            }}
                            value={
                              formik.values.CompanyBranch[index].dateCreated
                            }
                            onChange={(date) => {
                              formik.setFieldValue(
                                `CompanyBranch[${index}].dateCreated`,
                                date,
                                true
                              );
                            }}
                            onBlur={() =>
                              formik.setFieldTouched(
                                `CompanyBranch[${index}].dateCreated`,
                                true
                              )
                            }
                          />
                          <ErrorMessage
                            component="div"
                            name={`CompanyBranch[${index}].dateCreated`}
                            className="text-danger"
                          />
                        </Form.Group>
                        <div>
                          <Button
                            title="Remove"
                            variant=""
                            type="button"
                            size="xs"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            Remove Branch
                          </Button>
                        </div>
                      </div>
                    ))}

                    <div className="d-flex justify-content-start px-4">
                      <button
                        type="button"
                        className="btn btn-sm btn-primary text-nowrap"
                        onClick={() =>
                          arrayHelpers.push({
                            branchId: new Date().getTime() + 1,
                          })
                        }
                      >
                        + Add Branch
                      </button>
                    </div>
                  </div>
                </>
              )}
            />

            <div className=" d-flex justify-content-center gap-4">
              <Button type="submit" className="" variant="primary">
                {updateCompanyModal && !isLoading
                  ? "Update"
                  : updateCompanyModal && isLoading
                  ? "Updating"
                  : !updateCompanyModal && !isLoading
                  ? "Save"
                  : "Saving"}
              </Button>
              {updateCompanyModal && (
                <Button
                  onClick={() => setShowCreateCompanyModal(false)}
                  className=""
                  variant="  btn-outline-primary"
                >
                  Cancel
                </Button>
              )}
            </div>
          </Form>
        </FormikProvider>
      </Modal.Body>
    </Modal>
  );
}

export default function EBCompanyList() {
  const { backendUrl, token } = useAuth();
  const [showCreateCompanyModal, setShowCreateCompanyModal] = useState(false);
  const [showFilter, setShowFilter] = useLocalStorage(
    "showEBCompanyFilter",
    true
  );

  const [showCompanyBranchModal, setShowCompanyModal] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [EBCompanyName, setCompanyName] = useState("");
  const navigate = useNavigate();
  const initialFilterParams = {
    companyName: "",
    startDate: "",
    endDate: "",

    companyID: "",
  };
  const [updateCompanyModal, setUpdateCompanyModal] = useState(false);
  const [queryParams, setQueryParams] = useQueryParams({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });

  const fetchSystemCompany = async (query) => {
    const res = await fetch(
      `${backendUrl}/api/company/get-eb-companies?${queryString.stringify(
        query
      )}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      }
    );

    const data = await res.json();

    return data;
  };

  const { data, refetch, isFetching, isSuccess } = useQuery(
    ["GET_EB_COMPANY", queryParams],
    () => fetchSystemCompany(queryParams),

    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const debouncedFilterParams = useDebounce(filterParams, 500);
  useEffect(() => {
    setQueryParams({
      ...queryParams,
      ...debouncedFilterParams,
    });
  }, [debouncedFilterParams, setQueryParams]);

  const handleFilterParamsChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const filterByDateRange = (date) => {
    setFilterParams({
      ...filterParams,
      startDate: format(date[0], "yyyy-MM-dd"),
      endDate: format(date[1], "yyyy-MM-dd"),
      enableDateRange: true,
    });
  };

  const clearDateRange = () => {
    setFilterParams({
      ...filterParams,
      startDate: "",
      endDate: "",
    });
  };
  const search = () => {
    setQueryParams({
      ...queryParams,
      ...filterParams,
    });
  };

  const reset = () => {
    setFilterParams(initialFilterParams);
    setQueryParams({
      ...queryParams,
      ...initialFilterParams,
    });
  };

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      [e.target.name]: e.target.value,
    });
  };

  const tableHead = () => {
    return (
      <thead className="position-sticky top-0">
        <tr>
          <th />
          <th>Company ID</th>

          <th>Name</th>
          <th>Phone</th>

          {process.env.REACT_APP_SITE_TITLE === "InvexERP" && (
            <th>Core Users</th>
          )}
          {process.env.REACT_APP_SITE_TITLE === "InvexERP" && (
            <th>Passive Users</th>
          )}
          <th>Total Users</th>

          <th>Date Created</th>
          <th>Expiring Date</th>
          <th>Status</th>
        </tr>
      </thead>
    );
  };

  const getStatus = (date) => {
    const currentDate = new Date().getTime();
    let expiringDate = new Date(date);
    expiringDate.setDate(expiringDate.getDate() - 9);

    expiringDate = new Date(expiringDate).getTime();

    let timeDiff = currentDate - expiringDate;

    let daysDiff = Math.floor(timeDiff / (1000 * 3600 * 24));

    let status;

    if (daysDiff > 9) {
      status = "Suspended";
    } else {
      status = currentDate > expiringDate ? "Expired" : "Active";
    }

    return status;
  };

  function getExpiringDate(date) {
    let expDate = new Date(date);

    expDate.setDate(expDate.getDate() - 9);

    return expDate;
  }

  const tableData = (el, index) => {
    return (
      <>
        <td>
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              className="bg-white border-0"
              bsPrefix="print more"
            >
              <DotsVerticalIcon />
            </Dropdown.Toggle>
            <Dropdown.Menu
              popperConfig={{
                strategy: "fixed",
              }}
              renderOnMount
              className="dropdown-with-icons"
            >
              <Dropdown.Item
                as="button"
                onClick={() => {
                  setCompanyId(el?.CompanyID);
                  setCompanyName(el?.CompanyName);
                  setShowCompanyModal(true);
                }}
              >
                <ViewIcon /> View Branches
              </Dropdown.Item>
              <Dropdown.Item
                as="button"
                onClick={() => {
                  setUpdateCompanyModal(true);
                  setCompanyId(el?.CompanyID);
                  setShowCreateCompanyModal(true);
                  setCompanyName(el?.CompanyName);

                  // setShowEmailAddressModal(true);
                }}
              >
                <EditIcon />
                Update Company
              </Dropdown.Item>
            </Dropdown.Menu>{" "}
          </Dropdown>
        </td>

        <td>{el?.CompanyID}</td>
        <td>{el?.CompanyName}</td>
        <td>{Boolean(el?.CompanyPhone) && el?.CompanyPhone.split(",")[0]}</td>
        {/* <td style={{ wordBreak: "break-all" }}>{el?.CompanyEmail}</td> */}
        {process.env.REACT_APP_SITE_TITLE === "InvexERP" && (
          <td>{el?.CompanyCoreUsers}</td>
        )}
        {process.env.REACT_APP_SITE_TITLE === "InvexERP" && (
          <td>{el?.CompanyPassiveUsers}</td>
        )}

        <td>{el?.CompanyToatalUsers}</td>

        <td>
          {!isNaN(new Date(el?.CompanyDateCreated).getTime())
            ? format(new Date(el?.CompanyDateCreated), "dd MMM, yyyy")
            : "..........."}
        </td>
        <td>
          {!isNaN(new Date(el?.CompanyExpiringDate).getTime())
            ? format(
                new Date(getExpiringDate(el?.CompanyExpiringDate)),
                "dd MMM, yyyy"
              )
            : "............"}
        </td>
        <td
          className={
            getStatus(el?.CompanyExpiringDate) === "Expired"
              ? "expiring-date-color  fw-bold"
              : getStatus(el?.CompanyExpiringDate) === "Active"
              ? "text-success fw-bold"
              : "text-danger fw-bold"
          }
        >
          {getStatus(el?.CompanyExpiringDate)}
        </td>
      </>
    );
  };

  return (
    <>
      {showCreateCompanyModal && (
        <CreateCompanyMondal
          setShowCreateCompanyModal={setShowCreateCompanyModal}
          showCreateCompanyModal={showCreateCompanyModal}
          updateCompanyModal={updateCompanyModal}
          refetch={refetch}
          companyID={companyId}
          companyName={EBCompanyName}
        />
      )}

      {showCompanyBranchModal && (
        <CompanyBranches
          showCompanyBranchModal={showCompanyBranchModal}
          companyID={companyId}
          setShowCompanyModal={setShowCompanyModal}
          companyName={EBCompanyName}
        />
      )}

      <main className="cash-book ">
        <div className="content ">
          <div className="content-main pt-3">
            <div className={`filter-area ${showFilter ? "open" : "close"}`}>
              <div className="filter-header">
                <h2>
                  <FilterTwoIcon /> Filters
                </h2>

                <Button
                  variant="white"
                  onClick={() => setShowFilter(!showFilter)}
                >
                  <FilterCollapseIcon />
                </Button>
              </div>
              <div className="filter-body">
                <Form>
                  <div className="body">
                    <Form.Group className="mb-3 pb-2 mb-2-5">
                      <Form.Label>Company ID</Form.Label>{" "}
                      <InputGroup>
                        <Form.Control
                          name="companyID"
                          value={filterParams.companyID}
                          placeholder="company id"
                          onChange={(e) => handleFilterParamsChange(e)}
                        />{" "}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-3 pb-2 mb-2-5">
                      <Form.Label>Campany Name</Form.Label>{" "}
                      <InputGroup>
                        <Form.Control
                          name="companyName"
                          value={filterParams?.companyName}
                          placeholder="Enter company name"
                          onChange={(e) => handleFilterParamsChange(e)}
                        />{" "}
                      </InputGroup>
                    </Form.Group>

                    {/* <Form.Group className="mb-3 pb-2 mb-2-5">
                      <Form.Label>Branch</Form.Label>{" "}
                      <InputGroup>
                        <Form.Control
                          name="branch"
                          value={filterParams.branch}
                          placeholder="branch name"
                          onChange={(e) => handleFilterParamsChange(e)}
                        />{" "}
                      </InputGroup>
                    </Form.Group> */}

                    <hr className="mt-3 mb-4" />
                    <Form.Group className="">
                      <Form.Label>Date Range</Form.Label>
                      <div className="position-relative">
                        <RsDateRangePicker
                          placement="topStart"
                          value={
                            filterParams.startDate && filterParams.endDate
                              ? [
                                  parse(
                                    filterParams.startDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                  parse(
                                    filterParams.endDate,
                                    "yyyy-MM-dd",
                                    new Date()
                                  ),
                                ]
                              : []
                          }
                          onClean={() => clearDateRange()}
                          onOk={(date) => filterByDateRange(date)}
                        />
                      </div>
                    </Form.Group>
                  </div>

                  <div className="buttons rounded">
                    <Button onClick={() => reset()} variant="white">
                      Reset
                    </Button>
                    <Button onClick={() => search()} variant="primary">
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="content-body">
              <header>
                <h1>
                  {!showFilter && (
                    <button
                      onClick={() => setShowFilter(!showFilter)}
                      className="btn filter"
                    >
                      <FilterTwoIcon />
                    </button>
                  )}
                  Company List
                  <button
                    title="Refresh"
                    className="btn text-primary"
                    onClick={() => refetch()}
                  >
                    <CachedIcon />
                  </button>
                </h1>
                <div className="actions">
                  <Button
                    variant="primary"
                    onClick={() => {
                      setUpdateCompanyModal(false);
                      setCompanyId("");

                      setCompanyName("");

                      setShowCreateCompanyModal(true);
                    }}
                  >
                    + Create New
                  </Button>
                </div>
              </header>

              <div className="m-3">
                <TableComponent
                  responsive
                  borderless
                  striped
                  tableHeadsFunction={tableHead}
                  mainDataArray={data?.data?.ebCompany || []}
                  className="product-table text-nowrap"
                  tableDataRowFunction={tableData}
                  // tableRowClick={(e, el) => manageTransaction(e, el)}
                />

                {!isFetching && isSuccess && isEmpty(data?.data?.ebCompany) ? (
                  <NoTableItem queryParams={queryParams} />
                ) : null}
              </div>

              <div className="d-flex justify-content-between px-3 align-items-center pagination">
                <div className="pagination_left">
                  <p className="m-0 p-0">Show</p>
                  <select
                    value={queryParams.limit}
                    name="limit"
                    className="form-select "
                    onChange={(e) => handleSearchQueryChange(e)}
                  >
                    <option value="10">10 rows</option>
                    <option value="20">20 rows</option>
                    <option value="30">30 rows</option>
                    <option value="40">40 rows</option>
                    <option value="50">50 rows</option>
                    <option value="100">100 rows</option>{" "}
                  </select>
                </div>

                <ReactPaginate
                  {...paginationOptions}
                  pageCount={Math.ceil(data?.data?.count / queryParams.limit)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={0}
                  onPageChange={({ selected }) => {
                    document.body.scrollTop = document.documentElement.scrollTop = 0;
                    setQueryParams({
                      ...queryParams,
                      page: selected + 1,
                    });
                  }}
                  forcePage={queryParams.page - 1}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
