import { Modal, Form, Button } from "react-bootstrap";
import * as yup from "yup";
import { services, backendApis, appSettings, IS_HR } from "../config";
import { FormikProvider, useFormik } from "formik";
import { toast } from "react-toastify";
import { useEffect, useMemo, useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import {
  useBackendUrl,
  useIsAdmin,
  useLocalStorage,
  useToken,
} from "../utils/hooks";
import { LockIcon } from "./Icons";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  fetchActionsUtil,
  initialGeneralSettings,
  reactSelectTheme,
} from "../utils/helpers";
import Select from "react-select";
import { useAuth } from "../hooks/useAuth";
import { loginHRSite } from "../utils/loginHRSite";
import { useStoreActions } from "easy-peasy";
import { useLocation } from "react-router-dom";
import ConfirmDialog from "./ConfirmDialogue";
import EyeOffOutlineIcon from "mdi-react/EyeOffOutlineIcon";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";

function Login({
  proceed,
  show,
  title = "Admin Authentication",
  loginRequired = false,
  description = "",
}) {
  const backendUrl = useBackendUrl();
  const isAdmin = useIsAdmin();
  const token = useToken();

  if (isAdmin && !loginRequired) {
    proceed(true);
  }

  const [isLoading, setIsLoading] = useState(false);
  const initialValues = { username: "", password: "" };
  const schema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required(),
  });

  const loginUser = async (values) => {
    try {
      setIsLoading(true);
      let response = await fetch(`${backendUrl}/api/auth/admin-authenticate`, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ...values }),
        credentials: "include",
      });

      if (!response.ok) {
        response = await response.json();
        if (response.errors) formik.setErrors(response.errors);
        toast.error(response.message);
      } else {
        toast.success("Authenticated");
        proceed(true);
      }
    } catch (err) {
      console.log(err);
      toast.error("Unable to submit");
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      loginUser(values);
    },
  });
  return (
    <Modal
      show={show}
      onHide={() => !isLoading && proceed(false)}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6 d-flex gap-2 m-0 align-items-center">
            <LockIcon /> {title}
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-3">{description}</p>

        <Form noValidate onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter your username"
              name="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              isInvalid={formik.touched.username && !!formik.errors.username}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.username}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="••••••••"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              isInvalid={formik.touched.password && !!formik.errors.password}
            />
            <Form.Control.Feedback type="invalid">
              {formik.errors.password}
            </Form.Control.Feedback>
          </Form.Group>

          <Button
            disabled={isLoading}
            variant="primary"
            className="w-100 p-2"
            type="submit"
          >
            {isLoading ? "Please wait…" : "Continue"}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
export function UserLginModal({
  show,
  title = "Switch User",

  description = "",

  setShowSwitchUser,
}) {
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);

  const { user, login, backendUrl } = useAuth();
  const queryClient = useQueryClient();
  const setItemMeasurements = useStoreActions(
    (actions) => actions.setItemMeasurements
  );
  const setGeneralSettings = useStoreActions(
    (actions) => actions.setGeneralSettings
  );

  const initialValues = { username: "", password: "" };
  const schema = yup.object().shape({
    username: yup.string().required(),
    password: yup.string().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      mutate(values);
    },
  });

  const { data } = useQuery("users", () =>
    fetchActionsUtil(`${backendUrl}/api/users`, "GET")
  );

  const users = useMemo(() => {
    if (data?.staff) {
      return data?.staff?.map((user) => ({
        value: user.username,
        label: user.username,
      }));
    }
  }, [data]);

  const loginUser = async (values) => {
    let response = await fetch(`${backendUrl}/api/auth/login`, {
      method: "POST",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      body: JSON.stringify({
        ...formik.values,
        overwriteToken: true,
        adminOnly: appSettings.adminOnly,
      }),
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      if (response.errors) formik.setErrors(response.errors);
      if (response.alreadyLoggedIn) {
        handleAlreadyLoggedInUser();
      }
      throw new Error(response.message);
    }

    return await response.json();
  };

  const { status, error, mutate, isLoading } = useMutation(
    ["LOGIN"],
    (values) => loginUser(values),
    {
      enabled: false,

      onSuccess: (data) => {
        queryClient.removeQueries("LOGIN");
        login(
          {
            ...data.user,
            company: user?.company,
            timestamp: String(Date.now()),
          },
          location,
          data.isExpired
        );

        const backendApi = backendApis.find(
          (el) => el.name === formik.values.company
        );

        data.user.password = formik.values.password;
        IS_HR && loginHRSite({ user: data.user, backendApi });
        setItemMeasurements(data.itemMeasurements);
        setGeneralSettings({ ...initialGeneralSettings, ...data.settings });

        if (data?.success) {
          toast.success("Success");
          setShowSwitchUser(false);
        } else {
          toast.error("Try again!");
        }
      },
    }
  );

  const handleAlreadyLoggedInUser = async () => {
    const proceed = await ConfirmDialog({
      title: "User already logged In",
      description: "End previous session",
      choice: true,
      backdrop: "static",
    });
    if (proceed) {
      mutate({
        ...formik.values,
        overwriteToken: true,
      });
    }
  };
  return (
    <Modal
      show={show}
      onHide={() => setShowSwitchUser(false)}
      centered={true}
      animation={false}
      enforceFocus={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h6 d-flex gap-2 m-0 align-items-center">
            <LockIcon /> {title}
          </h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-3">{description}</p>
        <FormikProvider value={formik}>
          <Form noValidate onSubmit={formik.handleSubmit}>
            <Form.Group className="mb-2-5">
              <Form.Label>Username </Form.Label>
              <Select
                classNamePrefix="form-select"
                menuPosition="fixed"
                menuPlacement="auto"
                name="username"
                placeholder="Select User"
                theme={reactSelectTheme}
                isSearchable={true}
                onChange={(selected) => {
                  formik.setFieldValue("username", selected.value);
                }}
                value={users?.find((el) => el.value === formik.values.username)}
                options={users || []}
                isInvalid={formik.touched.username && !!formik.errors.username}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.username}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3 position-relative">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="••••••••"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                isInvalid={formik.touched.password && !!formik.errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.password}
              </Form.Control.Feedback>
              <Button
                variant=""
                type="button"
                style={{
                  position: "absolute",
                  right: 0,
                  top: "2rem",
                  transform: `scale(0.8)`,
                }}
                onClick={(e) => {
                  e.target.blur();
                  setShowPassword(!showPassword);
                }}
                className="no-focus text-light"
              >
                {!showPassword ? <EyeOffOutlineIcon /> : <EyeOutlineIcon />}
              </Button>
            </Form.Group>

            <Button
              disabled={isLoading}
              variant="primary"
              className="w-100 p-2"
              type="submit"
            >
              {isLoading ? "Please wait…" : "Continue"}
            </Button>
          </Form>
        </FormikProvider>
      </Modal.Body>
    </Modal>
  );
}

export default function LoginDialog(props) {
  return createConfirmation(confirmable(Login))(props);
}
// export function UserLoginDialog(props) {
//   return createConfirmation(confirmable(UserLgin))(props);
// }
